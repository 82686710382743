Unit BookingRequirements;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseBooking,
  Booking.Web.Shared,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Cyrus.Enumerations,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.WebCtrls,
  WEBLib.CDS;

Type
  TBookingRequirementsForm = Class(TBaseBookingForm)
    PageHeading: TLabel;
    GuestSource: TDataSource;
    EmergencyContactName: TDBEdit;
    EmergencyContactPhone: TDBEdit;
    EmergencyContactRelation: TDBEdit;
    PainterPanel: TPanel;
    EaselRequired: TDBLookupComboBox;
    ChairRequired: TDBLookupComboBox;
    BoardRequired: TDBLookupComboBox;
    DietaryRequirements: TDBEdit;
    Mobility: TDBEdit;
    OtherNeeds: TDBEdit;
    MediaFirstChoice: TDBLookupComboBox;
    MediaSecondChoice: TDBLookupComboBox;
    SecondMedia: TPanel;
    UKOnlyPanel: TPanel;
    ChairPanel: TPanel;
    TravelMethod: TDBLookupComboBox;
    OtherTravelRequirements: TDBEdit;
    AirTravelPanel: TPanel;
    OrigAirport: TDBEdit;
    PrefAirline: TDBEdit;
    FirstMedia: TPanel;
    OverseasMessage: THTMLSpan;
    EmergencyCopyButton: TButton;
    TravelPanel: TPanel;
    Procedure DietaryRequirementsExit(Sender: TObject);
    Procedure EmergencyContactPhoneExit(Sender: TObject);
    Procedure EmergencyContactPhoneKeyPress(Sender: TObject; Var Key: Char);
    Procedure EmergencyCopyButtonClick(Sender: TObject);
    Procedure MediaFirstChoiceChange(Sender: TObject);
    Procedure MediaSecondChoiceChange(Sender: TObject);
    Procedure MobilityExit(Sender: TObject);
    Procedure TravelMethodChange(Sender: TObject);
    Procedure TravelMethodExit(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
  Private
    { Private declarations }
    FCustomerType: TCustomerType;
    FMediaCount: Integer;
    FLastSelectedMedia: String;
    Procedure SetCustomerType(Const Value: TCustomerType);
    function GetDataset: TDataset;
    procedure SetDataset(const Value: TDataset);
    Property CustomerType: TCustomerType Read FCustomerType Write SetCustomerType;
  Protected
    Function GetBookingStateStage: TBookingState; Override;
    Procedure SetDisplayingAs(Const Value: TBookingPage); Override;
    Function IsValid(Const HighlightControls: Boolean = True): Boolean; Override;
    property Dataset: TDataset read GetDataset write SetDataset;
  Public
    { Public declarations }
    Function CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress; Override;
    [async]
    Procedure InitForm; async; Override;
    [async]
    Procedure LoadBooking; async; Override;
    Procedure SaveBooking; Override;
    Function NextPage: TBookingPage; Override;
    Function PreviousPage: TBookingPage; Override;
  protected procedure LoadDFMValues; override; End;

Var
  BookingRequirementsForm: TBookingRequirementsForm;

Implementation

{$R *.dfm}

Uses
  MainDataModule,
  SMX.Web.Document.Utils,
  SMX.Web.Utils,
  Holiday.Utils;

Procedure TBookingRequirementsForm.WebFormCreate(Sender: TObject);
Var
  lValue: String;
Begin
  Inherited;
//  TraceLog('Start webform create');
  EaselRequired.LookupValues.AddPair('True', 'Yes');
  EaselRequired.LookupValues.AddPair('False', 'No');

  ChairRequired.LookupValues.AddPair('True', 'Yes');
  ChairRequired.LookupValues.AddPair('False', 'No');

  BoardRequired.LookupValues.AddPair('True', 'Yes');
  BoardRequired.LookupValues.AddPair('False', 'No');

  FLastSelectedMedia := Media_Types[TMediaType.None];

  FMediaCount := MainData.MediaCount;

  FirstMedia.Visible := FMediaCount > 1;
  SecondMedia.Visible := FMediaCount > 1;

  MediaFirstChoice.LookupValues.AddPair(Media_Types[TMediaType.None], 'Please select');
  MediaSecondChoice.LookupValues.AddPair(lValue, 'None');

  If MainData.HolidayDataMediaWaterColours.Value Then
  Begin
    lValue := Media_Types[TMediaType.Watercolours];
    MediaFirstChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Watercolours]);
    MediaSecondChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Watercolours]);
  End;

  If MainData.HolidayDataMediaOil.Value Then
  Begin
    lValue := Media_Types[TMediaType.Oils];
    MediaFirstChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Oils]);
    MediaSecondChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Oils]);
  End;

  If MainData.HolidayDataMediaAcrylic.Value Then
  Begin
    lValue := Media_Types[TMediaType.Acrylics];
    MediaFirstChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Acrylics]);
    MediaSecondChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Acrylics]);
  End;

  If MainData.HolidayDataMediaPastel.Value Then
  Begin
    lValue := Media_Types[TMediaType.Pastels];
    MediaFirstChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Pastels]);
    MediaSecondChoice.LookupValues.AddPair(lValue, Media_Types[TMediaType.Pastels]);
  End;

//  TraceLog('End webform create');
End;

{ TBookingRequirementsForm }

Function TBookingRequirementsForm.CanCloseBookingPage(Const ANextPage: Boolean = True): TCanProgress;
Begin
  If Not ANextPage Then
    Result := TCanProgress.ccImmediate
  Else If IsValid(True) Then
    Result := TCanProgress.ccWait
  Else
    Result := TCanProgress.ccNo;
End;

Procedure TBookingRequirementsForm.DietaryRequirementsExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TBookingRequirementsForm.EmergencyContactPhoneExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := TUtils.IsPhoneNumberValid(EmergencyContactPhone.Text);
  If EmergencyContactPhone.Text = '' Then
    TDocUtils.setControlValidity(EmergencyContactPhone.ElementID, vsNone)
  Else
    TDocUtils.setControlValidity(EmergencyContactPhone.ElementID, Valid_Check[ControlValid]);
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TBookingRequirementsForm.EmergencyContactPhoneKeyPress(Sender: TObject; Var Key: Char);
Begin
  If Not(Key In ['0' .. '9', ' ', '-', '+']) Then
    Key := #0;
End;

Procedure TBookingRequirementsForm.EmergencyCopyButtonClick(Sender: TObject);
Begin
  Inherited;
  MainData.CopyEmergencyDetailsFromCustomer;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Function TBookingRequirementsForm.GetBookingStateStage: TBookingState;
Begin
  If FCustomerType = TCustomerType.Primary Then
    Result := TBookingState.Guest1Entered
  Else
    Result := TBookingState.Guest2Entered;
End;

function TBookingRequirementsForm.GetDataset: TDataset;
begin
  result := GuestSource.DataSet;
end;

Procedure TBookingRequirementsForm.InitForm;
Var
  lHolidayType: THolidayType;
Begin
  //TraceLog('Starting Page Init');
  Inherited;


  lHolidayType := THolidayUtils.StrToHolidayType(MainData.HolidaysDatasetHolidayType.Value);
  ChairPanel.Visible := (Not(lHolidayType In No_Chair_Holiday_Types)) And (Not MainData.SelectedVenue.IsAbroad);

  OverseasMessage.Visible := MainData.SelectedVenue.IsAbroad;

  UKOnlyPanel.Visible := Not MainData.SelectedVenue.IsAbroad;

  TravelMethod.LookupValues.AddPair('1', 'Car');
  TravelMethod.LookupValues.AddPair('2', 'Train');
  TravelMethod.LookupValues.AddPair('3', 'Bus');
  TravelMethod.LookupValues.AddPair('4', 'Plane');

//  TraceLog('Ending Page Init');

End;

Function TBookingRequirementsForm.IsValid(Const HighlightControls: Boolean = True): Boolean;
Var
  ControlValid: Boolean;
  lMedia, lPhone: String;
Begin
  Result := Inherited IsValid(HighlightControls);
  If Not Result Then
    Exit;

  // haven't started yet
  If (Not Dataset.Active) Or Dataset.IsEmpty Then
    Exit;

  lPhone := EmergencyContactPhone.Text;
  lPhone := lPhone.Trim;
  If lPhone = '' Then
    TDocUtils.setControlValidity(EmergencyContactPhone.ElementID, vsNone)
  Else
  Begin
    ControlValid := TUtils.IsPhoneNumberValid(lPhone);
    If HighlightControls Then
      TDocUtils.setControlValidity(EmergencyContactPhone.ElementID, Valid_Check[ControlValid]);
    Result := Result And ControlValid;
  End;

  If PainterPanel.Visible And FirstMedia.Visible Then
  Begin
    lMedia := Dataset.FieldByName('Media1').AsString;
    ControlValid := (lMedia <> '') And (lMedia <> Media_Types[TMediaType.None]);
    If HighlightControls Then
      TDocUtils.setControlValidity(MediaFirstChoice.ElementID, Valid_Check[ControlValid]);
    Result := Result And ControlValid;
  End;

  If TravelPanel.Visible Then
  Begin
    ControlValid := Dataset.FieldByName('TransportId').AsInteger > 0;
    If HighlightControls Then
      TDocUtils.setControlValidity(TravelMethod.ElementID, Valid_Check[ControlValid]);
    Result := Result And ControlValid;
  End;

End;

Procedure TBookingRequirementsForm.LoadBooking;
Begin
//  TraceLog('Start LoadBooking');
  Inherited;
  case FCustomerType of
    TCustomerType.Primary: Dataset := MainData.GuestDataset;
    TCustomerType.Secondary: Dataset := MainData.GuestDataset2;
  end;

  MainData.FindGuestRecord(FCustomerType);

  If MainData.GuestIsPainter(FCustomerType) Then
    PainterPanel.Visible := FirstMedia.Visible Or UKOnlyPanel.Visible Or ChairPanel.Visible
  Else
    PainterPanel.Visible := False;

//  TraceLog('Putting dataset into Edit Mode');
  MainData.EditDataset(Dataset);

//  TraceLog('Setting default TransportId');
  If MainData.SelectedVenue.IsAbroad And (Dataset.FieldByName('TransportId').AsInteger = 0) Then
    Dataset.FieldByName('TransportId').AsInteger := 4;

  AirTravelPanel.Visible := False;

//  TraceLog('Setting travel panel visibility');
  If (CustomerType = TCustomerType.Secondary) And MainData.Customer2AddressSameAsParent.Value Then
    TravelPanel.Visible := False
  Else
  Begin
    TravelPanel.Visible := True;
    AirTravelPanel.Visible := (TravelMethod.Value = '4') And MainData.SelectedVenue.IsAbroad;
  End;

  If FCustomerType = TCustomerType.Primary Then
    UpdateMainForm(wbsEnabled, GetAButtonState(IsValid(False)), Crumb_Customer_Details)
  Else
    UpdateMainForm(wbsEnabled, GetAButtonState(IsValid(False)), Crumb_Guest_Details);

//  TraceLog('End LoadBooking');
End;

Procedure TBookingRequirementsForm.MediaFirstChoiceChange(Sender: TObject);
Var
  lMedia1, lMedia2: String;
  I: Integer;
Begin

  lMedia1 := MediaFirstChoice.Value;

  If lMedia1 = Media_Types[TMediaType.None] Then
  Begin
    If FLastSelectedMedia <> Media_Types[TMediaType.None] Then
      Dataset.FieldByName('Media1').AsString := FLastSelectedMedia;
  End
  Else
    FLastSelectedMedia := lMedia1;

  If FMediaCount > 1 Then
  Begin
    If MediaSecondChoice.Value = lMedia1 Then
      Dataset.FieldByName('Media2').AsString := Media_Types[TMediaType.None];
  End;

  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);

End;

Procedure TBookingRequirementsForm.MediaSecondChoiceChange(Sender: TObject);
Var
  lMedia1, lMedia2: String;
Begin
  lMedia1 := MediaFirstChoice.Value;
  lMedia2 := MediaSecondChoice.Value;

  If lMedia2 = lMedia1 Then
  Begin
    Dataset.FieldByName('Media2').AsString := Media_Types[TMediaType.None];
    ShowMessage('you cannot select the same option for both media choices.');
  End;

  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TBookingRequirementsForm.MobilityExit(Sender: TObject);
Begin
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Function TBookingRequirementsForm.NextPage: TBookingPage;
Begin
  If (FCustomerType = TCustomerType.Primary) And (MainData.HolidayBooking.GuestCount = 2) Then
    Result := TBookingPage.bpGuestDetails
  Else
    Result := TBookingPage.bpOptionalExtras;
End;

Function TBookingRequirementsForm.PreviousPage: TBookingPage;
Begin
  If (FCustomerType = TCustomerType.Primary) Then
    Result := TBookingPage.bpCustomerDetails
  Else // secondary guest
    Result := TBookingPage.bpGuestDetails
End;

Procedure TBookingRequirementsForm.SaveBooking;
Begin
  Inherited;
  MainData.SaveGuest(FCustomerType);
End;

Procedure TBookingRequirementsForm.SetCustomerType(Const Value: TCustomerType);
Begin
  FCustomerType := Value;
  If FCustomerType = TCustomerType.Primary Then
    PageHeading.Caption := 'Your holiday requirements'
  Else
    PageHeading.Caption := MainData.HolidayBooking.GuestName + '''s holiday requirements';

  EmergencyCopyButton.Visible := FCustomerType = TCustomerType.Secondary;
End;

procedure TBookingRequirementsForm.SetDataset(const Value: TDataset);
begin
  GuestSource.DataSet := Value;
end;

Procedure TBookingRequirementsForm.SetDisplayingAs(Const Value: TBookingPage);
Begin
  Inherited;
  CustomerType := CustomerTypeForPage[DisplayingAs];
End;

Procedure TBookingRequirementsForm.TravelMethodChange(Sender: TObject);
Begin
  AirTravelPanel.Visible := (TravelMethod.Value = '4') And MainData.SelectedVenue.IsAbroad;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
End;

Procedure TBookingRequirementsForm.TravelMethodExit(Sender: TObject);
Var
  ControlValid: Boolean;
Begin
  ControlValid := Dataset.FieldByName('TransportId').AsInteger > 0;
  UpdateMainForm(wbsNoChange, GetAButtonState(IsValid(False)), Crumb_No_Change);
  TDocUtils.setControlValidity(TravelMethod.ElementID, Valid_Check[ControlValid]);
End;

procedure TBookingRequirementsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PageHeading := TLabel.Create('RegistrationHeading');
  EmergencyContactName := TDBEdit.Create('EmergencyContactName');
  EmergencyContactPhone := TDBEdit.Create('EmergencyContactPhone');
  EmergencyContactRelation := TDBEdit.Create('EmergencyContactRelation');
  PainterPanel := TPanel.Create('PainterPanel');
  UKOnlyPanel := TPanel.Create('UKOnlyPanel');
  EaselRequired := TDBLookupComboBox.Create('EaselRequired');
  BoardRequired := TDBLookupComboBox.Create('BoardRequired');
  ChairPanel := TPanel.Create('ChairPanel');
  ChairRequired := TDBLookupComboBox.Create('ChairRequired');
  FirstMedia := TPanel.Create('FirstMedia');
  MediaFirstChoice := TDBLookupComboBox.Create('MediaFirstChoice');
  SecondMedia := TPanel.Create('SecondMedia');
  MediaSecondChoice := TDBLookupComboBox.Create('MediaSecondChoice');
  OverseasMessage := THTMLSpan.Create('OverseasMessage');
  DietaryRequirements := TDBEdit.Create('DietaryRequirements');
  Mobility := TDBEdit.Create('Mobility');
  OtherNeeds := TDBEdit.Create('OtherNeeds');
  EmergencyCopyButton := TButton.Create('EmergencyCopyButton');
  TravelPanel := TPanel.Create('TravelPanel');
  TravelMethod := TDBLookupComboBox.Create('TravelMethod');
  OtherTravelRequirements := TDBEdit.Create('OtherTravelRequirements');
  AirTravelPanel := TPanel.Create('AirTravelPanel');
  OrigAirport := TDBEdit.Create('OrigAirport');
  PrefAirline := TDBEdit.Create('PrefAirline');
  GuestSource := TDataSource.Create(Self);

  HolidaySelectedLabel.BeforeLoadDFMValues;
  PageHeading.BeforeLoadDFMValues;
  PriceBreakDown.BeforeLoadDFMValues;
  EmergencyContactName.BeforeLoadDFMValues;
  EmergencyContactPhone.BeforeLoadDFMValues;
  EmergencyContactRelation.BeforeLoadDFMValues;
  PainterPanel.BeforeLoadDFMValues;
  UKOnlyPanel.BeforeLoadDFMValues;
  EaselRequired.BeforeLoadDFMValues;
  BoardRequired.BeforeLoadDFMValues;
  ChairPanel.BeforeLoadDFMValues;
  ChairRequired.BeforeLoadDFMValues;
  FirstMedia.BeforeLoadDFMValues;
  MediaFirstChoice.BeforeLoadDFMValues;
  SecondMedia.BeforeLoadDFMValues;
  MediaSecondChoice.BeforeLoadDFMValues;
  OverseasMessage.BeforeLoadDFMValues;
  DietaryRequirements.BeforeLoadDFMValues;
  Mobility.BeforeLoadDFMValues;
  OtherNeeds.BeforeLoadDFMValues;
  EmergencyCopyButton.BeforeLoadDFMValues;
  TravelPanel.BeforeLoadDFMValues;
  TravelMethod.BeforeLoadDFMValues;
  OtherTravelRequirements.BeforeLoadDFMValues;
  AirTravelPanel.BeforeLoadDFMValues;
  OrigAirport.BeforeLoadDFMValues;
  PrefAirline.BeforeLoadDFMValues;
  GuestSource.BeforeLoadDFMValues;
  try
    Width := 676;
    Height := 758;
    HolidaySelectedLabel.Left := 16;
    HolidaySelectedLabel.Top := 80;
    PageHeading.SetParentComponent(Self);
    PageHeading.Name := 'PageHeading';
    PageHeading.Left := 16;
    PageHeading.Top := 112;
    PageHeading.Width := 132;
    PageHeading.Height := 18;
    PageHeading.Caption := 'Registration Heading';
    PageHeading.ElementFont := efCSS;
    PageHeading.ElementPosition := epIgnore;
    PageHeading.HeightStyle := ssAuto;
    PageHeading.HeightPercent := 100.000000000000000000;
    PageHeading.WidthStyle := ssAuto;
    PageHeading.WidthPercent := 100.000000000000000000;
    PriceBreakDown.Left := 415;
    PriceBreakDown.Top := 117;
    EmergencyContactName.SetParentComponent(Self);
    EmergencyContactName.Name := 'EmergencyContactName';
    EmergencyContactName.Left := 16;
    EmergencyContactName.Top := 160;
    EmergencyContactName.Width := 177;
    EmergencyContactName.Height := 22;
    EmergencyContactName.ChildOrder := 4;
    EmergencyContactName.ElementClassName := 'form-control';
    EmergencyContactName.ElementFont := efCSS;
    EmergencyContactName.ElementPosition := epIgnore;
    EmergencyContactName.HeightStyle := ssAuto;
    EmergencyContactName.HeightPercent := 100.000000000000000000;
    EmergencyContactName.TabOrder := 1;
    EmergencyContactName.Text := 'EmergencyContactName';
    EmergencyContactName.WidthStyle := ssAuto;
    EmergencyContactName.WidthPercent := 100.000000000000000000;
    EmergencyContactName.DataField := 'Emergencycontactname';
    EmergencyContactName.DataSource := GuestSource;
    EmergencyContactPhone.SetParentComponent(Self);
    EmergencyContactPhone.Name := 'EmergencyContactPhone';
    EmergencyContactPhone.Left := 16;
    EmergencyContactPhone.Top := 188;
    EmergencyContactPhone.Width := 177;
    EmergencyContactPhone.Height := 22;
    EmergencyContactPhone.ChildOrder := 4;
    EmergencyContactPhone.ElementClassName := 'form-control';
    EmergencyContactPhone.ElementFont := efCSS;
    EmergencyContactPhone.ElementPosition := epIgnore;
    EmergencyContactPhone.HeightStyle := ssAuto;
    EmergencyContactPhone.HeightPercent := 100.000000000000000000;
    EmergencyContactPhone.TabOrder := 2;
    EmergencyContactPhone.Text := 'EmergencyContactPhone';
    EmergencyContactPhone.WidthStyle := ssAuto;
    EmergencyContactPhone.WidthPercent := 100.000000000000000000;
    SetEvent(EmergencyContactPhone, Self, 'OnExit', 'EmergencyContactPhoneExit');
    SetEvent(EmergencyContactPhone, Self, 'OnKeyPress', 'EmergencyContactPhoneKeyPress');
    EmergencyContactPhone.DataField := 'Emergencycontacttelephone';
    EmergencyContactPhone.DataSource := GuestSource;
    EmergencyContactRelation.SetParentComponent(Self);
    EmergencyContactRelation.Name := 'EmergencyContactRelation';
    EmergencyContactRelation.Left := 16;
    EmergencyContactRelation.Top := 216;
    EmergencyContactRelation.Width := 177;
    EmergencyContactRelation.Height := 22;
    EmergencyContactRelation.ChildOrder := 4;
    EmergencyContactRelation.ElementClassName := 'form-control';
    EmergencyContactRelation.ElementFont := efCSS;
    EmergencyContactRelation.ElementPosition := epIgnore;
    EmergencyContactRelation.HeightStyle := ssAuto;
    EmergencyContactRelation.HeightPercent := 100.000000000000000000;
    EmergencyContactRelation.TabOrder := 2;
    EmergencyContactRelation.Text := 'EmergencyContactRelation';
    EmergencyContactRelation.WidthStyle := ssAuto;
    EmergencyContactRelation.WidthPercent := 100.000000000000000000;
    EmergencyContactRelation.DataField := 'Emergencycontactrelation';
    EmergencyContactRelation.DataSource := GuestSource;
    PainterPanel.SetParentComponent(Self);
    PainterPanel.Name := 'PainterPanel';
    PainterPanel.Left := 16;
    PainterPanel.Top := 264;
    PainterPanel.Width := 544;
    PainterPanel.Height := 225;
    PainterPanel.ElementClassName := 'card';
    PainterPanel.WidthStyle := ssAuto;
    PainterPanel.BorderColor := clNone;
    PainterPanel.BorderStyle := bsNone;
    PainterPanel.ChildOrder := 7;
    PainterPanel.Color := clWindow;
    PainterPanel.ElementBodyClassName := 'card-body';
    PainterPanel.ElementFont := efCSS;
    PainterPanel.ElementPosition := epIgnore;
    PainterPanel.TabOrder := 7;
    UKOnlyPanel.SetParentComponent(PainterPanel);
    UKOnlyPanel.Name := 'UKOnlyPanel';
    UKOnlyPanel.Left := 24;
    UKOnlyPanel.Top := 16;
    UKOnlyPanel.Width := 201;
    UKOnlyPanel.Height := 97;
    UKOnlyPanel.ElementClassName := 'card';
    UKOnlyPanel.HeightStyle := ssAuto;
    UKOnlyPanel.WidthStyle := ssAuto;
    UKOnlyPanel.ChildOrder := 5;
    UKOnlyPanel.Color := clWindow;
    UKOnlyPanel.ElementBodyClassName := 'card-body';
    UKOnlyPanel.ElementFont := efCSS;
    UKOnlyPanel.ElementPosition := epIgnore;
    UKOnlyPanel.TabOrder := 0;
    EaselRequired.SetParentComponent(UKOnlyPanel);
    EaselRequired.Name := 'EaselRequired';
    EaselRequired.Left := 24;
    EaselRequired.Top := 14;
    EaselRequired.Width := 145;
    EaselRequired.Height := 22;
    EaselRequired.ElementClassName := 'form-select';
    EaselRequired.ElementFont := efCSS;
    EaselRequired.ElementPosition := epIgnore;
    EaselRequired.HeightStyle := ssAuto;
    EaselRequired.HeightPercent := 100.000000000000000000;
    EaselRequired.TabOrder := 13;
    EaselRequired.WidthStyle := ssAuto;
    EaselRequired.WidthPercent := 100.000000000000000000;
    EaselRequired.DataField := 'Easelrequired';
    EaselRequired.DataSource := GuestSource;
    EaselRequired.KeyField := 'Value';
    EaselRequired.ListField := 'Description';
    EaselRequired.ListSource := MainData.DummyLookUpSource;
    BoardRequired.SetParentComponent(UKOnlyPanel);
    BoardRequired.Name := 'BoardRequired';
    BoardRequired.Left := 24;
    BoardRequired.Top := 52;
    BoardRequired.Width := 145;
    BoardRequired.Height := 22;
    BoardRequired.ElementClassName := 'form-select';
    BoardRequired.ElementFont := efCSS;
    BoardRequired.ElementPosition := epIgnore;
    BoardRequired.HeightStyle := ssAuto;
    BoardRequired.HeightPercent := 100.000000000000000000;
    BoardRequired.TabOrder := 14;
    BoardRequired.WidthStyle := ssAuto;
    BoardRequired.WidthPercent := 100.000000000000000000;
    BoardRequired.DataField := 'Boardrequired';
    BoardRequired.DataSource := GuestSource;
    BoardRequired.KeyField := 'Value';
    BoardRequired.ListField := 'Description';
    BoardRequired.ListSource := MainData.DummyLookUpSource;
    ChairPanel.SetParentComponent(PainterPanel);
    ChairPanel.Name := 'ChairPanel';
    ChairPanel.Left := 24;
    ChairPanel.Top := 119;
    ChairPanel.Width := 193;
    ChairPanel.Height := 60;
    ChairPanel.ElementClassName := 'card';
    ChairPanel.HeightStyle := ssAuto;
    ChairPanel.WidthStyle := ssAuto;
    ChairPanel.ChildOrder := 4;
    ChairPanel.Color := clWindow;
    ChairPanel.ElementBodyClassName := 'card-body';
    ChairPanel.ElementFont := efCSS;
    ChairPanel.ElementPosition := epIgnore;
    ChairPanel.TabOrder := 1;
    ChairRequired.SetParentComponent(ChairPanel);
    ChairRequired.Name := 'ChairRequired';
    ChairRequired.Left := 21;
    ChairRequired.Top := 22;
    ChairRequired.Width := 145;
    ChairRequired.Height := 22;
    ChairRequired.ElementClassName := 'form-select';
    ChairRequired.ElementFont := efCSS;
    ChairRequired.ElementPosition := epIgnore;
    ChairRequired.HeightStyle := ssAuto;
    ChairRequired.HeightPercent := 100.000000000000000000;
    ChairRequired.TabOrder := 16;
    ChairRequired.WidthStyle := ssAuto;
    ChairRequired.WidthPercent := 100.000000000000000000;
    ChairRequired.DataField := 'Foldingchairrequired';
    ChairRequired.DataSource := GuestSource;
    ChairRequired.KeyField := 'Value';
    ChairRequired.ListField := 'Description';
    ChairRequired.ListSource := MainData.DummyLookUpSource;
    FirstMedia.SetParentComponent(PainterPanel);
    FirstMedia.Name := 'FirstMedia';
    FirstMedia.Left := 267;
    FirstMedia.Top := -2;
    FirstMedia.Width := 230;
    FirstMedia.Height := 116;
    FirstMedia.ElementClassName := 'card';
    FirstMedia.HeightStyle := ssAuto;
    FirstMedia.WidthStyle := ssAuto;
    FirstMedia.BorderColor := clNone;
    FirstMedia.BorderStyle := bsNone;
    FirstMedia.ChildOrder := 5;
    FirstMedia.Color := clWindow;
    FirstMedia.ElementBodyClassName := 'card-body';
    FirstMedia.ElementFont := efCSS;
    FirstMedia.ElementPosition := epIgnore;
    FirstMedia.TabOrder := 2;
    FirstMedia.TabStop := True;
    MediaFirstChoice.SetParentComponent(FirstMedia);
    MediaFirstChoice.Name := 'MediaFirstChoice';
    MediaFirstChoice.Left := 1;
    MediaFirstChoice.Top := 13;
    MediaFirstChoice.Width := 145;
    MediaFirstChoice.Height := 22;
    MediaFirstChoice.ElementClassName := 'form-select';
    MediaFirstChoice.ElementFont := efCSS;
    MediaFirstChoice.ElementPosition := epIgnore;
    MediaFirstChoice.HeightStyle := ssAuto;
    MediaFirstChoice.HeightPercent := 100.000000000000000000;
    MediaFirstChoice.TabOrder := 18;
    MediaFirstChoice.WidthStyle := ssAuto;
    MediaFirstChoice.WidthPercent := 100.000000000000000000;
    SetEvent(MediaFirstChoice, Self, 'OnChange', 'MediaFirstChoiceChange');
    MediaFirstChoice.DataField := 'Media1';
    MediaFirstChoice.DataSource := GuestSource;
    MediaFirstChoice.KeyField := 'Value';
    MediaFirstChoice.ListField := 'Description';
    MediaFirstChoice.ListSource := MainData.DummyLookUpSource;
    SecondMedia.SetParentComponent(FirstMedia);
    SecondMedia.Name := 'SecondMedia';
    SecondMedia.Left := 3;
    SecondMedia.Top := 41;
    SecondMedia.Width := 150;
    SecondMedia.Height := 60;
    SecondMedia.ElementClassName := 'card';
    SecondMedia.HeightStyle := ssAuto;
    SecondMedia.WidthStyle := ssAuto;
    SecondMedia.BorderColor := clNone;
    SecondMedia.BorderStyle := bsNone;
    SecondMedia.ChildOrder := 5;
    SecondMedia.Color := clWindow;
    SecondMedia.ElementBodyClassName := 'card-body';
    SecondMedia.ElementFont := efCSS;
    SecondMedia.ElementPosition := epIgnore;
    SecondMedia.TabOrder := 1;
    SecondMedia.TabStop := True;
    MediaSecondChoice.SetParentComponent(SecondMedia);
    MediaSecondChoice.Name := 'MediaSecondChoice';
    MediaSecondChoice.Left := 2;
    MediaSecondChoice.Top := 14;
    MediaSecondChoice.Width := 145;
    MediaSecondChoice.Height := 22;
    MediaSecondChoice.ElementClassName := 'form-select';
    MediaSecondChoice.ElementFont := efCSS;
    MediaSecondChoice.ElementPosition := epIgnore;
    MediaSecondChoice.HeightStyle := ssAuto;
    MediaSecondChoice.HeightPercent := 100.000000000000000000;
    MediaSecondChoice.TabOrder := 20;
    MediaSecondChoice.WidthStyle := ssAuto;
    MediaSecondChoice.WidthPercent := 100.000000000000000000;
    SetEvent(MediaSecondChoice, Self, 'OnChange', 'MediaSecondChoiceChange');
    MediaSecondChoice.DataField := 'Media2';
    MediaSecondChoice.DataSource := GuestSource;
    MediaSecondChoice.KeyField := 'Value';
    MediaSecondChoice.ListField := 'Description';
    MediaSecondChoice.ListSource := MainData.DummyLookUpSource;
    OverseasMessage.SetParentComponent(PainterPanel);
    OverseasMessage.Name := 'OverseasMessage';
    OverseasMessage.Left := 4;
    OverseasMessage.Top := 181;
    OverseasMessage.Width := 537;
    OverseasMessage.Height := 41;
    OverseasMessage.HeightStyle := ssAuto;
    OverseasMessage.WidthStyle := ssAuto;
    OverseasMessage.ChildOrder := 3;
    OverseasMessage.ElementPosition := epIgnore;
    OverseasMessage.ElementFont := efCSS;
    OverseasMessage.HTML.BeginUpdate;
    try
      OverseasMessage.HTML.Clear;
      OverseasMessage.HTML.Add('On this holiday we include the use of an easel, folding chair, board and kitchen roll, as required.');
    finally
      OverseasMessage.HTML.EndUpdate;
    end;
    OverseasMessage.Role := '';
    OverseasMessage.Visible := False;
    DietaryRequirements.SetParentComponent(Self);
    DietaryRequirements.Name := 'DietaryRequirements';
    DietaryRequirements.Left := 232;
    DietaryRequirements.Top := 160;
    DietaryRequirements.Width := 177;
    DietaryRequirements.Height := 22;
    DietaryRequirements.ChildOrder := 4;
    DietaryRequirements.ElementClassName := 'form-control';
    DietaryRequirements.ElementFont := efCSS;
    DietaryRequirements.ElementPosition := epIgnore;
    DietaryRequirements.HeightStyle := ssAuto;
    DietaryRequirements.HeightPercent := 100.000000000000000000;
    DietaryRequirements.TabOrder := 4;
    DietaryRequirements.Text := 'DietaryRequirements';
    DietaryRequirements.WidthStyle := ssAuto;
    DietaryRequirements.WidthPercent := 100.000000000000000000;
    SetEvent(DietaryRequirements, Self, 'OnExit', 'DietaryRequirementsExit');
    DietaryRequirements.DataField := 'Dietaryrequirements';
    DietaryRequirements.DataSource := GuestSource;
    Mobility.SetParentComponent(Self);
    Mobility.Name := 'Mobility';
    Mobility.Left := 232;
    Mobility.Top := 188;
    Mobility.Width := 177;
    Mobility.Height := 22;
    Mobility.ChildOrder := 4;
    Mobility.ElementClassName := 'form-control';
    Mobility.ElementFont := efCSS;
    Mobility.ElementPosition := epIgnore;
    Mobility.HeightStyle := ssAuto;
    Mobility.HeightPercent := 100.000000000000000000;
    Mobility.TabOrder := 4;
    Mobility.Text := 'Mobility';
    Mobility.WidthStyle := ssAuto;
    Mobility.WidthPercent := 100.000000000000000000;
    SetEvent(Mobility, Self, 'OnExit', 'MobilityExit');
    Mobility.DataField := 'Mobility';
    Mobility.DataSource := GuestSource;
    OtherNeeds.SetParentComponent(Self);
    OtherNeeds.Name := 'OtherNeeds';
    OtherNeeds.Left := 232;
    OtherNeeds.Top := 216;
    OtherNeeds.Width := 177;
    OtherNeeds.Height := 22;
    OtherNeeds.ChildOrder := 4;
    OtherNeeds.ElementClassName := 'form-control';
    OtherNeeds.ElementFont := efCSS;
    OtherNeeds.ElementPosition := epIgnore;
    OtherNeeds.HeightStyle := ssAuto;
    OtherNeeds.HeightPercent := 100.000000000000000000;
    OtherNeeds.TabOrder := 6;
    OtherNeeds.Text := 'OtherNeeds';
    OtherNeeds.WidthStyle := ssAuto;
    OtherNeeds.WidthPercent := 100.000000000000000000;
    OtherNeeds.DataField := 'Otherneeds';
    OtherNeeds.DataSource := GuestSource;
    EmergencyCopyButton.SetParentComponent(Self);
    EmergencyCopyButton.Name := 'EmergencyCopyButton';
    EmergencyCopyButton.Left := 154;
    EmergencyCopyButton.Top := 129;
    EmergencyCopyButton.Width := 241;
    EmergencyCopyButton.Height := 25;
    EmergencyCopyButton.Caption := 'Use my Emergency Contact Details';
    EmergencyCopyButton.ChildOrder := 13;
    EmergencyCopyButton.ElementClassName := 'btn btn-light';
    EmergencyCopyButton.ElementFont := efCSS;
    EmergencyCopyButton.ElementPosition := epIgnore;
    EmergencyCopyButton.HeightStyle := ssAuto;
    EmergencyCopyButton.HeightPercent := 100.000000000000000000;
    EmergencyCopyButton.WidthStyle := ssAuto;
    EmergencyCopyButton.WidthPercent := 100.000000000000000000;
    SetEvent(EmergencyCopyButton, Self, 'OnClick', 'EmergencyCopyButtonClick');
    TravelPanel.SetParentComponent(Self);
    TravelPanel.Name := 'TravelPanel';
    TravelPanel.Left := 20;
    TravelPanel.Top := 504;
    TravelPanel.Width := 280;
    TravelPanel.Height := 220;
    TravelPanel.ElementClassName := 'card';
    TravelPanel.HeightStyle := ssAuto;
    TravelPanel.WidthStyle := ssAuto;
    TravelPanel.ChildOrder := 4;
    TravelPanel.Color := clWindow;
    TravelPanel.ElementBodyClassName := 'card-body';
    TravelPanel.ElementFont := efCSS;
    TravelPanel.ElementPosition := epIgnore;
    TravelPanel.TabOrder := 9;
    TravelMethod.SetParentComponent(TravelPanel);
    TravelMethod.Name := 'TravelMethod';
    TravelMethod.Left := 20;
    TravelMethod.Top := 31;
    TravelMethod.Width := 145;
    TravelMethod.Height := 22;
    TravelMethod.ElementClassName := 'form-select';
    TravelMethod.ElementFont := efCSS;
    TravelMethod.ElementPosition := epIgnore;
    TravelMethod.HeightStyle := ssAuto;
    TravelMethod.HeightPercent := 100.000000000000000000;
    TravelMethod.TabOrder := 7;
    TravelMethod.WidthStyle := ssAuto;
    TravelMethod.WidthPercent := 100.000000000000000000;
    SetEvent(TravelMethod, Self, 'OnChange', 'TravelMethodChange');
    SetEvent(TravelMethod, Self, 'OnExit', 'TravelMethodExit');
    TravelMethod.DataField := 'TransportId';
    TravelMethod.DataSource := GuestSource;
    TravelMethod.ListSource := MainData.DummyLookUpSource;
    OtherTravelRequirements.SetParentComponent(TravelPanel);
    OtherTravelRequirements.Name := 'OtherTravelRequirements';
    OtherTravelRequirements.Left := 20;
    OtherTravelRequirements.Top := 59;
    OtherTravelRequirements.Width := 209;
    OtherTravelRequirements.Height := 22;
    OtherTravelRequirements.ChildOrder := 8;
    OtherTravelRequirements.ElementClassName := 'form-control';
    OtherTravelRequirements.ElementFont := efCSS;
    OtherTravelRequirements.ElementPosition := epIgnore;
    OtherTravelRequirements.HeightStyle := ssAuto;
    OtherTravelRequirements.HeightPercent := 100.000000000000000000;
    OtherTravelRequirements.TabOrder := 9;
    OtherTravelRequirements.Text := 'OtherTravelRequirements';
    OtherTravelRequirements.WidthStyle := ssAuto;
    OtherTravelRequirements.WidthPercent := 100.000000000000000000;
    OtherTravelRequirements.DataField := 'OtherTravelRequirements';
    OtherTravelRequirements.DataSource := GuestSource;
    AirTravelPanel.SetParentComponent(TravelPanel);
    AirTravelPanel.Name := 'AirTravelPanel';
    AirTravelPanel.Left := 20;
    AirTravelPanel.Top := 108;
    AirTravelPanel.Width := 249;
    AirTravelPanel.Height := 109;
    AirTravelPanel.ElementClassName := 'card';
    AirTravelPanel.HeightStyle := ssAuto;
    AirTravelPanel.WidthStyle := ssAuto;
    AirTravelPanel.ChildOrder := 4;
    AirTravelPanel.Color := clWindow;
    AirTravelPanel.ElementBodyClassName := 'card-body';
    AirTravelPanel.ElementFont := efCSS;
    AirTravelPanel.ElementPosition := epIgnore;
    AirTravelPanel.TabOrder := 2;
    AirTravelPanel.Visible := False;
    OrigAirport.SetParentComponent(AirTravelPanel);
    OrigAirport.Name := 'OrigAirport';
    OrigAirport.Left := 3;
    OrigAirport.Top := 23;
    OrigAirport.Width := 209;
    OrigAirport.Height := 22;
    OrigAirport.ChildOrder := 11;
    OrigAirport.ElementClassName := 'form-control';
    OrigAirport.ElementFont := efCSS;
    OrigAirport.ElementPosition := epIgnore;
    OrigAirport.HeightStyle := ssAuto;
    OrigAirport.HeightPercent := 100.000000000000000000;
    OrigAirport.TabOrder := 9;
    OrigAirport.Text := 'OrigAirport';
    OrigAirport.WidthStyle := ssAuto;
    OrigAirport.WidthPercent := 100.000000000000000000;
    OrigAirport.DataField := 'OrigAirport';
    OrigAirport.DataSource := GuestSource;
    PrefAirline.SetParentComponent(AirTravelPanel);
    PrefAirline.Name := 'PrefAirline';
    PrefAirline.Left := 3;
    PrefAirline.Top := 63;
    PrefAirline.Width := 209;
    PrefAirline.Height := 22;
    PrefAirline.ChildOrder := 11;
    PrefAirline.ElementClassName := 'form-control';
    PrefAirline.ElementFont := efCSS;
    PrefAirline.ElementPosition := epIgnore;
    PrefAirline.HeightStyle := ssAuto;
    PrefAirline.HeightPercent := 100.000000000000000000;
    PrefAirline.TabOrder := 10;
    PrefAirline.Text := 'PrefAirline';
    PrefAirline.WidthStyle := ssAuto;
    PrefAirline.WidthPercent := 100.000000000000000000;
    PrefAirline.DataField := 'PrefAirline';
    PrefAirline.DataSource := GuestSource;
    GuestSource.SetParentComponent(Self);
    GuestSource.Name := 'GuestSource';
    GuestSource.Left := 320;
    GuestSource.Top := 48;
  finally
    HolidaySelectedLabel.AfterLoadDFMValues;
    PageHeading.AfterLoadDFMValues;
    PriceBreakDown.AfterLoadDFMValues;
    EmergencyContactName.AfterLoadDFMValues;
    EmergencyContactPhone.AfterLoadDFMValues;
    EmergencyContactRelation.AfterLoadDFMValues;
    PainterPanel.AfterLoadDFMValues;
    UKOnlyPanel.AfterLoadDFMValues;
    EaselRequired.AfterLoadDFMValues;
    BoardRequired.AfterLoadDFMValues;
    ChairPanel.AfterLoadDFMValues;
    ChairRequired.AfterLoadDFMValues;
    FirstMedia.AfterLoadDFMValues;
    MediaFirstChoice.AfterLoadDFMValues;
    SecondMedia.AfterLoadDFMValues;
    MediaSecondChoice.AfterLoadDFMValues;
    OverseasMessage.AfterLoadDFMValues;
    DietaryRequirements.AfterLoadDFMValues;
    Mobility.AfterLoadDFMValues;
    OtherNeeds.AfterLoadDFMValues;
    EmergencyCopyButton.AfterLoadDFMValues;
    TravelPanel.AfterLoadDFMValues;
    TravelMethod.AfterLoadDFMValues;
    OtherTravelRequirements.AfterLoadDFMValues;
    AirTravelPanel.AfterLoadDFMValues;
    OrigAirport.AfterLoadDFMValues;
    PrefAirline.AfterLoadDFMValues;
    GuestSource.AfterLoadDFMValues;
  end;
end;

End.
