unit HolidayPayments;

interface

uses
  JS,
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  Holiday.ReturnTypes,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.CDS,
  WEBLib.StdCtrls,
  BaseBooking,
  Vcl.Mask,
  WEBLib.Mask,
  WEBLib.PayPal,
  Booking.Web.Shared,
  Cyrus.Enumerations,
  DTO_Payments,
  WEBLib.WebCtrls;

type
  THolidayPaymentsForm = class(TBaseBookingForm)
    PayPalButton: TPayPal;
    CreditCardButton: TButton;
    BACSButton: TButton;
    ChequeButton: TButton;
    [async]
    AgreeReadTerms: TCheckBox;
    HolidayDetailsButton: TButton;
    PainterDetailsButton: TButton;
    GuestDetailsButton: TButton;
    OtherInfoButton: TButton;
    HolidayDetails: TPanel;
    PainterDetails: TPanel;
    OtherInfo: TPanel;
    DestinationLabel: TLabel;
    HotelLabel: TLabel;
    HolidayLabel: TLabel;
    PainterCountLabel: TLabel;
    RoomTypeLabel: TLabel;
    NPPInfo: TPanel;
    NPPCountLabel: TLabel;
    FirstNameLabel: TLabel;
    SurnameLabel: TLabel;
    PhoneLabel: TLabel;
    EmailLabel: TLabel;
    VaccinatedLabel: TLabel;
    VaccinatedDate: TPanel;
    VaccinatedDateLabel: TLabel;
    ChairPanel: TPanel;
    FoldingChairLabel: TLabel;
    UKOnlyPanel: TPanel;
    EaselRequiredLabel: TLabel;
    BoardRequiredLabel: TLabel;
    PainterPanel: TPanel;
    Media1Label: TLabel;
    Media2Panel: TPanel;
    Media2Label: TLabel;
    BookingSource: TDataSource;
    GuestSource: TDataSource;
    ExtraNightsBeforeLabel: TDBLabel;
    ExtraNightsAfterLabel: TDBLabel;
    RoomUpgradeLabel: TDBLabel;
    AddressLabel: THTMLSpan;
    EmergencyContactNameLabel: TLabel;
    EmergencyRelationLabel: TLabel;
    EmergencyTelephoneLabel: TLabel;
    DietaryReqsLabel: TLabel;
    MobilityLabel: TLabel;
    OtherNeedsLabel: TLabel;
    Room2Panel: TPanel;
    RoomType2Label: TLabel;
    DogsAllowed: TPanel;
    HasDogLabel: TLabel;
    TransportLabel: TLabel;
    OtherTravelLabel: TDBLabel;
    HearAboutLabel: THTMLDiv;
    MobileLabel: TLabel;
    HomePhonePanel: TPanel;
    TravelPanel: TPanel;
    procedure AgreeReadTermsClick(Sender: TObject);
    procedure BACSButtonClick(Sender: TObject);
    procedure ChequeButtonClick(Sender: TObject);

    procedure CreditCardButtonClick(Sender: TObject);
    procedure GuestDetailsButtonClick(Sender: TObject);
    procedure HolidayDetailsButtonClick(Sender: TObject);
    procedure OtherInfoButtonClick(Sender: TObject);
    procedure PainterDetailsButtonClick(Sender: TObject);
  private
    FCanLeavePage: Boolean;
    // Booking: TJSObject;
    FLoadedCustomer: TCustomerType;
    // Token: JSValue;
    FProvider: TPaymentProvider;

    [async]
    procedure CreatePayPalOrder; async;

    [async]
    procedure CreateRevolutOrder; async;
    procedure OpenRevolutPopup(const Token, Mode: string);
    function AmountToCharge: Double;
    procedure AfterPaymentProcessed(const PaymentState: TPaymentState);
    procedure SetPainterSummary(const CustomerType: TCustomerType);
    procedure OnReadyToProgress(const CanProgress: Boolean);
    procedure SetPaymentLevel(const Value: TPaymentLevel);
    function GetPaymentLevel: TPaymentLevel;
    function GetGuestDataset: TDataset;
  protected
    procedure SetDisplayingAs(const Value: TBookingPage); override;
    function IsValid(const HighlightControls: Boolean = True): Boolean; override;
    procedure TearDown; override;
    function GetBookingStateStage: TBookingState; override;

    [async]
    procedure RevolutSuccess; async;
    [async]
    procedure RevolutError(aMessage: string); async;

    [JSInvokable]
    procedure RevolutSuccessCallback;
    [JSInvokable]
    procedure RevolutErrorCallback(aMessage: string);

    procedure Confirm;
    property PaymentLevel: TPaymentLevel read GetPaymentLevel write SetPaymentLevel;
    property GuestDS: TDataset read GetGuestDataset;
  public
    [async]
    procedure InitForm; async; override;
    [async]
    procedure LoadBooking; async; override;
    procedure SaveBooking; override;
    function CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress; override;
    function NextPage: TBookingPage; override;
    function PreviousPage: TBookingPage; override;

  published
    [async]
    procedure PayPalButtonPaymentCancelled(Sender: TObject); async;
    [async]
    procedure PayPalButtonPaymentDone(Sender: TObject; Args: TPayPalPaymentEventArgs); async;
    [async]
    procedure PayPalButtonPaymentError(Sender: TObject; Args: TPayPalErrorEventArgs); async;

  protected procedure LoadDFMValues; override; end;

var
  HolidayPaymentsForm: THolidayPaymentsForm;

implementation

uses
  System.Rtti,
  System.DateUtils,
  MainDataModule,
  XData.Web.Client,
  SMX.Web.Document.Utils,
  Holiday.Utils,
  MainBookingForm,
  SMX.Web.Utils,
  Payment.Utils;

{$R *.dfm}

procedure THolidayPaymentsForm.AfterPaymentProcessed(const PaymentState: TPaymentState);
begin

  TPaymentUtils.ClearPaymentLevel;

  if PaymentState = TPaymentState.Success then
    Confirm;
end;

procedure THolidayPaymentsForm.AgreeReadTermsClick(Sender: TObject);
begin

  PayPalButton.Enabled := AgreeReadTerms.Checked;
  CreditCardButton.Enabled := AgreeReadTerms.Checked;
  BACSButton.Enabled := AgreeReadTerms.Checked;
  ChequeButton.Enabled := ChequeButton.Visible and AgreeReadTerms.Checked;

end;

function THolidayPaymentsForm.AmountToCharge: Double;
begin
  if MainData.NeedsFullPayment then
  begin
    Result := MainData.BookingDatasetTotalHolidayPrice.Value;
    PaymentLevel := TPaymentLevel.Balance;
  end
  else
  begin
    Result := MainData.BookingDatasetDepositDue.Value;
    PaymentLevel := TPaymentLevel.Deposit;
  end;
end;

procedure THolidayPaymentsForm.BACSButtonClick(Sender: TObject);
begin
  { TODO : Should we add a payment record for this? Or do we need a field in Bookings so we know what is expected? }
  FProvider := TPaymentProvider.BACS;
  MainData.NonPaymentConfirmation(FProvider);
  Confirm;
end;

procedure THolidayPaymentsForm.CreditCardButtonClick(Sender: TObject);
begin
  CreateRevolutOrder;
end;

function THolidayPaymentsForm.CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress;
begin
  if not ANextPage then
    Result := TCanProgress.ccImmediate
  else if IsValid then
    Result := TCanProgress.ccImmediate
  else
    Result := TCanProgress.ccNo;
end;

procedure THolidayPaymentsForm.ChequeButtonClick(Sender: TObject);
begin
  FProvider := TPaymentProvider.Cheque;
  MainData.NonPaymentConfirmation(FProvider);
  Confirm;
end;

procedure THolidayPaymentsForm.Confirm;
begin
  MainData.OnReadyToProgess := OnReadyToProgress;
  MainData.SaveBookingDatasets(BookingStateStage);
end;

procedure THolidayPaymentsForm.CreatePayPalOrder;
var
  PayPalItem: TPayPalItem;
begin
  // FProvider := TPaymentProvider.PayPal;
  // MainData.PaymentProvider := TPaymentProvider.PayPal;

  PayPalButton.BeginUpdate;
  PayPalButton.APIKey := Await(MainData.PaymentAPIKey(TPaymentProvider.PayPal));
  PayPalButton.Payment.Currency := pcGBP;
  PayPalButton.Payment.Items.Add;
  PayPalButton.Payment.Shipping := 0.00;
  PayPalButton.Payment.Description := MainData.BookingDatasetBookingId.AsString;

  PayPalItem := PayPalButton.Payment.Items[0];
  PayPalItem.Name := MainData.BookingDatasetBookingReference.Value;
  PayPalItem.Price := AmountToCharge;
  PayPalItem.Quantity := 1;

  PayPalButton.EndUpdate;
  PayPalButton.Visible := True;
end;

procedure THolidayPaymentsForm.CreateRevolutOrder;
var
  ClientResponse: TXDataClientResponse;
  Payment: THolidayPayment;
  theToken: string;
  lRevolutOrder: TJSObject;
  lOrder: string;
const
  IPaymentService_Order = 'IPaymentService.CreateOrder';
begin

  FProvider := TPaymentProvider.Revolut;
  // MainData.PaymentProvider := TPaymentProvider.Revolut;
  Payment := THolidayPayment.Create;
  Payment.Description := 'REVOLUT';
  Payment.TotalAmount := AmountToCharge;
  Payment.CurrencyCode := 'GBP';

  ClientResponse := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IPaymentService_Order, [Payment]));

  lRevolutOrder := ClientResponse.ResultAsObject;
  lOrder := TJSJSON.stringify(lRevolutOrder);
  MainData.SessionValue['RevolutOrder'] := lOrder;
  theToken := string(lRevolutOrder['token']); // JS.ToString(FOrder['token']);

  OpenRevolutPopup(theToken, MainData.PaymentKeys.Revolut_Mode);
  Payment.Free;
end;

function THolidayPaymentsForm.GetBookingStateStage: TBookingState;
begin
  Result := TPaymentUtils.BookingStateStage(FProvider);
end;

function THolidayPaymentsForm.GetGuestDataset: TDataset;
begin
  Result := GuestSource.DataSet;
end;

function THolidayPaymentsForm.GetPaymentLevel: TPaymentLevel;
begin
  Result := TPaymentUtils.ReadPaymentLevel;
end;

procedure THolidayPaymentsForm.GuestDetailsButtonClick(Sender: TObject);
begin
  OtherInfo.Visible := False;
  HolidayDetails.Visible := False;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-primary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';

  SetPainterSummary(TCustomerType.Secondary);
end;

procedure THolidayPaymentsForm.HolidayDetailsButtonClick(Sender: TObject);
begin
  PainterDetails.Visible := False;
  OtherInfo.Visible := False;
  HolidayDetails.Visible := True;
  HolidayDetailsButton.ElementClassName := 'btn btn-primary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';
end;

procedure THolidayPaymentsForm.OnReadyToProgress(const CanProgress: Boolean);
begin
  MainData.OnReadyToProgess := nil;
  MainData.ProcessingState := TProcessingState.psStandard;
  MainBookingPage.ShowConfirmationPage;
end;

procedure THolidayPaymentsForm.OpenRevolutPopup(const Token, Mode: string);
var
  ModeValue: string;
  Email: string;
begin
  Email := MainData.CustomerEmailAddress;
  ModeValue := Mode.ToLower;
{$IFDEF PAS2JS}
  asm
    PopUp(event, Token, Mode, Email)
  end;
{$ENDIF}
end;

procedure THolidayPaymentsForm.InitForm;
var
  lValue: string;
  lLatestChequeDate: TDateTime;
begin
  inherited;
  FCanLeavePage := True;
  MainData.AfterPaymentProcessed := AfterPaymentProcessed;
  UpdateMainForm(wbsEnabled, wbsHidden, Crumb_Review_Pay);
  CreatePayPalOrder;

  lLatestChequeDate := IncDay(MainData.BookingDatasetDepartureDate.Value, -MainData.BankDetails.ChequeLimit);
  ChequeButton.Visible := lLatestChequeDate >= Date;

  if MainData.HolidayBooking.GuestCount = 1 then
  begin
    GuestDetailsButton.Visible := False;
  end
  else
  begin
    if MainData.HolidayBooking.Painters = 2 then
    begin
      GuestDetailsButton.Caption := 'Painter 2 Details';
      PainterDetailsButton.Caption := 'Painter 1 Details';
    end
    else
      GuestDetailsButton.Caption := 'Non-Painter Details';
  end;

  DestinationLabel.Caption := MainData.HolidayBooking.VenueName;
  HotelLabel.Caption := MainData.HolidayBooking.HotelName;
  HolidayLabel.Caption := MainData.HolidaysDatasetTitle.Value;
  PainterCountLabel.Caption := MainData.HolidayBooking.Painters.ToString;
  RoomTypeLabel.Caption := Room_Type_Description[MainData.HolidayBooking.RoomType];

  if MainData.HolidayBooking.RoomType2 <> TRoomType.None then
  begin
    RoomType2Label.Caption := Room_Type_Description[MainData.HolidayBooking.RoomType2];
    Room2Panel.Visible := True;
  end;

  NPPInfo.Visible := MainData.HolidayBooking.NonPainters > 0;
  NPPCountLabel.Caption := MainData.HolidayBooking.NonPainters.ToString;

  if MainData.BookingDatasetWheredidyouhearaboutus.Value > 0 then
  begin
    if MainData.HearAboutOptions.Locate('HearAboutId', MainData.BookingDatasetWheredidyouhearaboutus.Value, []) then
    begin
      lValue := MainData.HearAboutOptionsDescription.Value;
      HearAboutLabel.HTML.Text := lValue;
    end
    else
      HearAboutLabel.HTML.Text := 'not specified';
  end
  else
    HearAboutLabel.HTML.Text := 'not specified';

  DogsAllowed.Visible := MainData.HotelDataset.Active and (not MainData.HotelDataset.IsEmpty) and
    MainData.HotelDatasetDogsallowed.Value;
  if DogsAllowed.Visible then
    HasDogLabel.Caption := YesNo[MainData.BookingDatasetHasDog.Value];

end;

function THolidayPaymentsForm.IsValid(const HighlightControls: Boolean = True): Boolean;
begin
  Result := inherited IsValid;
end;

procedure THolidayPaymentsForm.LoadBooking;
begin
  inherited;

end;

function THolidayPaymentsForm.NextPage: TBookingPage;
begin
  Result := TBookingPage.bpNoChange;
end;

procedure THolidayPaymentsForm.OtherInfoButtonClick(Sender: TObject);
begin
  HolidayDetails.Visible := False;
  PainterDetails.Visible := False;
  OtherInfo.Visible := True;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-secondary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-primary';

end;

procedure THolidayPaymentsForm.PainterDetailsButtonClick(Sender: TObject);
begin
  OtherInfo.Visible := False;
  HolidayDetails.Visible := False;
  HolidayDetailsButton.ElementClassName := 'btn btn-secondary';
  PainterDetailsButton.ElementClassName := 'btn btn-primary';
  GuestDetailsButton.ElementClassName := 'btn btn-secondary';
  OtherInfoButton.ElementClassName := 'btn btn-secondary';

  SetPainterSummary(TCustomerType.Primary);
end;

procedure THolidayPaymentsForm.PayPalButtonPaymentCancelled(Sender: TObject);
begin
  FProvider := TPaymentProvider.PayPal;
  TPaymentUtils.PaymentFailed(TPaymentProvider.PayPal, 0.00, TPaymentState.Cancelled, 'Cancelled', '', PaymentLevel);
end;

procedure THolidayPaymentsForm.PayPalButtonPaymentDone(Sender: TObject; Args: TPayPalPaymentEventArgs);
var lCurrency: string;
    lTotal: String;
begin
  FProvider := TPaymentProvider.PayPal;
  lCurrency := Args.Currency;
  lTotal := Args.Total;

  TPaymentUtils.PaymentSuccess(TPaymentProvider.PayPal, Args.Total.ToDouble, Args.PaymentID, Args.PaymentState, PaymentLevel);
  MainData.ProcessPayment(TPaymentProvider.PayPal, True);
  MainData.DeletePayPalStorage;
end;

procedure THolidayPaymentsForm.PayPalButtonPaymentError(Sender: TObject; Args: TPayPalErrorEventArgs);
begin
  FProvider := TPaymentProvider.PayPal;
  TPaymentUtils.PaymentFailed(TPaymentProvider.PayPal, 0.00, TPaymentState.Failed, Args.ErrorName,
    Args.ErrorDetails.Text, PaymentLevel);
end;

function THolidayPaymentsForm.PreviousPage: TBookingPage;
begin
  if FCanLeavePage then
    Result := TBookingPage.bpOptionalExtras
  else
    Result := TBookingPage.bpNoChange;
end;

procedure THolidayPaymentsForm.RevolutSuccessCallback;
begin
  RevolutSuccess;
end;

procedure THolidayPaymentsForm.RevolutSuccess;
var
  lOrder: TJSObject;
begin

  lOrder := TJSJSON.parseObject(MainData.SessionValue['RevolutOrder']);

  TPaymentUtils.PaymentSuccess(TPaymentProvider.Revolut, Double(lOrder['amount']), string(lOrder['id']), 'Revolut: Success', PaymentLevel);
  MainData.SessionValue['RevolutOrder'] := '';
  MainData.ProcessPayment(TPaymentProvider.Revolut, True);
end;

[JSInvokable]
procedure THolidayPaymentsForm.RevolutErrorCallback(aMessage: string);
begin
  RevolutError(aMessage);
end;

procedure THolidayPaymentsForm.RevolutError(aMessage: string);
var
  lOrder: TJSObject;
begin

  lOrder := TJSJSON.parseObject(MainData.SessionValue['RevolutOrder']);
  TPaymentUtils.PaymentFailed(TPaymentProvider.Revolut, Double(lOrder['amount']), TPaymentState.Failed,
    string(lOrder['id']), 'Revolut: Fail', PaymentLevel);
  MainData.SessionValue['RevolutOrder'] := '';
end;

procedure THolidayPaymentsForm.SaveBooking;
begin
  inherited;
end;

procedure THolidayPaymentsForm.SetDisplayingAs(const Value: TBookingPage);
begin
  inherited;

end;

procedure THolidayPaymentsForm.SetPainterSummary(const CustomerType: TCustomerType);
var
  lDataset: TDataset;
  lIsPainter: Boolean;
  lValue: string;
begin

  if FLoadedCustomer = CustomerType then
  begin
    PainterDetails.Visible := True;
    Exit;
  end;

  FLoadedCustomer := CustomerType;

  case CustomerType of
    TCustomerType.Primary:
    begin
      lDataset := MainData.CustomerDataset;
      GuestSource.DataSet := MainData.GuestDataset;
    end;
    TCustomerType.Secondary:
    begin
      lDataset := MainData.Customer2;
      GuestSource.DataSet := MainData.GuestDataset2;
    end;
  end;

  lIsPainter := (CustomerType = TCustomerType.Primary) or (MainData.HolidayBooking.Painters = 2);

  FirstNameLabel.Caption := lDataset.FieldByName('FirstName').AsString;
  SurnameLabel.Caption := lDataset.FieldByName('LastName').AsString;

  if (CustomerType = TCustomerType.Secondary) and MainData.Customer2AddressSameAsParent.Value then
  begin
    AddressLabel.HTML.Text := 'Same';
    HomePhonePanel.Visible := False;
    TravelPanel.Visible := False;
  end
  else
  begin
    AddressLabel.HTML.Text := THolidayUtils.FormatAddress(lDataset);
    PhoneLabel.Caption := lDataset.FieldByName('HomeTelephoneNumber').AsString;
    TransportLabel.Caption := Transport_Type_Idx[GuestDS.FieldByName('TransportId').AsInteger];
    HomePhonePanel.Visible := True;
    TravelPanel.Visible := True;
  end;

  if lDataset.FieldByName('Email').AsString = '' then
    EmailLabel.Caption := 'Not specified'
  else
    EmailLabel.Caption := lDataset.FieldByName('Email').AsString;

  if lDataset.FieldByName('MobileNumber').AsString = '' then
    MobileLabel.Caption := 'Not specified'
  else
    MobileLabel.Caption := lDataset.FieldByName('MobileNumber').AsString;

  if GuestDS.FieldByName('Emergencycontactname').AsString <> '' then
    EmergencyContactNameLabel.Caption := GuestDS.FieldByName('Emergencycontactname').AsString
  else
    EmergencyContactNameLabel.Caption := 'none specified';

  if GuestDS.FieldByName('Emergencycontactrelation').AsString <> '' then
    EmergencyRelationLabel.Caption := GuestDS.FieldByName('Emergencycontactrelation').AsString
  else
    EmergencyRelationLabel.Caption := 'not specified';

  if GuestDS.FieldByName('Emergencycontacttelephone').AsString <> '' then
    EmergencyTelephoneLabel.Caption := GuestDS.FieldByName('Emergencycontacttelephone').AsString
  else
    EmergencyTelephoneLabel.Caption := 'not specified';

  if GuestDS.FieldByName('Dietaryrequirements').AsString <> '' then
    DietaryReqsLabel.Caption := GuestDS.FieldByName('Dietaryrequirements').AsString
  else
    DietaryReqsLabel.Caption := 'none specified';

  if GuestDS.FieldByName('Mobility').AsString <> '' then
    MobilityLabel.Caption := GuestDS.FieldByName('Mobility').AsString
  else
    MobilityLabel.Caption := 'none specified';

  if GuestDS.FieldByName('Otherneeds').AsString <> '' then
    OtherNeedsLabel.Caption := GuestDS.FieldByName('Otherneeds').AsString
  else
    OtherNeedsLabel.Caption := 'none specified';

  if lDataset.FieldByName('FullyVaccinated').AsInteger = 1 then
  begin
    VaccinatedLabel.Caption := 'Yes';
    VaccinatedDate.Visible := True;
    VaccinatedDateLabel.Caption := FormatDateTime('dd, mmm yyyy', lDataset.FieldByName('DateOfLastVaccination')
      .AsDateTime);
  end
  else
  begin
    VaccinatedLabel.Caption := 'No';
    VaccinatedDate.Visible := False;
  end;

  PainterPanel.Visible := lIsPainter;
  if PainterPanel.Visible then
  begin
    ChairPanel.Visible := (MainData.HolidaysDatasetHolidayType.Value = 'Location') and
      (not MainData.SelectedVenue.IsAbroad);
    if ChairPanel.Visible then
      FoldingChairLabel.Caption := Yes_No[GuestDS.FieldByName('Foldingchairrequired').AsBoolean];

    UKOnlyPanel.Visible := not MainData.SelectedVenue.IsAbroad;
    if UKOnlyPanel.Visible then
    begin
      EaselRequiredLabel.Caption := Yes_No[GuestDS.FieldByName('Easelrequired').AsBoolean];
      BoardRequiredLabel.Caption := Yes_No[GuestDS.FieldByName('Boardrequired').AsBoolean];
    end;

    lValue := GuestDS.FieldByName('Media1').AsString;

    Media1Label.Caption := lValue;
    if (GuestDS.FieldByName('Media2').AsString <> '') then
    begin
      Media2Label.Caption := GuestDS.FieldByName('Media2').AsString;
      Media2Panel.Visible := True;
    end
    else
      Media2Panel.Visible := False;

  end;

  PainterDetails.Visible := True;

end;

procedure THolidayPaymentsForm.SetPaymentLevel(const Value: TPaymentLevel);
begin
  TPaymentUtils.WritePaymentLevel(Value);
end;

procedure THolidayPaymentsForm.TearDown;
begin
  inherited;
  MainData.AfterPaymentProcessed := nil;
end;

procedure THolidayPaymentsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PayPalButton := TPayPal.Create('PayPalButton');
  CreditCardButton := TButton.Create('CreditCardButton');
  BACSButton := TButton.Create('BACSButton');
  ChequeButton := TButton.Create('ChequeButton');
  AgreeReadTerms := TCheckBox.Create('AgreeReadTerms');
  HolidayDetailsButton := TButton.Create('HolidayDetailsButton');
  PainterDetailsButton := TButton.Create('PainterDetailsButton');
  OtherInfoButton := TButton.Create('Other Information');
  HolidayDetails := TPanel.Create('HolidayDetails');
  DestinationLabel := TLabel.Create('DestinationLabel');
  HotelLabel := TLabel.Create('HotelLabel');
  HolidayLabel := TLabel.Create('HolidayLabel');
  PainterCountLabel := TLabel.Create('PainterCountLabel');
  RoomTypeLabel := TLabel.Create('RoomTypeLabel');
  NPPInfo := TPanel.Create('NPPInfo');
  NPPCountLabel := TLabel.Create('NPPCountLabel');
  Room2Panel := TPanel.Create('Room2Panel');
  RoomType2Label := TLabel.Create('RoomType2Label');
  PainterDetails := TPanel.Create('PainterDetails');
  FirstNameLabel := TLabel.Create('FirstNameLabel');
  SurnameLabel := TLabel.Create('SurnameLabel');
  EmailLabel := TLabel.Create('EmailLabel');
  VaccinatedLabel := TLabel.Create('VaccinatedLabel');
  EmergencyContactNameLabel := TLabel.Create('EmergencyContactNameLabel');
  EmergencyRelationLabel := TLabel.Create('EmergencyRelationLabel');
  EmergencyTelephoneLabel := TLabel.Create('EmergencyTelephoneLabel');
  DietaryReqsLabel := TLabel.Create('DietaryReqsLabel');
  MobilityLabel := TLabel.Create('MobilityLabel');
  OtherNeedsLabel := TLabel.Create('OtherNeedsLabel');
  MobileLabel := TLabel.Create('MobileLabel');
  VaccinatedDate := TPanel.Create('VaccinatedDate');
  VaccinatedDateLabel := TLabel.Create('VaccinatedDateLabel');
  PainterPanel := TPanel.Create('PainterPanel');
  Media1Label := TLabel.Create('Media1Label');
  ChairPanel := TPanel.Create('ChairPanel');
  FoldingChairLabel := TLabel.Create('FoldingChairLabel');
  UKOnlyPanel := TPanel.Create('UKOnlyPanel');
  EaselRequiredLabel := TLabel.Create('EaselRequiredLabel');
  BoardRequiredLabel := TLabel.Create('BoardRequiredLabel');
  Media2Panel := TPanel.Create('Media2Panel');
  Media2Label := TLabel.Create('Media2Label');
  AddressLabel := THTMLSpan.Create('AddressLabel');
  HomePhonePanel := TPanel.Create('HomePhonePanel');
  PhoneLabel := TLabel.Create('PhoneLabel');
  TravelPanel := TPanel.Create('TravelPanel');
  TransportLabel := TLabel.Create('TransportLabel');
  OtherTravelLabel := TDBLabel.Create('OtherTravelLabel');
  OtherInfo := TPanel.Create('OtherInfo');
  ExtraNightsBeforeLabel := TDBLabel.Create('ExtraNightsBeforeLabel');
  ExtraNightsAfterLabel := TDBLabel.Create('ExtraNightsAfterLabel');
  RoomUpgradeLabel := TDBLabel.Create('RoomUpgradeLabel');
  DogsAllowed := TPanel.Create('DogsAllowed');
  HasDogLabel := TLabel.Create('HasDogLabel');
  HearAboutLabel := THTMLDiv.Create('HearAboutLabel');
  GuestDetailsButton := TButton.Create('GuestDetailsButton');
  BookingSource := TDataSource.Create(Self);
  GuestSource := TDataSource.Create(Self);

  PayPalButton.BeforeLoadDFMValues;
  CreditCardButton.BeforeLoadDFMValues;
  BACSButton.BeforeLoadDFMValues;
  ChequeButton.BeforeLoadDFMValues;
  PriceBreakDown.BeforeLoadDFMValues;
  AgreeReadTerms.BeforeLoadDFMValues;
  HolidayDetailsButton.BeforeLoadDFMValues;
  PainterDetailsButton.BeforeLoadDFMValues;
  OtherInfoButton.BeforeLoadDFMValues;
  HolidayDetails.BeforeLoadDFMValues;
  DestinationLabel.BeforeLoadDFMValues;
  HotelLabel.BeforeLoadDFMValues;
  HolidayLabel.BeforeLoadDFMValues;
  PainterCountLabel.BeforeLoadDFMValues;
  RoomTypeLabel.BeforeLoadDFMValues;
  NPPInfo.BeforeLoadDFMValues;
  NPPCountLabel.BeforeLoadDFMValues;
  Room2Panel.BeforeLoadDFMValues;
  RoomType2Label.BeforeLoadDFMValues;
  PainterDetails.BeforeLoadDFMValues;
  FirstNameLabel.BeforeLoadDFMValues;
  SurnameLabel.BeforeLoadDFMValues;
  EmailLabel.BeforeLoadDFMValues;
  VaccinatedLabel.BeforeLoadDFMValues;
  EmergencyContactNameLabel.BeforeLoadDFMValues;
  EmergencyRelationLabel.BeforeLoadDFMValues;
  EmergencyTelephoneLabel.BeforeLoadDFMValues;
  DietaryReqsLabel.BeforeLoadDFMValues;
  MobilityLabel.BeforeLoadDFMValues;
  OtherNeedsLabel.BeforeLoadDFMValues;
  MobileLabel.BeforeLoadDFMValues;
  VaccinatedDate.BeforeLoadDFMValues;
  VaccinatedDateLabel.BeforeLoadDFMValues;
  PainterPanel.BeforeLoadDFMValues;
  Media1Label.BeforeLoadDFMValues;
  ChairPanel.BeforeLoadDFMValues;
  FoldingChairLabel.BeforeLoadDFMValues;
  UKOnlyPanel.BeforeLoadDFMValues;
  EaselRequiredLabel.BeforeLoadDFMValues;
  BoardRequiredLabel.BeforeLoadDFMValues;
  Media2Panel.BeforeLoadDFMValues;
  Media2Label.BeforeLoadDFMValues;
  AddressLabel.BeforeLoadDFMValues;
  HomePhonePanel.BeforeLoadDFMValues;
  PhoneLabel.BeforeLoadDFMValues;
  TravelPanel.BeforeLoadDFMValues;
  TransportLabel.BeforeLoadDFMValues;
  OtherTravelLabel.BeforeLoadDFMValues;
  OtherInfo.BeforeLoadDFMValues;
  ExtraNightsBeforeLabel.BeforeLoadDFMValues;
  ExtraNightsAfterLabel.BeforeLoadDFMValues;
  RoomUpgradeLabel.BeforeLoadDFMValues;
  DogsAllowed.BeforeLoadDFMValues;
  HasDogLabel.BeforeLoadDFMValues;
  HearAboutLabel.BeforeLoadDFMValues;
  GuestDetailsButton.BeforeLoadDFMValues;
  BookingSource.BeforeLoadDFMValues;
  GuestSource.BeforeLoadDFMValues;
  try
    Width := 1001;
    Height := 891;
    Caption := 'Review and Pay';
    Color := clBtnFace;
    PayPalButton.SetParentComponent(Self);
    PayPalButton.Name := 'PayPalButton';
    PayPalButton.Left := 64;
    PayPalButton.Top := 656;
    PayPalButton.Width := 209;
    PayPalButton.Height := 49;
    PayPalButton.Enabled := False;
    SetEvent(PayPalButton, Self, 'OnPaymentDone', 'PayPalButtonPaymentDone');
    SetEvent(PayPalButton, Self, 'OnPaymentCancelled', 'PayPalButtonPaymentCancelled');
    SetEvent(PayPalButton, Self, 'OnPaymentError', 'PayPalButtonPaymentError');
    CreditCardButton.SetParentComponent(Self);
    CreditCardButton.Name := 'CreditCardButton';
    CreditCardButton.Left := 279;
    CreditCardButton.Top := 656;
    CreditCardButton.Width := 209;
    CreditCardButton.Height := 48;
    CreditCardButton.Caption := 'Card';
    CreditCardButton.ChildOrder := 1;
    CreditCardButton.ElementClassName := 'btn btn-primary';
    CreditCardButton.ElementFont := efCSS;
    CreditCardButton.Enabled := False;
    CreditCardButton.HeightStyle := ssAuto;
    CreditCardButton.HeightPercent := 100.000000000000000000;
    CreditCardButton.WidthPercent := 100.000000000000000000;
    SetEvent(CreditCardButton, Self, 'OnClick', 'CreditCardButtonClick');
    BACSButton.SetParentComponent(Self);
    BACSButton.Name := 'BACSButton';
    BACSButton.Left := 64;
    BACSButton.Top := 711;
    BACSButton.Width := 209;
    BACSButton.Height := 48;
    BACSButton.Caption := 'BACS/Bank Transfer';
    BACSButton.ChildOrder := 1;
    BACSButton.ElementClassName := 'btn btn-primary';
    BACSButton.ElementFont := efCSS;
    BACSButton.Enabled := False;
    BACSButton.HeightStyle := ssAuto;
    BACSButton.HeightPercent := 100.000000000000000000;
    BACSButton.WidthPercent := 100.000000000000000000;
    SetEvent(BACSButton, Self, 'OnClick', 'BACSButtonClick');
    ChequeButton.SetParentComponent(Self);
    ChequeButton.Name := 'ChequeButton';
    ChequeButton.Left := 279;
    ChequeButton.Top := 710;
    ChequeButton.Width := 209;
    ChequeButton.Height := 48;
    ChequeButton.Caption := 'Cheque';
    ChequeButton.ChildOrder := 1;
    ChequeButton.ElementClassName := 'btn btn-primary';
    ChequeButton.ElementFont := efCSS;
    ChequeButton.Enabled := False;
    ChequeButton.HeightStyle := ssAuto;
    ChequeButton.HeightPercent := 100.000000000000000000;
    ChequeButton.WidthPercent := 100.000000000000000000;
    SetEvent(ChequeButton, Self, 'OnClick', 'ChequeButtonClick');
    PriceBreakDown.Left := 40;
    PriceBreakDown.Top := 72;
    AgreeReadTerms.SetParentComponent(Self);
    AgreeReadTerms.Name := 'AgreeReadTerms';
    AgreeReadTerms.Left := 72;
    AgreeReadTerms.Top := 628;
    AgreeReadTerms.Width := 113;
    AgreeReadTerms.Height := 22;
    AgreeReadTerms.ChildOrder := 6;
    AgreeReadTerms.ElementClassName := 'form-check-input';
    AgreeReadTerms.ElementFont := efCSS;
    AgreeReadTerms.ElementPosition := epIgnore;
    AgreeReadTerms.HeightStyle := ssAuto;
    AgreeReadTerms.HeightPercent := 100.000000000000000000;
    AgreeReadTerms.WidthStyle := ssAuto;
    AgreeReadTerms.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeReadTerms, Self, 'OnClick', 'AgreeReadTermsClick');
    HolidayDetailsButton.SetParentComponent(Self);
    HolidayDetailsButton.Name := 'HolidayDetailsButton';
    HolidayDetailsButton.Left := 34;
    HolidayDetailsButton.Top := 248;
    HolidayDetailsButton.Width := 96;
    HolidayDetailsButton.Height := 25;
    HolidayDetailsButton.Caption := 'Holiday Details';
    HolidayDetailsButton.ChildOrder := 7;
    HolidayDetailsButton.ElementClassName := 'btn btn-primary';
    HolidayDetailsButton.ElementFont := efCSS;
    HolidayDetailsButton.ElementPosition := epIgnore;
    HolidayDetailsButton.HeightStyle := ssAuto;
    HolidayDetailsButton.HeightPercent := 100.000000000000000000;
    HolidayDetailsButton.WidthStyle := ssAuto;
    HolidayDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(HolidayDetailsButton, Self, 'OnClick', 'HolidayDetailsButtonClick');
    PainterDetailsButton.SetParentComponent(Self);
    PainterDetailsButton.Name := 'PainterDetailsButton';
    PainterDetailsButton.Left := 136;
    PainterDetailsButton.Top := 248;
    PainterDetailsButton.Width := 96;
    PainterDetailsButton.Height := 25;
    PainterDetailsButton.Caption := 'Painter Details';
    PainterDetailsButton.ChildOrder := 7;
    PainterDetailsButton.ElementClassName := 'btn btn-secondary';
    PainterDetailsButton.ElementFont := efCSS;
    PainterDetailsButton.ElementPosition := epIgnore;
    PainterDetailsButton.HeightStyle := ssAuto;
    PainterDetailsButton.HeightPercent := 100.000000000000000000;
    PainterDetailsButton.WidthStyle := ssAuto;
    PainterDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(PainterDetailsButton, Self, 'OnClick', 'PainterDetailsButtonClick');
    OtherInfoButton.SetParentComponent(Self);
    OtherInfoButton.Name := 'OtherInfoButton';
    OtherInfoButton.Left := 336;
    OtherInfoButton.Top := 248;
    OtherInfoButton.Width := 121;
    OtherInfoButton.Height := 25;
    OtherInfoButton.Caption := 'Other Information';
    OtherInfoButton.ChildOrder := 7;
    OtherInfoButton.ElementClassName := 'btn btn-secondary';
    OtherInfoButton.ElementFont := efCSS;
    OtherInfoButton.ElementPosition := epIgnore;
    OtherInfoButton.HeightStyle := ssAuto;
    OtherInfoButton.HeightPercent := 100.000000000000000000;
    OtherInfoButton.WidthStyle := ssAuto;
    OtherInfoButton.WidthPercent := 100.000000000000000000;
    SetEvent(OtherInfoButton, Self, 'OnClick', 'OtherInfoButtonClick');
    HolidayDetails.SetParentComponent(Self);
    HolidayDetails.Name := 'HolidayDetails';
    HolidayDetails.Left := 40;
    HolidayDetails.Top := 320;
    HolidayDetails.Width := 233;
    HolidayDetails.Height := 302;
    HolidayDetails.ElementClassName := 'card';
    HolidayDetails.HeightStyle := ssAuto;
    HolidayDetails.WidthStyle := ssAuto;
    HolidayDetails.BorderColor := clNone;
    HolidayDetails.BorderStyle := bsNone;
    HolidayDetails.ChildOrder := 10;
    HolidayDetails.Color := clWindow;
    HolidayDetails.ElementBodyClassName := 'card-body';
    HolidayDetails.ElementFont := efCSS;
    HolidayDetails.ElementPosition := epIgnore;
    HolidayDetails.TabOrder := 9;
    DestinationLabel.SetParentComponent(HolidayDetails);
    DestinationLabel.Name := 'DestinationLabel';
    DestinationLabel.Left := 56;
    DestinationLabel.Top := 32;
    DestinationLabel.Width := 104;
    DestinationLabel.Height := 18;
    DestinationLabel.Caption := 'DestinationLabel';
    DestinationLabel.ElementFont := efCSS;
    DestinationLabel.ElementPosition := epIgnore;
    DestinationLabel.HeightStyle := ssAuto;
    DestinationLabel.HeightPercent := 100.000000000000000000;
    DestinationLabel.WidthStyle := ssAuto;
    DestinationLabel.WidthPercent := 100.000000000000000000;
    HotelLabel.SetParentComponent(HolidayDetails);
    HotelLabel.Name := 'HotelLabel';
    HotelLabel.Left := 57;
    HotelLabel.Top := 64;
    HotelLabel.Width := 66;
    HotelLabel.Height := 18;
    HotelLabel.Caption := 'HotelLabel';
    HotelLabel.ElementFont := efCSS;
    HotelLabel.ElementPosition := epIgnore;
    HotelLabel.HeightStyle := ssAuto;
    HotelLabel.HeightPercent := 100.000000000000000000;
    HotelLabel.WidthStyle := ssAuto;
    HotelLabel.WidthPercent := 100.000000000000000000;
    HolidayLabel.SetParentComponent(HolidayDetails);
    HolidayLabel.Name := 'HolidayLabel';
    HolidayLabel.Left := 57;
    HolidayLabel.Top := 104;
    HolidayLabel.Width := 79;
    HolidayLabel.Height := 18;
    HolidayLabel.Caption := 'HolidayLabel';
    HolidayLabel.ElementFont := efCSS;
    HolidayLabel.ElementPosition := epIgnore;
    HolidayLabel.HeightStyle := ssAuto;
    HolidayLabel.HeightPercent := 100.000000000000000000;
    HolidayLabel.WidthStyle := ssAuto;
    HolidayLabel.WidthPercent := 100.000000000000000000;
    PainterCountLabel.SetParentComponent(HolidayDetails);
    PainterCountLabel.Name := 'PainterCountLabel';
    PainterCountLabel.Left := 57;
    PainterCountLabel.Top := 144;
    PainterCountLabel.Width := 115;
    PainterCountLabel.Height := 18;
    PainterCountLabel.Caption := 'PainterCountLabel';
    PainterCountLabel.ElementFont := efCSS;
    PainterCountLabel.ElementPosition := epIgnore;
    PainterCountLabel.HeightStyle := ssAuto;
    PainterCountLabel.HeightPercent := 100.000000000000000000;
    PainterCountLabel.WidthStyle := ssAuto;
    PainterCountLabel.WidthPercent := 100.000000000000000000;
    RoomTypeLabel.SetParentComponent(HolidayDetails);
    RoomTypeLabel.Name := 'RoomTypeLabel';
    RoomTypeLabel.Left := 57;
    RoomTypeLabel.Top := 216;
    RoomTypeLabel.Width := 105;
    RoomTypeLabel.Height := 18;
    RoomTypeLabel.Caption := 'RoomTypeLabel';
    RoomTypeLabel.ElementFont := efCSS;
    RoomTypeLabel.ElementPosition := epIgnore;
    RoomTypeLabel.HeightStyle := ssAuto;
    RoomTypeLabel.HeightPercent := 100.000000000000000000;
    RoomTypeLabel.WidthStyle := ssAuto;
    RoomTypeLabel.WidthPercent := 100.000000000000000000;
    NPPInfo.SetParentComponent(HolidayDetails);
    NPPInfo.Name := 'NPPInfo';
    NPPInfo.Left := 57;
    NPPInfo.Top := 177;
    NPPInfo.Width := 129;
    NPPInfo.Height := 33;
    NPPInfo.ElementClassName := 'card';
    NPPInfo.HeightStyle := ssAuto;
    NPPInfo.WidthStyle := ssAuto;
    NPPInfo.BorderColor := clNone;
    NPPInfo.BorderStyle := bsNone;
    NPPInfo.ChildOrder := 10;
    NPPInfo.Color := clWindow;
    NPPInfo.ElementBodyClassName := 'card-body';
    NPPInfo.ElementFont := efCSS;
    NPPInfo.ElementPosition := epIgnore;
    NPPInfo.TabOrder := 0;
    NPPInfo.Visible := False;
    NPPCountLabel.SetParentComponent(NPPInfo);
    NPPCountLabel.Name := 'NPPCountLabel';
    NPPCountLabel.Left := 25;
    NPPCountLabel.Top := 3;
    NPPCountLabel.Width := 8;
    NPPCountLabel.Height := 18;
    NPPCountLabel.Caption := '0';
    NPPCountLabel.ElementFont := efCSS;
    NPPCountLabel.ElementPosition := epIgnore;
    NPPCountLabel.HeightStyle := ssAuto;
    NPPCountLabel.HeightPercent := 100.000000000000000000;
    NPPCountLabel.WidthStyle := ssAuto;
    NPPCountLabel.WidthPercent := 100.000000000000000000;
    Room2Panel.SetParentComponent(HolidayDetails);
    Room2Panel.Name := 'Room2Panel';
    Room2Panel.Left := 56;
    Room2Panel.Top := 256;
    Room2Panel.Width := 129;
    Room2Panel.Height := 33;
    Room2Panel.ElementClassName := 'card';
    Room2Panel.HeightStyle := ssAuto;
    Room2Panel.WidthStyle := ssAuto;
    Room2Panel.BorderColor := clNone;
    Room2Panel.BorderStyle := bsNone;
    Room2Panel.ChildOrder := 10;
    Room2Panel.Color := clWindow;
    Room2Panel.ElementBodyClassName := 'card-body';
    Room2Panel.ElementFont := efCSS;
    Room2Panel.ElementPosition := epIgnore;
    Room2Panel.TabOrder := 1;
    Room2Panel.Visible := False;
    RoomType2Label.SetParentComponent(Room2Panel);
    RoomType2Label.Name := 'RoomType2Label';
    RoomType2Label.Left := 3;
    RoomType2Label.Top := 3;
    RoomType2Label.Width := 105;
    RoomType2Label.Height := 18;
    RoomType2Label.Caption := 'RoomTypeLabel';
    RoomType2Label.ElementFont := efCSS;
    RoomType2Label.ElementPosition := epIgnore;
    RoomType2Label.HeightStyle := ssAuto;
    RoomType2Label.HeightPercent := 100.000000000000000000;
    RoomType2Label.WidthStyle := ssAuto;
    RoomType2Label.WidthPercent := 100.000000000000000000;
    PainterDetails.SetParentComponent(Self);
    PainterDetails.Name := 'PainterDetails';
    PainterDetails.Left := 456;
    PainterDetails.Top := 25;
    PainterDetails.Width := 385;
    PainterDetails.Height := 505;
    PainterDetails.ElementClassName := 'card';
    PainterDetails.HeightStyle := ssAuto;
    PainterDetails.WidthStyle := ssAuto;
    PainterDetails.BorderColor := clNone;
    PainterDetails.BorderStyle := bsNone;
    PainterDetails.ChildOrder := 10;
    PainterDetails.Color := clWindow;
    PainterDetails.ElementBodyClassName := 'card-body';
    PainterDetails.ElementFont := efCSS;
    PainterDetails.ElementPosition := epIgnore;
    PainterDetails.TabOrder := 10;
    PainterDetails.Visible := False;
    FirstNameLabel.SetParentComponent(PainterDetails);
    FirstNameLabel.Name := 'FirstNameLabel';
    FirstNameLabel.Left := 64;
    FirstNameLabel.Top := 40;
    FirstNameLabel.Width := 99;
    FirstNameLabel.Height := 18;
    FirstNameLabel.Caption := 'FirstNameLabel';
    FirstNameLabel.ElementFont := efCSS;
    FirstNameLabel.ElementPosition := epIgnore;
    FirstNameLabel.HeightStyle := ssAuto;
    FirstNameLabel.HeightPercent := 100.000000000000000000;
    FirstNameLabel.WidthStyle := ssAuto;
    FirstNameLabel.WidthPercent := 100.000000000000000000;
    SurnameLabel.SetParentComponent(PainterDetails);
    SurnameLabel.Name := 'SurnameLabel';
    SurnameLabel.Left := 65;
    SurnameLabel.Top := 64;
    SurnameLabel.Width := 91;
    SurnameLabel.Height := 18;
    SurnameLabel.Caption := 'SurnameLabel';
    SurnameLabel.ElementFont := efCSS;
    SurnameLabel.ElementPosition := epIgnore;
    SurnameLabel.HeightStyle := ssAuto;
    SurnameLabel.HeightPercent := 100.000000000000000000;
    SurnameLabel.WidthStyle := ssAuto;
    SurnameLabel.WidthPercent := 100.000000000000000000;
    EmailLabel.SetParentComponent(PainterDetails);
    EmailLabel.Name := 'EmailLabel';
    EmailLabel.Left := 65;
    EmailLabel.Top := 148;
    EmailLabel.Width := 66;
    EmailLabel.Height := 18;
    EmailLabel.Caption := 'EmailLabel';
    EmailLabel.ElementFont := efCSS;
    EmailLabel.ElementPosition := epIgnore;
    EmailLabel.HeightStyle := ssAuto;
    EmailLabel.HeightPercent := 100.000000000000000000;
    EmailLabel.WidthStyle := ssAuto;
    EmailLabel.WidthPercent := 100.000000000000000000;
    VaccinatedLabel.SetParentComponent(PainterDetails);
    VaccinatedLabel.Name := 'VaccinatedLabel';
    VaccinatedLabel.Left := 57;
    VaccinatedLabel.Top := 172;
    VaccinatedLabel.Width := 103;
    VaccinatedLabel.Height := 18;
    VaccinatedLabel.Caption := 'VaccinatedLabel';
    VaccinatedLabel.ElementFont := efCSS;
    VaccinatedLabel.ElementPosition := epIgnore;
    VaccinatedLabel.HeightStyle := ssAuto;
    VaccinatedLabel.HeightPercent := 100.000000000000000000;
    VaccinatedLabel.WidthStyle := ssAuto;
    VaccinatedLabel.WidthPercent := 100.000000000000000000;
    EmergencyContactNameLabel.SetParentComponent(PainterDetails);
    EmergencyContactNameLabel.Name := 'EmergencyContactNameLabel';
    EmergencyContactNameLabel.Left := 48;
    EmergencyContactNameLabel.Top := 271;
    EmergencyContactNameLabel.Width := 195;
    EmergencyContactNameLabel.Height := 18;
    EmergencyContactNameLabel.Caption := 'EmergencyContactNameLabel';
    EmergencyContactNameLabel.ElementFont := efCSS;
    EmergencyContactNameLabel.ElementPosition := epIgnore;
    EmergencyContactNameLabel.HeightStyle := ssAuto;
    EmergencyContactNameLabel.HeightPercent := 100.000000000000000000;
    EmergencyContactNameLabel.WidthStyle := ssAuto;
    EmergencyContactNameLabel.WidthPercent := 100.000000000000000000;
    EmergencyRelationLabel.SetParentComponent(PainterDetails);
    EmergencyRelationLabel.Name := 'EmergencyRelationLabel';
    EmergencyRelationLabel.Left := 48;
    EmergencyRelationLabel.Top := 303;
    EmergencyRelationLabel.Width := 156;
    EmergencyRelationLabel.Height := 18;
    EmergencyRelationLabel.Caption := 'EmergencyRelationLabel';
    EmergencyRelationLabel.ElementFont := efCSS;
    EmergencyRelationLabel.ElementPosition := epIgnore;
    EmergencyRelationLabel.HeightStyle := ssAuto;
    EmergencyRelationLabel.HeightPercent := 100.000000000000000000;
    EmergencyRelationLabel.WidthStyle := ssAuto;
    EmergencyRelationLabel.WidthPercent := 100.000000000000000000;
    EmergencyTelephoneLabel.SetParentComponent(PainterDetails);
    EmergencyTelephoneLabel.Name := 'EmergencyTelephoneLabel';
    EmergencyTelephoneLabel.Left := 48;
    EmergencyTelephoneLabel.Top := 335;
    EmergencyTelephoneLabel.Width := 174;
    EmergencyTelephoneLabel.Height := 18;
    EmergencyTelephoneLabel.Caption := 'EmergencyTelephoneLabel';
    EmergencyTelephoneLabel.ElementFont := efCSS;
    EmergencyTelephoneLabel.ElementPosition := epIgnore;
    EmergencyTelephoneLabel.HeightStyle := ssAuto;
    EmergencyTelephoneLabel.HeightPercent := 100.000000000000000000;
    EmergencyTelephoneLabel.WidthStyle := ssAuto;
    EmergencyTelephoneLabel.WidthPercent := 100.000000000000000000;
    DietaryReqsLabel.SetParentComponent(PainterDetails);
    DietaryReqsLabel.Name := 'DietaryReqsLabel';
    DietaryReqsLabel.Left := 48;
    DietaryReqsLabel.Top := 359;
    DietaryReqsLabel.Width := 111;
    DietaryReqsLabel.Height := 18;
    DietaryReqsLabel.Caption := 'DietaryReqsLabel';
    DietaryReqsLabel.ElementFont := efCSS;
    DietaryReqsLabel.ElementPosition := epIgnore;
    DietaryReqsLabel.HeightStyle := ssAuto;
    DietaryReqsLabel.HeightPercent := 100.000000000000000000;
    DietaryReqsLabel.WidthStyle := ssAuto;
    DietaryReqsLabel.WidthPercent := 100.000000000000000000;
    MobilityLabel.SetParentComponent(PainterDetails);
    MobilityLabel.Name := 'MobilityLabel';
    MobilityLabel.Left := 48;
    MobilityLabel.Top := 383;
    MobilityLabel.Width := 80;
    MobilityLabel.Height := 18;
    MobilityLabel.Caption := 'MobilityLabel';
    MobilityLabel.ElementFont := efCSS;
    MobilityLabel.ElementPosition := epIgnore;
    MobilityLabel.HeightStyle := ssAuto;
    MobilityLabel.HeightPercent := 100.000000000000000000;
    MobilityLabel.WidthStyle := ssAuto;
    MobilityLabel.WidthPercent := 100.000000000000000000;
    OtherNeedsLabel.SetParentComponent(PainterDetails);
    OtherNeedsLabel.Name := 'OtherNeedsLabel';
    OtherNeedsLabel.Left := 48;
    OtherNeedsLabel.Top := 407;
    OtherNeedsLabel.Width := 111;
    OtherNeedsLabel.Height := 18;
    OtherNeedsLabel.Caption := 'OtherNeedsLabel';
    OtherNeedsLabel.ElementFont := efCSS;
    OtherNeedsLabel.ElementPosition := epIgnore;
    OtherNeedsLabel.HeightStyle := ssAuto;
    OtherNeedsLabel.HeightPercent := 100.000000000000000000;
    OtherNeedsLabel.WidthStyle := ssAuto;
    OtherNeedsLabel.WidthPercent := 100.000000000000000000;
    MobileLabel.SetParentComponent(PainterDetails);
    MobileLabel.Name := 'MobileLabel';
    MobileLabel.Left := 64;
    MobileLabel.Top := 192;
    MobileLabel.Width := 73;
    MobileLabel.Height := 18;
    MobileLabel.Caption := 'MobileLabel';
    MobileLabel.ElementFont := efCSS;
    MobileLabel.HeightStyle := ssAuto;
    MobileLabel.HeightPercent := 100.000000000000000000;
    MobileLabel.WidthPercent := 100.000000000000000000;
    VaccinatedDate.SetParentComponent(PainterDetails);
    VaccinatedDate.Name := 'VaccinatedDate';
    VaccinatedDate.Left := 228;
    VaccinatedDate.Top := 295;
    VaccinatedDate.Width := 129;
    VaccinatedDate.Height := 56;
    VaccinatedDate.ElementClassName := 'card';
    VaccinatedDate.HeightStyle := ssAuto;
    VaccinatedDate.WidthStyle := ssAuto;
    VaccinatedDate.BorderColor := clNone;
    VaccinatedDate.BorderStyle := bsNone;
    VaccinatedDate.ChildOrder := 10;
    VaccinatedDate.Color := clWindow;
    VaccinatedDate.ElementBodyClassName := 'card-body';
    VaccinatedDate.ElementFont := efCSS;
    VaccinatedDate.ElementPosition := epIgnore;
    VaccinatedDate.TabOrder := 0;
    VaccinatedDate.Visible := False;
    VaccinatedDateLabel.SetParentComponent(VaccinatedDate);
    VaccinatedDateLabel.Name := 'VaccinatedDateLabel';
    VaccinatedDateLabel.Left := 3;
    VaccinatedDateLabel.Top := 22;
    VaccinatedDateLabel.Width := 134;
    VaccinatedDateLabel.Height := 18;
    VaccinatedDateLabel.Caption := 'VaccinatedDateLabel';
    VaccinatedDateLabel.ElementFont := efCSS;
    VaccinatedDateLabel.ElementPosition := epIgnore;
    VaccinatedDateLabel.HeightStyle := ssAuto;
    VaccinatedDateLabel.HeightPercent := 100.000000000000000000;
    VaccinatedDateLabel.WidthStyle := ssAuto;
    VaccinatedDateLabel.WidthPercent := 100.000000000000000000;
    PainterPanel.SetParentComponent(PainterDetails);
    PainterPanel.Name := 'PainterPanel';
    PainterPanel.Left := 208;
    PainterPanel.Top := 11;
    PainterPanel.Width := 161;
    PainterPanel.Height := 237;
    PainterPanel.ElementClassName := 'card';
    PainterPanel.HeightStyle := ssAuto;
    PainterPanel.WidthStyle := ssAuto;
    PainterPanel.BorderColor := clNone;
    PainterPanel.BorderStyle := bsNone;
    PainterPanel.ChildOrder := 10;
    PainterPanel.Color := clWindow;
    PainterPanel.ElementBodyClassName := 'card-body';
    PainterPanel.ElementFont := efCSS;
    PainterPanel.ElementPosition := epIgnore;
    PainterPanel.TabOrder := 1;
    PainterPanel.Visible := False;
    Media1Label.SetParentComponent(PainterPanel);
    Media1Label.Name := 'Media1Label';
    Media1Label.Left := 3;
    Media1Label.Top := 142;
    Media1Label.Width := 79;
    Media1Label.Height := 18;
    Media1Label.Caption := 'Media1Label';
    Media1Label.ElementFont := efCSS;
    Media1Label.ElementPosition := epIgnore;
    Media1Label.HeightStyle := ssAuto;
    Media1Label.HeightPercent := 100.000000000000000000;
    Media1Label.WidthStyle := ssAuto;
    Media1Label.WidthPercent := 100.000000000000000000;
    ChairPanel.SetParentComponent(PainterPanel);
    ChairPanel.Name := 'ChairPanel';
    ChairPanel.Left := 3;
    ChairPanel.Top := 67;
    ChairPanel.Width := 129;
    ChairPanel.Height := 58;
    ChairPanel.ElementClassName := 'card';
    ChairPanel.HeightStyle := ssAuto;
    ChairPanel.WidthStyle := ssAuto;
    ChairPanel.BorderColor := clNone;
    ChairPanel.BorderStyle := bsNone;
    ChairPanel.Color := clWindow;
    ChairPanel.ElementBodyClassName := 'card-body';
    ChairPanel.ElementFont := efCSS;
    ChairPanel.ElementPosition := epIgnore;
    ChairPanel.TabOrder := 0;
    ChairPanel.Visible := False;
    FoldingChairLabel.SetParentComponent(ChairPanel);
    FoldingChairLabel.Name := 'FoldingChairLabel';
    FoldingChairLabel.Left := 3;
    FoldingChairLabel.Top := 22;
    FoldingChairLabel.Width := 109;
    FoldingChairLabel.Height := 18;
    FoldingChairLabel.Caption := 'FoldingChairLabel';
    FoldingChairLabel.ElementFont := efCSS;
    FoldingChairLabel.ElementPosition := epIgnore;
    FoldingChairLabel.HeightStyle := ssAuto;
    FoldingChairLabel.HeightPercent := 100.000000000000000000;
    FoldingChairLabel.WidthStyle := ssAuto;
    FoldingChairLabel.WidthPercent := 100.000000000000000000;
    UKOnlyPanel.SetParentComponent(PainterPanel);
    UKOnlyPanel.Name := 'UKOnlyPanel';
    UKOnlyPanel.Left := 3;
    UKOnlyPanel.Top := 11;
    UKOnlyPanel.Width := 143;
    UKOnlyPanel.Height := 58;
    UKOnlyPanel.ElementClassName := 'card';
    UKOnlyPanel.HeightStyle := ssAuto;
    UKOnlyPanel.WidthStyle := ssAuto;
    UKOnlyPanel.BorderColor := clNone;
    UKOnlyPanel.BorderStyle := bsNone;
    UKOnlyPanel.ChildOrder := 1;
    UKOnlyPanel.Color := clWindow;
    UKOnlyPanel.ElementBodyClassName := 'card-body';
    UKOnlyPanel.ElementFont := efCSS;
    UKOnlyPanel.ElementPosition := epIgnore;
    UKOnlyPanel.TabOrder := 1;
    UKOnlyPanel.Visible := False;
    EaselRequiredLabel.SetParentComponent(UKOnlyPanel);
    EaselRequiredLabel.Name := 'EaselRequiredLabel';
    EaselRequiredLabel.Left := 3;
    EaselRequiredLabel.Top := 14;
    EaselRequiredLabel.Width := 122;
    EaselRequiredLabel.Height := 18;
    EaselRequiredLabel.Caption := 'EaselRequiredLabel';
    EaselRequiredLabel.ElementFont := efCSS;
    EaselRequiredLabel.ElementPosition := epIgnore;
    EaselRequiredLabel.HeightStyle := ssAuto;
    EaselRequiredLabel.HeightPercent := 100.000000000000000000;
    EaselRequiredLabel.WidthStyle := ssAuto;
    EaselRequiredLabel.WidthPercent := 100.000000000000000000;
    BoardRequiredLabel.SetParentComponent(UKOnlyPanel);
    BoardRequiredLabel.Name := 'BoardRequiredLabel';
    BoardRequiredLabel.Left := 3;
    BoardRequiredLabel.Top := 38;
    BoardRequiredLabel.Width := 127;
    BoardRequiredLabel.Height := 18;
    BoardRequiredLabel.Caption := 'BoardRequiredLabel';
    BoardRequiredLabel.ElementFont := efCSS;
    BoardRequiredLabel.ElementPosition := epIgnore;
    BoardRequiredLabel.HeightStyle := ssAuto;
    BoardRequiredLabel.HeightPercent := 100.000000000000000000;
    BoardRequiredLabel.WidthStyle := ssAuto;
    BoardRequiredLabel.WidthPercent := 100.000000000000000000;
    Media2Panel.SetParentComponent(PainterPanel);
    Media2Panel.Name := 'Media2Panel';
    Media2Panel.Left := 3;
    Media2Panel.Top := 166;
    Media2Panel.Width := 129;
    Media2Panel.Height := 58;
    Media2Panel.ElementClassName := 'card';
    Media2Panel.HeightStyle := ssAuto;
    Media2Panel.WidthStyle := ssAuto;
    Media2Panel.BorderColor := clNone;
    Media2Panel.BorderStyle := bsNone;
    Media2Panel.ChildOrder := 3;
    Media2Panel.Color := clWindow;
    Media2Panel.ElementBodyClassName := 'card-body';
    Media2Panel.ElementFont := efCSS;
    Media2Panel.ElementPosition := epIgnore;
    Media2Panel.TabOrder := 2;
    Media2Panel.Visible := False;
    Media2Label.SetParentComponent(Media2Panel);
    Media2Label.Name := 'Media2Label';
    Media2Label.Left := 3;
    Media2Label.Top := 22;
    Media2Label.Width := 79;
    Media2Label.Height := 18;
    Media2Label.Caption := 'Media2Label';
    Media2Label.ElementFont := efCSS;
    Media2Label.ElementPosition := epIgnore;
    Media2Label.HeightStyle := ssAuto;
    Media2Label.HeightPercent := 100.000000000000000000;
    Media2Label.WidthStyle := ssAuto;
    Media2Label.WidthPercent := 100.000000000000000000;
    AddressLabel.SetParentComponent(PainterDetails);
    AddressLabel.Name := 'AddressLabel';
    AddressLabel.Left := 56;
    AddressLabel.Top := 88;
    AddressLabel.Width := 100;
    AddressLabel.Height := 30;
    AddressLabel.ElementClassName := 'PostalAddress';
    AddressLabel.HeightStyle := ssAuto;
    AddressLabel.WidthStyle := ssAuto;
    AddressLabel.ChildOrder := 13;
    AddressLabel.ElementPosition := epIgnore;
    AddressLabel.ElementFont := efCSS;
    AddressLabel.Role := '';
    HomePhonePanel.SetParentComponent(PainterDetails);
    HomePhonePanel.Name := 'HomePhonePanel';
    HomePhonePanel.Left := 220;
    HomePhonePanel.Top := 369;
    HomePhonePanel.Width := 129;
    HomePhonePanel.Height := 56;
    HomePhonePanel.ElementClassName := 'card';
    HomePhonePanel.HeightStyle := ssAuto;
    HomePhonePanel.WidthStyle := ssAuto;
    HomePhonePanel.BorderColor := clNone;
    HomePhonePanel.BorderStyle := bsNone;
    HomePhonePanel.ChildOrder := 10;
    HomePhonePanel.Color := clWindow;
    HomePhonePanel.ElementFont := efCSS;
    HomePhonePanel.ElementPosition := epIgnore;
    HomePhonePanel.TabOrder := 3;
    HomePhonePanel.Visible := False;
    PhoneLabel.SetParentComponent(HomePhonePanel);
    PhoneLabel.Name := 'PhoneLabel';
    PhoneLabel.Left := 32;
    PhoneLabel.Top := 22;
    PhoneLabel.Width := 73;
    PhoneLabel.Height := 18;
    PhoneLabel.Caption := 'PhoneLabel';
    PhoneLabel.ElementFont := efCSS;
    PhoneLabel.ElementPosition := epIgnore;
    PhoneLabel.HeightStyle := ssAuto;
    PhoneLabel.HeightPercent := 100.000000000000000000;
    PhoneLabel.WidthStyle := ssAuto;
    PhoneLabel.WidthPercent := 100.000000000000000000;
    TravelPanel.SetParentComponent(PainterDetails);
    TravelPanel.Name := 'TravelPanel';
    TravelPanel.Left := 220;
    TravelPanel.Top := 431;
    TravelPanel.Width := 129;
    TravelPanel.Height := 56;
    TravelPanel.ElementClassName := 'card';
    TravelPanel.HeightStyle := ssAuto;
    TravelPanel.WidthStyle := ssAuto;
    TravelPanel.BorderColor := clNone;
    TravelPanel.BorderStyle := bsNone;
    TravelPanel.ChildOrder := 10;
    TravelPanel.Color := clWindow;
    TravelPanel.ElementFont := efCSS;
    TravelPanel.ElementPosition := epIgnore;
    TravelPanel.TabOrder := 4;
    TravelPanel.Visible := False;
    TransportLabel.SetParentComponent(TravelPanel);
    TransportLabel.Name := 'TransportLabel';
    TransportLabel.Left := 3;
    TransportLabel.Top := 3;
    TransportLabel.Width := 97;
    TransportLabel.Height := 18;
    TransportLabel.Caption := 'TransportLabel';
    TransportLabel.ElementFont := efCSS;
    TransportLabel.ElementPosition := epIgnore;
    TransportLabel.HeightStyle := ssAuto;
    TransportLabel.HeightPercent := 100.000000000000000000;
    TransportLabel.WidthStyle := ssAuto;
    TransportLabel.WidthPercent := 100.000000000000000000;
    OtherTravelLabel.SetParentComponent(TravelPanel);
    OtherTravelLabel.Name := 'OtherTravelLabel';
    OtherTravelLabel.Left := 3;
    OtherTravelLabel.Top := 35;
    OtherTravelLabel.Width := 111;
    OtherTravelLabel.Height := 18;
    OtherTravelLabel.Caption := 'OtherTravelLabel';
    OtherTravelLabel.ElementFont := efCSS;
    OtherTravelLabel.ElementPosition := epIgnore;
    OtherTravelLabel.HeightStyle := ssAuto;
    OtherTravelLabel.HeightPercent := 100.000000000000000000;
    OtherTravelLabel.WidthStyle := ssAuto;
    OtherTravelLabel.WidthPercent := 100.000000000000000000;
    OtherTravelLabel.DataField := 'OtherTravelRequirements';
    OtherTravelLabel.DataSource := GuestSource;
    OtherInfo.SetParentComponent(Self);
    OtherInfo.Name := 'OtherInfo';
    OtherInfo.Left := 504;
    OtherInfo.Top := 536;
    OtherInfo.Width := 321;
    OtherInfo.Height := 232;
    OtherInfo.ElementClassName := 'card';
    OtherInfo.HeightStyle := ssAuto;
    OtherInfo.WidthStyle := ssAuto;
    OtherInfo.BorderColor := clNone;
    OtherInfo.BorderStyle := bsNone;
    OtherInfo.ChildOrder := 10;
    OtherInfo.Color := clWindow;
    OtherInfo.ElementBodyClassName := 'card-body';
    OtherInfo.ElementFont := efCSS;
    OtherInfo.ElementPosition := epIgnore;
    OtherInfo.TabOrder := 11;
    OtherInfo.Visible := False;
    ExtraNightsBeforeLabel.SetParentComponent(OtherInfo);
    ExtraNightsBeforeLabel.Name := 'ExtraNightsBeforeLabel';
    ExtraNightsBeforeLabel.Left := 16;
    ExtraNightsBeforeLabel.Top := 23;
    ExtraNightsBeforeLabel.Width := 150;
    ExtraNightsBeforeLabel.Height := 18;
    ExtraNightsBeforeLabel.Caption := 'ExtraNightsBeforeLabel';
    ExtraNightsBeforeLabel.ElementFont := efCSS;
    ExtraNightsBeforeLabel.ElementPosition := epIgnore;
    ExtraNightsBeforeLabel.HeightStyle := ssAuto;
    ExtraNightsBeforeLabel.HeightPercent := 100.000000000000000000;
    ExtraNightsBeforeLabel.WidthStyle := ssAuto;
    ExtraNightsBeforeLabel.WidthPercent := 100.000000000000000000;
    ExtraNightsBeforeLabel.DataField := 'FirstRoomExtraNightsBefore';
    ExtraNightsBeforeLabel.DataSource := BookingSource;
    ExtraNightsAfterLabel.SetParentComponent(OtherInfo);
    ExtraNightsAfterLabel.Name := 'ExtraNightsAfterLabel';
    ExtraNightsAfterLabel.Left := 16;
    ExtraNightsAfterLabel.Top := 47;
    ExtraNightsAfterLabel.Width := 150;
    ExtraNightsAfterLabel.Height := 18;
    ExtraNightsAfterLabel.Caption := 'ExtraNightsBeforeLabel';
    ExtraNightsAfterLabel.ElementFont := efCSS;
    ExtraNightsAfterLabel.ElementPosition := epIgnore;
    ExtraNightsAfterLabel.HeightStyle := ssAuto;
    ExtraNightsAfterLabel.HeightPercent := 100.000000000000000000;
    ExtraNightsAfterLabel.WidthStyle := ssAuto;
    ExtraNightsAfterLabel.WidthPercent := 100.000000000000000000;
    ExtraNightsAfterLabel.DataField := 'FirstRoomExtraNightsAfter';
    ExtraNightsAfterLabel.DataSource := BookingSource;
    RoomUpgradeLabel.SetParentComponent(OtherInfo);
    RoomUpgradeLabel.Name := 'RoomUpgradeLabel';
    RoomUpgradeLabel.Left := 16;
    RoomUpgradeLabel.Top := 95;
    RoomUpgradeLabel.Width := 126;
    RoomUpgradeLabel.Height := 18;
    RoomUpgradeLabel.Caption := 'RoomUpgradeLabel';
    RoomUpgradeLabel.ElementFont := efCSS;
    RoomUpgradeLabel.ElementPosition := epIgnore;
    RoomUpgradeLabel.HeightStyle := ssAuto;
    RoomUpgradeLabel.HeightPercent := 100.000000000000000000;
    RoomUpgradeLabel.WidthStyle := ssAuto;
    RoomUpgradeLabel.WidthPercent := 100.000000000000000000;
    RoomUpgradeLabel.DataField := 'FirstRoomUpgrade';
    RoomUpgradeLabel.DataSource := BookingSource;
    DogsAllowed.SetParentComponent(OtherInfo);
    DogsAllowed.Name := 'DogsAllowed';
    DogsAllowed.Left := 12;
    DogsAllowed.Top := 135;
    DogsAllowed.Width := 129;
    DogsAllowed.Height := 35;
    DogsAllowed.ElementClassName := 'card';
    DogsAllowed.HeightStyle := ssAuto;
    DogsAllowed.WidthStyle := ssAuto;
    DogsAllowed.BorderColor := clNone;
    DogsAllowed.BorderStyle := bsNone;
    DogsAllowed.ChildOrder := 3;
    DogsAllowed.Color := clWindow;
    DogsAllowed.ElementBodyClassName := 'card-body';
    DogsAllowed.ElementFont := efCSS;
    DogsAllowed.ElementPosition := epIgnore;
    DogsAllowed.TabOrder := 0;
    DogsAllowed.Visible := False;
    HasDogLabel.SetParentComponent(DogsAllowed);
    HasDogLabel.Name := 'HasDogLabel';
    HasDogLabel.Left := 11;
    HasDogLabel.Top := 3;
    HasDogLabel.Width := 84;
    HasDogLabel.Height := 18;
    HasDogLabel.Caption := 'HasDogLabel';
    HasDogLabel.ElementFont := efCSS;
    HasDogLabel.ElementPosition := epIgnore;
    HasDogLabel.HeightStyle := ssAuto;
    HasDogLabel.HeightPercent := 100.000000000000000000;
    HasDogLabel.WidthStyle := ssAuto;
    HasDogLabel.WidthPercent := 100.000000000000000000;
    HearAboutLabel.SetParentComponent(OtherInfo);
    HearAboutLabel.Name := 'HearAboutLabel';
    HearAboutLabel.Left := 16;
    HearAboutLabel.Top := 176;
    HearAboutLabel.Width := 100;
    HearAboutLabel.Height := 41;
    HearAboutLabel.HeightStyle := ssAuto;
    HearAboutLabel.WidthStyle := ssAuto;
    HearAboutLabel.ChildOrder := 5;
    HearAboutLabel.ElementPosition := epIgnore;
    HearAboutLabel.ElementFont := efCSS;
    HearAboutLabel.Role := '';
    GuestDetailsButton.SetParentComponent(Self);
    GuestDetailsButton.Name := 'GuestDetailsButton';
    GuestDetailsButton.Left := 238;
    GuestDetailsButton.Top := 248;
    GuestDetailsButton.Width := 96;
    GuestDetailsButton.Height := 25;
    GuestDetailsButton.Caption := 'Guest Details';
    GuestDetailsButton.ChildOrder := 7;
    GuestDetailsButton.ElementClassName := 'btn btn-secondary';
    GuestDetailsButton.ElementFont := efCSS;
    GuestDetailsButton.ElementPosition := epIgnore;
    GuestDetailsButton.HeightStyle := ssAuto;
    GuestDetailsButton.HeightPercent := 100.000000000000000000;
    GuestDetailsButton.WidthStyle := ssAuto;
    GuestDetailsButton.WidthPercent := 100.000000000000000000;
    SetEvent(GuestDetailsButton, Self, 'OnClick', 'GuestDetailsButtonClick');
    BookingSource.SetParentComponent(Self);
    BookingSource.Name := 'BookingSource';
    BookingSource.DataSet := MainData.BookingDataset;
    BookingSource.Left := 304;
    BookingSource.Top := 312;
    GuestSource.SetParentComponent(Self);
    GuestSource.Name := 'GuestSource';
    GuestSource.DataSet := MainData.GuestDataset;
    GuestSource.Left := 312;
    GuestSource.Top := 400;
  finally
    PayPalButton.AfterLoadDFMValues;
    CreditCardButton.AfterLoadDFMValues;
    BACSButton.AfterLoadDFMValues;
    ChequeButton.AfterLoadDFMValues;
    PriceBreakDown.AfterLoadDFMValues;
    AgreeReadTerms.AfterLoadDFMValues;
    HolidayDetailsButton.AfterLoadDFMValues;
    PainterDetailsButton.AfterLoadDFMValues;
    OtherInfoButton.AfterLoadDFMValues;
    HolidayDetails.AfterLoadDFMValues;
    DestinationLabel.AfterLoadDFMValues;
    HotelLabel.AfterLoadDFMValues;
    HolidayLabel.AfterLoadDFMValues;
    PainterCountLabel.AfterLoadDFMValues;
    RoomTypeLabel.AfterLoadDFMValues;
    NPPInfo.AfterLoadDFMValues;
    NPPCountLabel.AfterLoadDFMValues;
    Room2Panel.AfterLoadDFMValues;
    RoomType2Label.AfterLoadDFMValues;
    PainterDetails.AfterLoadDFMValues;
    FirstNameLabel.AfterLoadDFMValues;
    SurnameLabel.AfterLoadDFMValues;
    EmailLabel.AfterLoadDFMValues;
    VaccinatedLabel.AfterLoadDFMValues;
    EmergencyContactNameLabel.AfterLoadDFMValues;
    EmergencyRelationLabel.AfterLoadDFMValues;
    EmergencyTelephoneLabel.AfterLoadDFMValues;
    DietaryReqsLabel.AfterLoadDFMValues;
    MobilityLabel.AfterLoadDFMValues;
    OtherNeedsLabel.AfterLoadDFMValues;
    MobileLabel.AfterLoadDFMValues;
    VaccinatedDate.AfterLoadDFMValues;
    VaccinatedDateLabel.AfterLoadDFMValues;
    PainterPanel.AfterLoadDFMValues;
    Media1Label.AfterLoadDFMValues;
    ChairPanel.AfterLoadDFMValues;
    FoldingChairLabel.AfterLoadDFMValues;
    UKOnlyPanel.AfterLoadDFMValues;
    EaselRequiredLabel.AfterLoadDFMValues;
    BoardRequiredLabel.AfterLoadDFMValues;
    Media2Panel.AfterLoadDFMValues;
    Media2Label.AfterLoadDFMValues;
    AddressLabel.AfterLoadDFMValues;
    HomePhonePanel.AfterLoadDFMValues;
    PhoneLabel.AfterLoadDFMValues;
    TravelPanel.AfterLoadDFMValues;
    TransportLabel.AfterLoadDFMValues;
    OtherTravelLabel.AfterLoadDFMValues;
    OtherInfo.AfterLoadDFMValues;
    ExtraNightsBeforeLabel.AfterLoadDFMValues;
    ExtraNightsAfterLabel.AfterLoadDFMValues;
    RoomUpgradeLabel.AfterLoadDFMValues;
    DogsAllowed.AfterLoadDFMValues;
    HasDogLabel.AfterLoadDFMValues;
    HearAboutLabel.AfterLoadDFMValues;
    GuestDetailsButton.AfterLoadDFMValues;
    BookingSource.AfterLoadDFMValues;
    GuestSource.AfterLoadDFMValues;
  end;
end;

end.
