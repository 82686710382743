unit BaseBooking;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Booking.Web.Shared,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.ExtCtrls,
  WebForm.Core,
  Cyrus.Enumerations;

type
  TBaseBookingForm = class(TCoreWebForm)
    HolidaySelectedLabel: TLabel;
    HolidayBookingCosts: THTMLSpan;
    PriceBreakDown: TPanel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FPageLoading: Boolean;
    FOnBookingPageChange: TOnBookingPageChange;
    FDisplayingAs: TBookingPage;
    FNavDirection: TNavDirection;
    function GetPageHash: string;

    { Private declarations }
  protected
    function GetBookingStateStage: TBookingState; virtual;
    procedure SetPageLoading(const Value: Boolean); virtual;

    procedure SetDisplayingAs(const Value: TBookingPage); virtual;
    procedure UpdateMainForm(const ThePreviousButton, TheNextButton: TButtonState; const CrumblineIndex: Integer);

    function IsValid(const HighlightControls: Boolean = True): Boolean; virtual;
    procedure UpdateHolidayDescription;
    procedure UpdateHolidayBookingCosts; virtual;
    function GetAButtonState(const Value: Boolean): TButtonState;
    procedure SaveBooking; virtual;
    procedure TraceLog(const AMessage: string);
  public
    { Public declarations }

    [async]
    procedure InitForm; async; virtual;
    procedure TearDown; override;

    [async]
    function PreviewClose: Boolean; async; virtual;

    function CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress; virtual;

    [async]
    procedure LoadBooking; async; virtual;
    procedure AfterLoaded; virtual;
    procedure SaveTheBooking;

    function CrumblineClicked(const CrumblineIndex: Integer): Boolean; virtual;
    function NextPage: TBookingPage; virtual;
    function PreviousPage: TBookingPage; virtual;
    property OnBookingPageChange: TOnBookingPageChange read FOnBookingPageChange write FOnBookingPageChange;
    property DisplayingAs: TBookingPage read FDisplayingAs write SetDisplayingAs;
    property PageLoading: Boolean read FPageLoading write SetPageLoading;
    property NavDirection: TNavDirection read FNavDirection write FNavDirection;
    property BookingStateStage: TBookingState read GetBookingStateStage;
    property PageHash: string read GetPageHash;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  System.DateUtils,
  System.Rtti,
  MainDataModule,
  SMX.Web.Table.Simple,
  Data.DB,
  SMX.Web.Utils,
  Holiday.Utils;

procedure TBaseBookingForm.WebFormCreate(Sender: TObject);
begin
  FPageLoading := True;
  FNavDirection := TNavDirection.ndNone;
end;

{ TBaseBookingForm }

procedure TBaseBookingForm.AfterLoaded;
begin
  FPageLoading := False;
  HideWaitMessage;
end;

function TBaseBookingForm.CanCloseBookingPage(const ANextPage: Boolean = True): TCanProgress;
begin
  Result := TCanProgress.ccImmediate;
end;

procedure TBaseBookingForm.TraceLog(const AMessage: string);
begin
  MainData.LogSomething(TRttiEnumerationType.GetName<TBookingPage>(FDisplayingAs), 'InPageLog', 0, AMessage, TLOgKInd.Trace);
end;

function TBaseBookingForm.CrumblineClicked(const CrumblineIndex: Integer): Boolean;
begin
  Result := True;
end;

function TBaseBookingForm.GetAButtonState(const Value: Boolean): TButtonState;
begin
  if Value then
    Result := TButtonState.wbsEnabled
  else
    Result := TButtonState.wbsDisabled;
end;

function TBaseBookingForm.GetBookingStateStage: TBookingState;
begin
  Result := TBookingState.Unknown;
end;

function TBaseBookingForm.GetPageHash: string;
begin
  Result := TRttiEnumerationType.GetName<TBookingPage>(FDisplayingAs);
end;

procedure TBaseBookingForm.InitForm;
var lHash: string;
begin
  lHash := GetPageHash;
  if lHash <> Application.Hash then
     window.location.hash := lHash;  //self.ClassName.Substring(1);
  UpdateHolidayDescription;
  UpdateHolidayBookingCosts;
end;

function TBaseBookingForm.IsValid(const HighlightControls: Boolean = True): Boolean;
begin
  Result := True;
end;

procedure TBaseBookingForm.LoadBooking;
begin
  // for descendants
end;

function TBaseBookingForm.NextPage: TBookingPage;
begin
  Result := bpNoChange;
end;

function TBaseBookingForm.PreviewClose: Boolean;
begin
  Result := True;
end;

function TBaseBookingForm.PreviousPage: TBookingPage;
begin
  Result := bpNoChange;
end;

procedure TBaseBookingForm.SaveBooking;
begin
  // for descendants
end;

procedure TBaseBookingForm.SaveTheBooking;
begin
  SaveBooking;
  MainData.SaveBookingDatasets(BookingStateStage);
end;

procedure TBaseBookingForm.SetDisplayingAs(const Value: TBookingPage);
begin
  FDisplayingAs := Value;
end;

procedure TBaseBookingForm.SetPageLoading(const Value: Boolean);
begin
  FPageLoading := Value;
end;

procedure TBaseBookingForm.TearDown;
begin
  // for descendants
end;

procedure TBaseBookingForm.UpdateHolidayBookingCosts;
begin

  PriceBreakDown.Visible := MainData.BookingCosts.Active and (not MainData.BookingCosts.IsEmpty);

  if not PriceBreakDown.Visible then
    Exit;

  HolidayBookingCosts.HTML.Text := THolidayUtils.BookingCostsTable;

end;

procedure TBaseBookingForm.UpdateHolidayDescription;
begin
  HolidaySelectedLabel.Caption := MainData.HolidayDescription;
  HolidaySelectedLabel.Visible := (HolidaySelectedLabel.Caption <> '');
end;

procedure TBaseBookingForm.UpdateMainForm(const ThePreviousButton,
    TheNextButton: TButtonState; const CrumblineIndex: Integer);
begin
  if Assigned(FOnBookingPageChange) then
    FOnBookingPageChange(ThePreviousButton, TheNextButton, CrumblineIndex, False); //MainData.IsFirstRegistration);
end;

procedure TBaseBookingForm.WebFormShow(Sender: TObject);
begin
  PageLoading := False;
end;

procedure TBaseBookingForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HolidaySelectedLabel := TLabel.Create('HolidaySelectedLabel');
  PriceBreakDown := TPanel.Create('PriceBreakDown');
  HolidayBookingCosts := THTMLSpan.Create('HolidayBookingCosts');

  HolidaySelectedLabel.BeforeLoadDFMValues;
  PriceBreakDown.BeforeLoadDFMValues;
  HolidayBookingCosts.BeforeLoadDFMValues;
  try
    Font.Height := -15;
    Font.Name := 'Tahoma';
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    HolidaySelectedLabel.SetParentComponent(Self);
    HolidaySelectedLabel.Name := 'HolidaySelectedLabel';
    HolidaySelectedLabel.Left := 80;
    HolidaySelectedLabel.Top := 48;
    HolidaySelectedLabel.Width := 133;
    HolidaySelectedLabel.Height := 18;
    HolidaySelectedLabel.Caption := 'HolidaySelectedLabel';
    HolidaySelectedLabel.Color := clBtnFace;
    HolidaySelectedLabel.ElementFont := efCSS;
    HolidaySelectedLabel.ElementPosition := epIgnore;
    HolidaySelectedLabel.HeightStyle := ssAuto;
    HolidaySelectedLabel.HeightPercent := 100.000000000000000000;
    HolidaySelectedLabel.WidthStyle := ssAuto;
    HolidaySelectedLabel.WidthPercent := 100.000000000000000000;
    PriceBreakDown.SetParentComponent(Self);
    PriceBreakDown.Name := 'PriceBreakDown';
    PriceBreakDown.Left := 368;
    PriceBreakDown.Top := 184;
    PriceBreakDown.Width := 233;
    PriceBreakDown.Height := 121;
    PriceBreakDown.ElementClassName := 'card';
    PriceBreakDown.HeightStyle := ssAuto;
    PriceBreakDown.WidthStyle := ssAuto;
    PriceBreakDown.BorderStyle := bsNone;
    PriceBreakDown.ChildOrder := 2;
    PriceBreakDown.ElementBodyClassName := 'card-body';
    PriceBreakDown.ElementFont := efCSS;
    PriceBreakDown.ElementPosition := epIgnore;
    PriceBreakDown.TabOrder := 0;
    PriceBreakDown.Visible := False;
    HolidayBookingCosts.SetParentComponent(PriceBreakDown);
    HolidayBookingCosts.Name := 'HolidayBookingCosts';
    HolidayBookingCosts.Left := 32;
    HolidayBookingCosts.Top := 16;
    HolidayBookingCosts.Width := 145;
    HolidayBookingCosts.Height := 89;
    HolidayBookingCosts.HeightStyle := ssAuto;
    HolidayBookingCosts.WidthStyle := ssAuto;
    HolidayBookingCosts.ElementPosition := epIgnore;
    HolidayBookingCosts.ElementFont := efCSS;
    HolidayBookingCosts.Role := '';
  finally
    HolidaySelectedLabel.AfterLoadDFMValues;
    PriceBreakDown.AfterLoadDFMValues;
    HolidayBookingCosts.AfterLoadDFMValues;
  end;
end;

end.
