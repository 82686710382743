Unit MainDataModule;

Interface

Uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  JS,
  Web,
  WEBLib.Modules,
  XData.Web.Client,
  XData.Web.Connection,
  App.Config,
  App.Types,
  Holiday.ReturnTypes,
  DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Cyrus.Enumerations,
  Booking.Web.Shared,
  WEBLib.CDS,
  Sphinx.WebLogin,
  WEBLib.Forms,
  WEBLib.DB;

{
  Debug Authority: http://alpha:2024/AlphaLogin
  Demo Site: https://alpha.smxi-azure-001.smxi.com/AlphaLogin
}

{

  Emails sent on BookingDataset.AfterApplyUpdates for Confirmations (Cheque, BACS) and Payments.AfterApplyUpdates for successful payments.

  Emails also sent in BookingDataset.AfterApplyUpdates for Waitlist

  Holiday and RoomAllocations are Updated when the Holiday is Confirmed (Cheque, BACS), Deposit Paid or Fully Paid.

}

Type

  TMainData = Class(TDataModule)
    DataConnection: TXDataWebConnection;
    WebClient: TXDataWebClient;
    CustomerDataset: TXDataWebDataSet;
    GuestDataset: TXDataWebDataSet;
    GuestDatasetCustomerId: TIntegerField;
    HolidaysDataset: TClientDataSet;
    HolidaysDatasetHolidayId: TIntegerField;
    HolidaysDatasetTitle: TStringField;
    HolidaysDatasetHolidayType: TStringField;
    HolidaysDatasetStartDate: TDateField;
    HolidaysDatasetEndDate: TDateField;
    HolidaysDatasetTutor: TStringField;
    HolidaysDatasetStartDateFormatted: TStringField;
    HolidaysDatasetEndDateFormatted: TStringField;
    BookingDataset: TXDataWebDataSet;
    BookingCosts: TXDataWebDataSet;
    SphinxLogin: TSphinxWebLogin;
    RoomAllocations: TXDataWebDataSet;
    RoomTypes: TXDataWebDataSet;
    RoomTypesRoomtypeid: TIntegerField;
    RoomTypesRoomtypecode: TStringField;
    RoomTypesRoomdescription: TStringField;
    RoomTypesIssingleroom: TBooleanField;
    RoomTypesNonresidential: TBooleanField;
    HotelDataset: TXDataWebDataSet;
    HotelDatasetHotelId: TIntegerField;
    HotelDatasetCode: TStringField;
    HotelDatasetName: TStringField;
    HotelDatasetSingleroomsavailable: TBooleanField;
    GuestDatasetGuestType: TStringField;
    HolidayData: TXDataWebDataSet;
    HolidayDataHolidayid: TIntegerField;
    HolidayDataReference: TStringField;
    HolidayDataTitle: TStringField;
    HolidayDataHolidayType: TStringField;
    HolidayDataStartDate: TDateTimeField;
    HolidayDataEndDate: TDateTimeField;
    HolidayDataMaxNumberOfStudents: TIntegerField;
    HolidayDataVenueId: TIntegerField;
    HolidayDataDepositresidential: TFloatField;
    HolidayDataActive: TBooleanField;
    HolidayDataMediaWaterColours: TBooleanField;
    HolidayDataMediaOil: TBooleanField;
    HolidayDataMediaAcrylic: TBooleanField;
    HolidayDataMediaPastel: TBooleanField;
    HolidayDataTotalAdjPainters: TIntegerField;
    HolidayDataTotalAdjNonPainters: TIntegerField;
    HolidayDataConfirmed: TBooleanField;
    HolidayDataMainHotelId: TIntegerField;
    HolidayDataSecondaryHotelId: TIntegerField;
    HolidayDataTutorId: TIntegerField;
    HolidayDataPainterPrice: TFloatField;
    HolidayDataNPPPrice: TFloatField;
    HolidayDataSingleOccupancy: TFloatField;
    HolidayDataSingleRoom: TFloatField;
    HolidayDataNPPSingleRoom: TFloatField;
    HolidayDataExtraNight: TFloatField;
    HolidayDataExtraNightSingleRoom: TFloatField;
    HolidayDataExtraNightSingleOccupancy: TFloatField;
    HolidayDataNPPSingleOccupancy: TFloatField;
    BookingCostsBookingcostid: TIntegerField;
    BookingCostsItemcode: TStringField;
    BookingCostsDescription: TStringField;
    BookingCostsUnitPrice: TFloatField;
    BookingCostsQuantity: TIntegerField;
    BookingCostsPayerId: TIntegerField;
    BookingCostsItemType: TStringField;
    BookingCostsSubItemType: TStringField;
    BookingCostsTotalCost: TFloatField;
    BookingCostsFormattedDescription: TStringField;
    BookingDatasetBookingId: TIntegerField;
    BookingDatasetNoOfGuests: TIntegerField;
    BookingDatasetNonPaintingPartner: TBooleanField;
    BookingDatasetArrivalDate: TDateTimeField;
    BookingDatasetDepartureDate: TDateTimeField;
    BookingDatasetFirstRoomExtraNightsBefore: TIntegerField;
    BookingDatasetFirstRoomExtraNightsAfter: TIntegerField;
    BookingDatasetFirstRoomUpgrade: TStringField;
    BookingDatasetHasDog: TBooleanField;
    BookingDatasetTransportId: TIntegerField;
    BookingDatasetShareRoom: TBooleanField;
    BookingDatasetNumberOfPainters: TIntegerField;
    BookingDatasetBookingReference: TStringField;
    BookingDatasetDepositDue: TFloatField;
    BookingDatasetTotalHolidayPrice: TFloatField;
    BookingDatasetFinalBalanceDueOn: TDateTimeField;
    BookingDatasetRoomAvailability: TBooleanField;
    BookingDatasetHolidayAvailability: TBooleanField;
    BookingDatasetLogid: TIntegerField;
    BookingDatasetCreatedOn: TDateTimeField;
    BookingDatasetWheredidyouhearaboutus: TIntegerField;
    BookingDatasetOtherTravelRequirements: TStringField;
    BookingDatasetTotPriceAdjustment: TFloatField;
    BookingDatasetTotalPaid: TFloatField;
    BookingDatasetExported: TIntegerField;
    BookingDatasetExportedOn: TStringField;
    BookingDatasetCustomerId: TIntegerField;
    BookingDatasetHolidayId: TIntegerField;
    BookingDatasetFirstRoomTypeId: TIntegerField;
    BookingDatasetSecondRoomTypeId: TIntegerField;
    BookingDatasetGuestId: TIntegerField;
    Payments: TXDataWebDataSet;
    PaymentsProvider: TStringField;
    PaymentsState: TStringField;
    PaymentsPaymentDate: TDateTimeField;
    PaymentsBookingId: TIntegerField;
    PaymentsAmount: TFloatField;
    PaymentsReference: TStringField;
    PaymentsOutcome: TStringField;
    PaymentsCurrencyCode: TStringField;
    DummyLookUp: TClientDataSet;
    DummyLookUpSource: TDataSource;
    HearAboutOptions: TXDataWebDataSet;
    HearAboutOptionsHearAboutId: TIntegerField;
    HearAboutOptionsHearAboutStatus: TStringField;
    HearAboutOptionsDescription: TStringField;
    GuestDatasetMedia1: TStringField;
    GuestDatasetMedia2: TStringField;
    GuestDatasetMedia3: TStringField;
    GuestDatasetMedia4: TStringField;
    GuestDatasetExtraNightsCharge: TFloatField;
    BookingDatasetNonPainterCount: TIntegerField;
    BookingDatasetTotalNumberOfNights: TIntegerField;
    HolidaysDatasetHolidayRef: TStringField;
    GuestDatasetGuestId: TIntegerField;
    GuestDatasetRoomTypeId: TIntegerField;
    GuestDatasetRoomUpgradeDescription: TStringField;
    GuestDatasetIncomplete: TBooleanField;
    GuestDatasetGuestNumber: TIntegerField;
    GuestDatasetEaselRequired: TBooleanField;
    GuestDatasetFoldingChairRequired: TBooleanField;
    GuestDatasetDietaryRequirements: TStringField;
    GuestDatasetMobility: TStringField;
    GuestDatasetOtherNeeds: TStringField;
    GuestDatasetEmergencyContactName: TStringField;
    GuestDatasetEmergencyContactTelephone: TStringField;
    GuestDatasetEmergencyContactRelation: TStringField;
    GuestDatasetOrigAirport: TStringField;
    GuestDatasetPrefAirline: TStringField;
    GuestDatasetBoardRequired: TBooleanField;
    GuestDatasetBookingId: TIntegerField;
    GuestDatasetTotalPersonPrice: TFloatField;
    GuestDatasetDepositDue: TFloatField;
    GuestDatasetSameContactDetails: TBooleanField;
    GuestDatasetOtherTravelRequirements: TStringField;
    GuestDatasetTransportId: TIntegerField;
    HotelDatasetDogCostPeriod: TStringField;
    HotelDatasetDogCost: TFloatField;
    BookingCostsCostPeriod: TStringField;
    PaymentsPaymentType: TStringField;
    RoomAllocationsHolidayroomallocationid: TIntegerField;
    RoomAllocationsMaxAllocation: TIntegerField;
    RoomAllocationsAllocated: TIntegerField;
    RoomAllocationsRoomTypeId: TIntegerField;
    BookingDatasetBalanceDue: TFloatField;
    PaymentsId: TIntegerField;
    HotelDatasetDogsAllowed: TBooleanField;
    HolidaysDatasetHotelId: TIntegerField;
    HolidaysDatasetAvailability: TIntegerField;
    CustomerDatasetCustomerId: TIntegerField;
    CustomerDatasetTitle: TStringField;
    CustomerDatasetFirstName: TStringField;
    CustomerDatasetMiddleName: TStringField;
    CustomerDatasetLastName: TStringField;
    CustomerDatasetMobileNumber: TStringField;
    CustomerDatasetEmail: TStringField;
    CustomerDatasetAddressLine1: TStringField;
    CustomerDatasetAddressLine2: TStringField;
    CustomerDatasetAddressLine3: TStringField;
    CustomerDatasetTown: TStringField;
    CustomerDatasetCounty: TStringField;
    CustomerDatasetPostCode: TStringField;
    CustomerDatasetDetailsComplete: TBooleanField;
    CustomerDatasetFullyVaccinated: TIntegerField;
    CustomerDatasetDateOfLastVaccination: TDateTimeField;
    CustomerDatasetCountryCode: TStringField;
    CustomerDatasetDiscountId: TIntegerField;
    CustomerDatasetUserId: TStringField;
    CustomerDatasetCustomerCode: TStringField;
    CustomerDatasetCustomerType: TStringField;
    CustomerDatasetParentCustomerId: TIntegerField;
    CustomerDatasetAddressSameAsParent: TBooleanField;
    CustomerDatasetContactDetailId: TIntegerField;
    CustomerDatasetDateCreated: TDateTimeField;
    BookingCostsBookingId: TIntegerField;
    HotelDatasetVenueId: TIntegerField;
    BookingDatasetBookingState: TStringField;
    Customer2: TXDataWebDataSet;
    Customer2CustomerId: TIntegerField;
    Customer2Title: TStringField;
    Customer2FirstName: TStringField;
    Customer2MiddleName: TStringField;
    Customer2LastName: TStringField;
    Customer2HomeTelephoneNumber: TStringField;
    Customer2MobileNumber: TStringField;
    Customer2Email: TStringField;
    Customer2AddressLine1: TStringField;
    Customer2AddressLine2: TStringField;
    Customer2AddressLine3: TStringField;
    Customer2Town: TStringField;
    Customer2County: TStringField;
    Customer2PostCode: TStringField;
    Customer2DetailsComplete: TBooleanField;
    Customer2FullyVaccinated: TIntegerField;
    Customer2DateOfLastVaccination: TDateTimeField;
    Customer2CountryCode: TStringField;
    Customer2DiscountId: TIntegerField;
    Customer2UserId: TStringField;
    Customer2CustomerCode: TStringField;
    Customer2CustomerType: TStringField;
    Customer2ParentCustomerId: TIntegerField;
    Customer2AddressSameAsParent: TBooleanField;
    Customer2ContactDetailId: TIntegerField;
    Customer2DateCreated: TDateTimeField;
    CustomerDatasetHomeTelephoneNumber: TStringField;
    HolidaysDatasetConfirmed: TBooleanField;
    HolidaysDatasetMedia: TStringField;
    HolidaysDatasetVenueId: TIntegerField;
    BookingDatasetExtraNights: TIntegerField;
    HolidayDataExtraNightOption: TStringField;
    PaymentsPaymentLevel: TStringField;
    RoomAllocationsHolidayid: TIntegerField;
    CustomerDatasetHearAboutUs: TIntegerField;
    Customer2HearAboutUs: TIntegerField;
    GuestDataset2: TXDataWebDataSet;
    GuestDataset2GuestId: TIntegerField;
    GuestDataset2GuestType: TStringField;
    GuestDataset2RoomTypeId: TIntegerField;
    GuestDataset2RoomUpgradeDescription: TStringField;
    GuestDataset2Incomplete: TBooleanField;
    GuestDataset2GuestNumber: TIntegerField;
    GuestDataset2EaselRequired: TBooleanField;
    GuestDataset2FoldingChairRequired: TBooleanField;
    GuestDataset2Media1: TStringField;
    GuestDataset2Media2: TStringField;
    GuestDataset2Media3: TStringField;
    GuestDataset2Media4: TStringField;
    GuestDataset2DietaryRequirements: TStringField;
    GuestDataset2Mobility: TStringField;
    GuestDataset2OtherNeeds: TStringField;
    GuestDataset2EmergencyContactName: TStringField;
    GuestDataset2EmergencyContactTelephone: TStringField;
    GuestDataset2SameContactDetails: TBooleanField;
    GuestDataset2EmergencyContactRelation: TStringField;
    GuestDataset2OrigAirport: TStringField;
    GuestDataset2PrefAirline: TStringField;
    GuestDataset2BoardRequired: TBooleanField;
    GuestDataset2BookingId: TIntegerField;
    GuestDataset2CustomerId: TIntegerField;
    GuestDataset2TotalPersonPrice: TFloatField;
    GuestDataset2DepositDue: TFloatField;
    GuestDataset2ExtraNightsCharge: TFloatField;
    GuestDataset2OtherTravelRequirements: TStringField;
    GuestDataset2TransportId: TIntegerField;
    Procedure BookingCostsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure BookingCostsAfterOpen(Dataset: TDataSet);
    Procedure BookingCostsCalcFields(Dataset: TDataSet);
    Procedure BookingCostsNewRecord(Dataset: TDataSet);
    Procedure BookingDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure BookingDatasetAfterOpen(Dataset: TDataSet);
    Procedure BookingDatasetCalcFields(Dataset: TDataSet);
    Procedure BookingDatasetNewRecord(Dataset: TDataSet);
    Procedure CustomerDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure CustomerDatasetAfterOpen(Dataset: TDataSet);
    Procedure CustomerDatasetEmailSetText(Sender: TField; Const Text: String);
    Procedure CustomerDatasetPostcodeSetText(Sender: TField; Const Text: String);
    Procedure DataConnectionError(Error: TXDataWebConnectionError);
    Procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    Procedure Customer2AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure Customer2AfterOpen(Dataset: TDataSet);
    Procedure Customer2EmailSetText(Sender: TField; Const Text: String);
    Procedure GuestDataset2AfterOpen(Dataset: TDataSet);
    Procedure Customer2NewRecord(Dataset: TDataSet);
    Procedure Customer2PostCodeSetText(Sender: TField; Const Text: String);
    Procedure GuestDataset2AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure GuestDataset2NewRecord(Dataset: TDataSet);
    Procedure GuestDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure GuestDatasetAfterOpen(Dataset: TDataSet);
    Procedure GuestDatasetNewRecord(Dataset: TDataSet);
    Procedure HolidayDataAfterOpen(Dataset: TDataSet);
    Procedure HolidayDataCalcFields(Dataset: TDataSet);
    Procedure HolidaysDatasetAfterOpen(Dataset: TDataSet);
    Procedure HolidaysDatasetCalcFields(Dataset: TDataSet);
    Procedure HotelDatasetAfterOpen(Dataset: TDataSet);
    Procedure PaymentsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure PaymentsAfterOpen(Dataset: TDataSet);
    Procedure PaymentsNewRecord(Dataset: TDataSet);
    Procedure RoomAllocationsAfterOpen(Dataset: TDataSet);
    [async]
    Procedure SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs); async;
    Procedure WebClientError(Error: TXDataClientError);
    Procedure WebDataModuleDestroy(Sender: TObject);
    [async]
    Procedure WebDataModuleCreate(Sender: TObject); async;
  Private
    { Private declarations }

    FIsLoggingException: Boolean;
    FInitDone: Boolean;
    FProcessingState: TProcessingState;
    FStoredProcessingState: TProcessingState;
    FBookingState: TBookingState;
    FContinuingAfterLogin: Boolean;

    FLoginSource: TLoginSource;
    FMainPage: TMainAppPage;
    FMainPageText: String;
    FPreviousMainPage: TMainAppPage;

    FCustomerId: Integer;
    FGuestId: Integer;
    FCustomerTypeLoading: TCustomerType;
    FCustomer: TBasicPerson;

    FAdditionalGuestData: TAdditionalGuestData;

    FVenueList: TList<THolidayVenue>;
    FSelectedVenue: Integer;

    FHolidayBooking: THolidayBooking;

    FSavingGuestType: TCustomerType;

    FPaymentProvider: TPaymentProvider;
    FBankDetails: TBankDetails;
    FPaymentAPIKeys: TPaymentAPIKeys;

    // CallBacks
    FRoomAllocationsCallBack: TNotifyEvent;
    FCustomerOpenCallBack: TNotifyEvent;
    FOnReadyToProgress: TOnReadyToProgress;
    FAfterPaymentProcessed: TAfterPaymentProcessed;
    FAfterLogout: TNotifyEvent;
    FOnSelectedBookingLoaded: TNotifyEvent;

    FGuestType: TCustomerType;
    FVenues: TList<THolidayVenue>;
    FUserId: String;
    FDebugInfo: String;
    FHotels: TList<THolidayHotel>;
    FErrorMessage: String;
    FMailingAddress: TMailingAddress;
    FSelectedBookingHandled: Boolean;

    FBookingSaved: TBookingData;
    FBookingOpen: TBookingData;
    FHolidayOpen: THolidayData;
    FFirstRegistration: Boolean;
    FLastBookingId: Integer;
    FLastBookingRefTested: String;
    FPaymentOnBooking: Boolean;
    FWaitingListProcessedCallBack: TNotifyEvent;
    FCustomerDetailsUpdated: Boolean;
    FErrorMessageProc: TErrorMessageProc;
    FApplyGuestAccountChange: TNotifyEvent;
    FSessionId: Integer;

    FRequestLink: TDeepLink;

    [async]
    Procedure ProcessQuery; async;

    Function GetLocalValue(Const Key: String): String;
    Procedure SetLocalValue(Const Key, Value: String);

    Procedure OnApplicationError(Sender: TObject; AError: TApplicationError; Var Handled: Boolean);
    Procedure OnApplicationException(Sender: TObject; E: Exception);

    Function LocalValueKeyStartsWith(Const Value: String): String;
    // procedure SetLoginState(const Value: TLoginState);
    Function SphinxRedirectUri: String;
    [async]
    Procedure LoadBankDetails; async;
    [async]
    Procedure LoadPaymentKeys; async;
    [async]
    Procedure LoadMailingAddress; async;
    [async]
    Procedure ConfigInit; async;
    [async]
    Procedure InitModule(Const CalledAfterLogin: Boolean); async;
    [async]
    Procedure LoadConfig; async;
    [async]
    Function SessionId: Integer; async;

    Procedure SaveHolidayBooking;
    Function LoadHolidayBooking: Boolean;
    Function GetHolidayBooking: THolidayBooking;
    Function GetHolidayDescription: String;
    Function GetBookingId: Integer;
    Procedure SetPaymentProvider(Const Value: TPaymentProvider);
    Function GetHotelId: Integer;
    Function GetSelectedVenue: THolidayVenue;
    Procedure CalcExtraNights;
    Procedure CreateBookingPage;
    Procedure CreateMyAccountPage;
    Procedure ShowMyAccountPage;
    Procedure SendPaymentEmail(Const ABookingId, APaymentId: Integer; Const APaymentProvider: String);

    [async]
    Procedure LoadHolidayContinuation; async;
    Procedure AfterAddToWaitList;

    Procedure UpdateTotalBookingPrice;
    Function TotalPaid: Double;

    [async]
    Procedure LoadSelectedBookingAdditionalData; async;

    Procedure UpdateBookingCostsWithId(Const BookingId: Integer);
    Procedure UpdateGuestsWithId(Const BookingId: Integer);

    Function GetDebugInfo: String;
    [async]
    Procedure HandleBookingAfterLogin; async;
    [async]
    Procedure HandleAccountAfterLogin; async;
    [async]
    Procedure GetLastEmergencyInfo; async;
    /// <summary>
    /// This is to help process the reloading of the choice data after a successful login for bookings. Hopefully there
    /// will be a LoadAndAwait at some point so we won't need this messing about
    /// </summary>
    [async]
    Procedure ProgressBookingAfterContinuation; async;
    Procedure CallbackAfterSelectedBookingLoaded;
    Procedure FindHoliday(Const HolidayId: Integer);

    Procedure SetProcessingState(Const Value: TProcessingState);
    Function GetSessionValue(Const Key: String): String;
    Procedure SetSessionValue(Const Key, Value: String);

    Function GetIsFirstRegistration: Boolean;
    Procedure SetMainPage(Const Value: TMainAppPage);
    Function GetIsLoggedIn: Boolean;
    Function GetCustomerId: Integer;
    Procedure SetCustomerId(Const Value: Integer);
    Function GetChoiceComplete: Boolean;
    Procedure SetChoiceComplete(Const Value: Boolean);
    Procedure UnlinkAllCallBacks;
    Function GetPageSwitched: Boolean;

    [async]
    Procedure SaveBookingCosts; async;
    // [async]
    // Procedure SaveGuests;

    [async]
    Procedure SaveAllData; async;
    Procedure SaveAllDataCheck;
    Function GetIsActiveBooking: Boolean;
    Function GetGuestId: Integer;
    Procedure SetGuestId(Const Value: Integer);
    Procedure UpdateBookingFromHoliday;
    Procedure UpdateBookingFromHolidayBasic;
    Function GetCustomerEmailAddress: String;
    Function GetCustomer: TBasicPerson;
    Function GetCustomerName: String;
    Function GetAdditionalGuestData: TAdditionalGuestData;
    Function GetRequestLink: TDeepLink;

    Property MainPage: TMainAppPage Read FMainPage Write SetMainPage;

    Property AdditionalGuestData: TAdditionalGuestData Read GetAdditionalGuestData;
  Public
    { Public declarations }

    Procedure RaiseException(Const AMessage: String);

    Procedure Login(Const ASource: TLoginSource);
    Procedure Logout(Const LogoutMethod: TLogoutMethod);
    Procedure LogPageChange(Const AFromPage: String; Const AToPage: String; Const ADirection: String);

    Function ActivateDataset(ADataset: TDataSet): Boolean;
    Procedure EditDataset(ADataset: TDataSet);

    Procedure LoadSelectedBooking(Const ABookingId: Integer);
    Procedure CloseBookingData(Const RestorePreviousBooking: Boolean;
      Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);

    Procedure ResetHolidayOptions(Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);

    [async]
    Function BookingExists(Const ABookingRef: String): Boolean; async;

    [async]
    Procedure LoadVenues; async;
    Procedure SelectVenue(Const VenueId: Integer);
    [async]
    Procedure LoadHotels(Const VenueId: Integer); async;

    [async]
    Procedure LoadBookableHolidayById(Const HolidayId: Integer); async;
    [async]
    Procedure LoadHolidays(Const HotelId: Integer); async;

    Procedure LoadHotel(Const AHotelId: Integer);
    Procedure LoadHoliday(Const HolidayId: Integer);
    Procedure LoadBookingCosts(Const ABookingId: Integer);
    Procedure LoadPayments(Const ABookingId: Integer);

    Procedure LoadRoomAllocations;

    Function RoomAvailable(Const ARoomType: TRoomType; Const ACount: Integer): Boolean;
    Function RoomsAvailableCount: Integer;
    Function GuestIsPainter(Const ACustomerType: TCustomerType): Boolean;
    [async]
    Function AddToWaitList: Boolean; async;

    Function OpenCustomerDataset: Boolean;
    // Used after editing My Details in MyAccount
    Procedure ReloadCustomer;
    Function OpenGuest2: Boolean;
    Function LoadSelectedGuest(Const AGuestId: Integer): TsmxRecordState;
    Procedure SaveCustomer;
    Procedure SaveCustomer2;
    Procedure CopyCustomerAddress;
    Function FindCustomer: TsmxRecordState;
    Function FindGuest: TsmxRecordState;

    Function OpenBooking: TsmxRecordState;
    Function UpdateBookingData: Boolean;
    Procedure LoadBookingFromHoliday;
    Procedure LoadHolidayFromBooking;
    Procedure StartBooking;

    Procedure SaveBookingDatasets(Const ABookingState: TBookingState);

    Procedure CreateBaseBookingCosts;
    Function TotalHolidayPrice: Double;

    Function FindGuestRecord(ACustomerType: TCustomerType): TsmxRecordState;
    Procedure SaveGuest(ACustomerType: TCustomerType);
    Procedure CopyEmergencyDetailsFromCustomer;
    Procedure FixMissingGuestRecord(ACustomerType: TCustomerType);
    Procedure ApplyGuestAccountUpdate(Const ACustomerType: TCustomerType);

    Function MediaCount: Integer;
    Function MediaSelection: TMediaType;

    Procedure PreArrivalDays(Const Value: Integer);
    Procedure PostDepatureDays(Const Value: Integer);
    Procedure UpdateDogCost(Const HasDog: Boolean);

    [async]
    Function PaymentAPIKey(Const AProvider: TPaymentProvider): String; async;
    Function NeedsFullPayment: Boolean;
    Function BookingNeedsFinalising: Boolean;

    Procedure ProcessPayment(APaymentProvider: TPaymentProvider; Const OnInitialBooking: Boolean);
    Procedure NonPaymentConfirmation(APaymentProvider: TPaymentProvider);

    [async]
    Procedure ContinueBooking(Const ABookingId: Integer); async;
    Procedure DeletePayPalStorage;

    [async]
    Function HolidayStillAvailable(Const AHolidayId: Integer): Boolean; async;

    Procedure SwitchMainPage(Const APage: TMainAppPage);

    Procedure LogSomething(Const AAction, IdType: String; Const IdValue: Integer; Const ALogMessage: String;
      Const ALogKind: TLogKind);

    Property RequestLink: TDeepLink Read GetRequestLink;
    Property DebugInfo: String Read GetDebugInfo;
    Property CustomerDetailsUpdated: Boolean Read FCustomerDetailsUpdated Write FCustomerDetailsUpdated;
    Property CustomerId: Integer Read GetCustomerId Write SetCustomerId;
    Property GuestId: Integer Read GetGuestId Write SetGuestId;
    Property CustomerEmailAddress: String Read GetCustomerEmailAddress;
    Property CustomerName: String Read GetCustomerName;
    Property IsLoggedIn: Boolean Read GetIsLoggedIn;
    Property Customer: TBasicPerson Read GetCustomer;

    Property ProcessingState: TProcessingState Read FProcessingState Write SetProcessingState;
    // property LoginState: TLoginState read FLoginState write SetLoginState;
    Property ChoiceComplete: Boolean Read GetChoiceComplete Write SetChoiceComplete;

    Property Venues: TList<THolidayVenue> Read FVenues;
    Property SelectedVenue: THolidayVenue Read GetSelectedVenue;
    Property Hotels: TList<THolidayHotel> Read FHotels;

    Property BookingId: Integer Read GetBookingId;
    Property HotelId: Integer Read GetHotelId;
    Property HolidayBooking: THolidayBooking Read GetHolidayBooking;

    Property HolidayDescription: String Read GetHolidayDescription;
    Property LastBookingRefTested: String Read FLastBookingRefTested Write FLastBookingRefTested;

    Property PaymentProvider: TPaymentProvider Read FPaymentProvider Write SetPaymentProvider;
    Property PaymentKeys: TPaymentAPIKeys Read FPaymentAPIKeys;
    Property BankDetails: TBankDetails Read FBankDetails;
    Property MailingAddress: TMailingAddress Read FMailingAddress;

    Property RoomAllocationsCallBack: TNotifyEvent Write FRoomAllocationsCallBack;
    Property CustomerOpenCallBack: TNotifyEvent Write FCustomerOpenCallBack;
    Property ApplyGuestAccountChange: TNotifyEvent Write FApplyGuestAccountChange;
    Property OnReadyToProgess: TOnReadyToProgress Write FOnReadyToProgress;
    Property AfterPaymentProcessed: TAfterPaymentProcessed Write FAfterPaymentProcessed;
    Property OnSelectedBookingLoaded: TNotifyEvent Write FOnSelectedBookingLoaded;
    Property WaitingListProcessedCallBack: TNotifyEvent Write FWaitingListProcessedCallBack;
    Property AfterLogout: TNotifyEvent Write FAfterLogout;
    Property ErrorMessageProc: TErrorMessageProc Read FErrorMessageProc Write FErrorMessageProc;

    Property ErrorMessage: String Read FErrorMessage Write FErrorMessage;
    /// <summary>
    /// Returns True if not Logged In or Customer Id is not present in the JWT
    /// </summary>
    Property IsFirstRegistration: Boolean Read GetIsFirstRegistration;
    Property LastBookingId: Integer Read FLastBookingId;
    Property IsActiveBooking: Boolean Read GetIsActiveBooking;

    Property LocalValue[Const Key: String]: String Read GetLocalValue Write SetLocalValue;
    Property SessionValue[Const Key: String]: String Read GetSessionValue Write SetSessionValue;
    Property PageSwitched: Boolean Read GetPageSwitched;
  protected procedure LoadDFMValues; override; End;

Var
  MainData: TMainData;

Implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

Uses
  System.Rtti,
  System.DateUtils,
  System.StrUtils,
  XData.Web.Request,
  XData.Web.Response,
  WEBLib.Storage,
  WEBLib.WebTools,
  Holiday.Utils,
  XData.Dataset.Utils,
  Auth.Service,
  MainBookingForm,
  smx.webcore.Types,
  AccountMainForm,
  smx.Auth.Shared,
  Holiday.Booking.Update,
  smx.Web.Document.Utils;

Const
  Login_Source = 'LoginSource';
  // Login_State = 'LoginState';
  Process_State = 'ProcessState';
  Holiday_Booking = 'HolidayBooking';
  // Used when another booking is being reloaded for account management
  Stored_Holiday_Booking = 'StoredHolidayBooking';
  Paypal_Storage = '__paypal_storage__';
  Last_Section = 'LastSection';
  Customer_Id = 'Customer_Id';

Function TMainData.ActivateDataset(ADataset: TDataSet): Boolean;
Begin
  Result := False;
  If Not ADataset.Active Then
  Begin
    ADataset.Open;
    Result := True;
  End;
End;

Function TMainData.AddToWaitList: Boolean;
Begin
  Result := False;
  ProcessingState := TProcessingState.psWaitList;
  If HolidayBooking.WaitList = TWaitListStep.wlEmailSent Then
    Exit;
  If IsLoggedIn And (Not IsFirstRegistration) Then
  Begin
    UpdateBookingData; // Call Save All Data from HolidayChoice
    HolidayBooking.WaitList := TWaitListStep.wlComplete;
    Result := True;
  End
  Else
  Begin
    HolidayBooking.WaitList := TWaitListStep.wlAdd;
  End;
End;

Procedure TMainData.AfterAddToWaitList;
Const
  waitlist_email_svc = 'IHolidayService.SendWaitlistEmail';
Begin
  If HolidayBooking.WaitList = TWaitListStep.wlEmailSent Then
    Exit;
  WebClient.RawInvokeAsync(waitlist_email_svc, [BookingDatasetBookingId.Value]);
  HolidayBooking.WaitList := TWaitListStep.wlEmailSent;
  FLastBookingId := BookingDatasetBookingId.Value;
  CloseBookingData(False, TWaitListStep.wlEmailSent);
End;

Procedure TMainData.ApplyGuestAccountUpdate(Const ACustomerType: TCustomerType);
Begin
  FStoredProcessingState := ProcessingState;
  ProcessingState := TProcessingState.psAccountUpdate;
  If ACustomerType = TCustomerType.Primary Then
    GuestDataset.ApplyUpdates
  Else If ACustomerType = TCustomerType.Secondary Then
    GuestDataset2.ApplyUpdates;
End;

Procedure TMainData.BookingCostsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Begin
  TXDataDSUtils.UpdateAllIds(BookingCosts, Info, 'BookingCostId', True);

  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Costs := True;
    SaveAllDataCheck;
  End;

  Assert(ProcessingState = TProcessingState.psSavingData, 'should not get to BookingCostsAfterApplyUpdates');

End;

Procedure TMainData.BookingCostsAfterOpen(Dataset: TDataSet);
Var
  lCount: Integer;
Begin
  If FProcessingState = psLoadingPreviousBooking Then
  Begin
    lCount := Dataset.RecordCount;
    FBookingOpen.Costs := True;
    CallbackAfterSelectedBookingLoaded;
  End;
End;

Procedure TMainData.BookingCostsCalcFields(Dataset: TDataSet);
Var
  lDescription, lBreakdown: String;
Begin
  BookingCostsTotalCost.Value := BookingCostsUnitPrice.Value * BookingCostsQuantity.Value;
  lDescription := BookingCostsDescription.Value;

  If MainData.BookingCostsQuantity.Value > 1 Then
    lBreakdown := ' <span class="pricebreakdownsmalltext">' + BookingCostsQuantity.Value.ToString +
      IfThen(BookingCostsCostPeriod.Value = Cost_Period_PerNight, ' nights') + ' x &pound;' +
      FormatFloat('#,##0.00', BookingCostsUnitPrice.Value) + '</span>'
  Else
    lBreakdown := '';

  If lDescription.Contains(' (') Then
    lDescription := lDescription.Replace(' (', lBreakdown + '<br /><span class="pricebreakdownsmalltext">(')
      .Replace(')', ')</span>')
  Else
    lDescription := lDescription + lBreakdown;

  BookingCostsFormattedDescription.Value := lDescription;
End;

Procedure TMainData.BookingCostsNewRecord(Dataset: TDataSet);
Begin
  BookingCostsBookingId.Value := FHolidayBooking.BookingId; // BookingDatasetBookingId.Value;
  BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Standard];;
End;

Procedure TMainData.BookingDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Var
  lResolveId, lBookingId: Integer;
  lMessage: String;
Begin

  lMessage := '';
  If Not TXDataDSUtils.ResolvedAsExpected(Info, 'Booking', lMessage) Then
    Raise Exception.Create(lMessage);

  If (Info.Records[0].Status = TUpdateStatus.usInserted) Then
  Begin
    lResolveId := TXDataDSUtils.UpdateId(BookingDataset, Info, 'BookingId', 0);
    HolidayBooking.BookingId := lResolveId;
    FSavingGuestType := TCustomerType.None;
    UpdateBookingCostsWithId(lResolveId);
    UpdateGuestsWithId(lResolveId);
  End;

  lBookingId := BookingDatasetBookingId.Value;

  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Booking := True;
    SaveAllData;
    // No need to run the check here as this is done in Save All Data
    { TODO : Need to sort out confirmation and payment processing - move to SaveDataCheck? }
  End;

  Assert(ProcessingState = TProcessingState.psSavingData, 'should not get to BookingDatasetAfterApplyUpdates');

End;

Procedure TMainData.BookingDatasetAfterOpen(Dataset: TDataSet);
Begin

  If FProcessingState = psLoadingPreviousBooking Then
  Begin
    FBookingOpen.Booking := True;
    LoadSelectedBookingAdditionalData;
  End;

End;

Procedure TMainData.BookingDatasetCalcFields(Dataset: TDataSet);
Var
  lNights: Integer;
Begin
  If BookingDatasetNonPaintingPartner.Value Then
    BookingDatasetNonPainterCount.Value := 1
  Else
    BookingDatasetNonPainterCount.Value := 0;

  BookingDatasetExtraNights.Value := BookingDatasetFirstRoomExtraNightsBefore.Value +
    BookingDatasetFirstRoomExtraNightsAfter.Value;
  lNights := DaysBetween(BookingDatasetArrivalDate.Value, BookingDatasetDepartureDate.Value);
  BookingDatasetTotalNumberOfNights.Value := lNights;

  BookingDatasetBalanceDue.Value := BookingDatasetTotalHolidayPrice.Value - BookingDatasetTotalPaid.Value;

End;

Procedure TMainData.BookingDatasetNewRecord(Dataset: TDataSet);
Begin
  BookingDatasetBookingState.Value := Booking_State[TBookingState.New];
  BookingDatasetLogid.Value := FSessionId;
End;

Function TMainData.BookingExists(Const ABookingRef: String): Boolean;
Const
  holiday_booking_exists_svc = 'IHolidayService.BookingExists'; // (BookingRef)
Var
  lRetval: TXDataClientResponse;
  lExistingBooking: Integer;
Begin
  FLastBookingRefTested := ABookingRef;
  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_booking_exists_svc, [ABookingRef]));
  lExistingBooking := JS.toInteger(lRetval.ResultAsObject['value']);

  Result := (lExistingBooking > 0) And (lExistingBooking <> HolidayBooking.BookingId);
  If Result Then
    ProcessingState := TProcessingState.psBookingExists;

  If Result Then
    ResetHolidayOptions();
End;

Function TMainData.BookingNeedsFinalising: Boolean;
Begin
  Result := TXDataDSUtils.IsModified(BookingCosts) Or TXDataDSUtils.IsModified(GuestDataset) Or
    TXDataDSUtils.IsModified(BookingDataset);
End;

Procedure TMainData.WebDataModuleDestroy(Sender: TObject);
Begin
  If Assigned(FHolidayBooking) Then
    FHolidayBooking.Free;
  If Assigned(FVenues) Then
    FVenues.Free;
  If Assigned(FHotels) Then
    FHotels.Free;
  If Assigned(FBankDetails) Then
    FBankDetails.Free;
  If Assigned(FPaymentAPIKeys) Then
    FPaymentAPIKeys.Free;
  If Assigned(FMailingAddress) Then
    FMailingAddress.Free;
  If Assigned(FCustomer) Then
    FCustomer.Free;
  If Assigned(FAdditionalGuestData) Then
    FAdditionalGuestData.Free;
  If Assigned(FRequestLink) Then
    FRequestLink.Free;
End;

Procedure TMainData.CalcExtraNights;
Var
  lExtraNights: Integer;
  lDescription: String;
  lPrice: Double;
Begin

  lExtraNights := BookingDatasetFirstRoomExtraNightsBefore.Value + BookingDatasetFirstRoomExtraNightsAfter.Value;
  If HolidayBooking.GuestCount > 1 Then
    lDescription := 'Extra nights for ' + HolidayBooking.GuestCount.ToString + ' guests '
  Else
    lDescription := 'Extra nights ';

  If BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.ExtraNights], []) Then
  Begin
    If lExtraNights = 0 Then
    Begin
      BookingCosts.Delete;
      Exit;
    End
    Else If Not(BookingCosts.State In dsEditModes) Then
      BookingCosts.Edit;
  End
  Else If lExtraNights > 0 Then
  Begin
    BookingCosts.Append;
    BookingCostsItemType.Value := Cost_Item_Type[TCostItemType.ExtraNights];
    BookingCostsCostPeriod.Value := Cost_Period_PerNight;
  End
  Else
    Exit;

  BookingCostsQuantity.Value := lExtraNights; // ;

  If HolidayBooking.RoomType = TRoomType.Single Then
  Begin
    lPrice := HolidayDataExtraNightSingleRoom.Value;
    If lPrice = 0 Then
      lPrice := HolidayDataExtraNight.Value;

    BookingCostsUnitPrice.Value := lPrice * HolidayBooking.GuestCount;
    BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Standard];
    BookingCostsDescription.Value := lDescription + '(Single Room)';
  End
  Else
  Begin
    If (Not HolidayBooking.RoomShare) Or (HolidayBooking.GuestCount = 1) Then
    Begin
      BookingCostsUnitPrice.Value := HolidayDataExtraNightSingleOccupancy.Value * HolidayBooking.GuestCount;
      BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.SingleOccupier];
      If (HolidayBooking.GuestCount = 2) And (HolidayBooking.RoomType <> HolidayBooking.RoomType2) Then
        lDescription := lDescription + '(' + Room_Type_Description[HolidayBooking.RoomType] + '/' +
          Room_Type_Description[HolidayBooking.RoomType2] + ' Rooms - Single Occupancy)'
      Else
        lDescription := lDescription + '(' + Room_Type_Description[HolidayBooking.RoomType] +
          ' Room - Single Occupancy)';

      BookingCostsDescription.Value := lDescription;
    End
    Else
    Begin
      BookingCostsUnitPrice.Value := HolidayDataExtraNight.Value * HolidayBooking.GuestCount;
      BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Shared];
      BookingCostsDescription.Value := lDescription + '(' + Room_Type_Description[HolidayBooking.RoomType] + ' Room)';
    End;
  End;

  BookingCosts.First;
  While Not BookingCosts.Eof Do
  Begin
    If (BookingCostsCostPeriod.Value = Cost_Period_PerNight) And
      (BookingCostsItemType.Value <> Cost_Item_Type[TCostItemType.ExtraNights]) Then
    Begin
      BookingCosts.Edit;
      BookingCostsQuantity.Value := BookingDatasetTotalNumberOfNights.Value;
      BookingCosts.Post;
    End;

    BookingCosts.Next;
  End;

  UpdateTotalBookingPrice;

End;

Procedure TMainData.CallbackAfterSelectedBookingLoaded;
Begin

  If FSelectedBookingHandled Then
    Exit;

  If FBookingOpen.AllDone And FHolidayOpen.AllDone Then
  Begin
    FSelectedBookingHandled := True;
    If Assigned(FOnSelectedBookingLoaded) Then
      FOnSelectedBookingLoaded(Self);
  End;
End;

Procedure TMainData.CloseBookingData(Const RestorePreviousBooking: Boolean;
  Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);
Var
  lValue: String;
Begin
  ChoiceComplete := False;
  TXDataDSUtils.CloseDataset(BookingDataset);
  TXDataDSUtils.CloseDataset(CustomerDataset);
  TXDataDSUtils.CloseDataset(GuestDataset);
  TXDataDSUtils.CloseDataset(Customer2);
  TXDataDSUtils.CloseDataset(GuestDataset2);
  TXDataDSUtils.CloseDataset(BookingCosts);
  TXDataDSUtils.CloseDataset(Payments);

  ResetHolidayOptions;
  ProcessingState := TProcessingState.psStandard;
  FStoredProcessingState := TProcessingState.psStandard;
  HolidayBooking.WaitList := WaitListStep;

  If RestorePreviousBooking Then
  Begin
    lValue := SessionValue[Stored_Holiday_Booking];
    If lValue <> '' Then
    Begin
      HolidayBooking.Load(TJSJSON.parseObject(lValue));
      SessionValue[Holiday_Booking] := '';
    End;
  End;
End;

Procedure TMainData.ConfigInit;
Var
  lParamValue: String;
  lLoginStarted: TDateTime;

Begin
  Await(LoadConfig);

  If SessionValue[Login_Source] = '' Then
  Begin

    If HasQueryParam('SP', lParamValue, False) Then
    Begin
      If lParamValue.ToUpper = 'MYACCOUNT' Then
        FLoginSource := TLoginSource.lsMyAccount
      Else
        FLoginSource := TLoginSource.lsNotSpecified;
    End
    Else
      FLoginSource := TLoginSource.lsNotSpecified;
  End
  Else
    FLoginSource := TRttiEnumerationType.GetValue<TLoginSource>(SessionValue[Login_Source]);

  If SessionValue[Process_State] <> '' Then
  Begin
    ProcessingState := TRttiEnumerationType.GetValue<TProcessingState>(SessionValue[Process_State]);
    SessionValue[Process_State] := '';
  End
  Else
    ProcessingState := TProcessingState.psStandard;

  // if (SessionValue[Login_State] = '') then
  // LoginState := TLoginState.lisNotLoggedIn
  // else
  // LoginState := TRttiEnumerationType.GetValue<TLoginState>(SessionValue[Login_State]);

End;

Procedure TMainData.ContinueBooking(Const ABookingId: Integer);
Begin
  SwitchMainPage(TMainAppPage.mpBooking)
End;

Procedure TMainData.CopyCustomerAddress;
Var
  lAddress: TAddress;
Begin
  lAddress := THolidayUtils.AddressFromDataset(CustomerDataset);
  EditDataset(Customer2);
  THolidayUtils.AddressToDataset(lAddress, Customer2);
End;

Procedure TMainData.CopyEmergencyDetailsFromCustomer;
Begin
  EditDataset(GuestDataset2);
  GuestDataset2EmergencyContactName.Value := FAdditionalGuestData.ContactName;
  GuestDataset2EmergencyContactTelephone.Value := FAdditionalGuestData.Telephone;
  GuestDataset2EmergencyContactRelation.Value := FAdditionalGuestData.Relation;
End;

Procedure TMainData.CreateBaseBookingCosts;
Var
  Qty: Integer;
Begin

  If HolidayBooking.WaitList <> TWaitListStep.wlNoAdd Then
    Exit;

  If Not BookingCosts.Active Then
    BookingCosts.Open;

  // Painter Costs
  THolidayGuestsUpdate.PainterUpdateCost;

  // NPP Costs
  If HolidayBooking.NonPainters = 1 Then
    THolidayGuestsUpdate.NPPUpdateCost;

End;

Procedure TMainData.CreateBookingPage;
Begin
  If Assigned(AccountMainPage) Then
  Begin
    // check to close?
    AccountMainPage.CloseMainPage;
    AccountMainPage.Free;
    AccountMainPage := Nil;
  End;

  MainPage := TMainAppPage.mpBooking;

  TMainBookingPage.DisplayPage('content');
End;

Procedure TMainData.FixMissingGuestRecord(ACustomerType: TCustomerType);
Begin

End;

Procedure TMainData.CreateMyAccountPage;
Begin
  If Assigned(MainBookingPage) Then
  Begin
    MainBookingPage.CloseMainPage;
    MainBookingPage.Free;
    MainBookingPage := Nil;
  End;

  MainPage := TMainAppPage.mpMyAccount;

  TAccountMainPage.DisplayPage('content');
End;

Procedure TMainData.CustomerDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Var
  lResolveId, lSize: Integer;
  lMessage: String;
Begin

  lMessage := '';
  lSize := Length(Info.Records);

  If Not TXDataDSUtils.ResolvedAsExpected(Info, 'Customer', lMessage) Then
    RaiseException(lMessage);

  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Customers := True;
    CustomerDetailsUpdated := True;
    SaveAllDataCheck;
    Exit;
  End
  Else
  Begin
    FSavingGuestType := TCustomerType.None;

    If Assigned(FOnReadyToProgress) Then
      FOnReadyToProgress(True);
  End;
End;

Procedure TMainData.CustomerDatasetAfterOpen(Dataset: TDataSet);
Var
  lModified: Boolean;
  lCount: Integer;
Begin

  lModified := TXDataDSUtils.IsModified(CustomerDataset);
  lCount := CustomerDataset.RecordCount;

  Customer.FirstName := CustomerDatasetFirstName.Value;
  Customer.LastName := CustomerDatasetLastName.Value;

  If ProcessingState = TProcessingState.psReloadingCustomer Then
  Begin
    ProcessingState := FStoredProcessingState;
    FStoredProcessingState := TProcessingState.psStandard;
    Exit;
  End;

  FCustomerTypeLoading := TCustomerType.None;
  CustomerDataset.EnableControls;

  If FProcessingState = psLoadingPreviousBooking Then
  Begin
    FBookingOpen.Customers := True;
    CallbackAfterSelectedBookingLoaded;
    Exit;
  End;

  UpdateBookingData;

  If Assigned(FCustomerOpenCallBack) Then
    FCustomerOpenCallBack(Self);

End;

Procedure TMainData.CustomerDatasetEmailSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToLower;
End;

Procedure TMainData.CustomerDatasetPostcodeSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToUpper;
End;

Procedure TMainData.DataConnectionError(Error: TXDataWebConnectionError);
Var
  lHandled: Boolean;
Begin
  // If Not FIsLoggingException Then
  // Begin
  // lHandled := False;
  // HandleAnException(EGeneralXDataException.Create(Error), lHandled);
  // End;
End;

Procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
Begin
  If SphinxLogin.IsLoggedIn Then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + SphinxLogin.AuthResult.AccessToken);
  If FSessionId > 0 Then
    Args.Request.Headers.SetValue('SessionId', FSessionId.ToString);
  If CustomerId > 0 Then
    Args.Request.Headers.SetValue('pid', CustomerId.ToString);
  Args.Request.Headers.SetValue('UserSection', FMainPageText);
End;

Procedure TMainData.DeletePayPalStorage;
Begin
  LocalValue[Paypal_Storage] := '';
End;

Procedure TMainData.EditDataset(ADataset: TDataSet);
Begin
  If Assigned(ADataset) Then
  Begin
    // if not ADataset.Active then
    // ADataset.Open;
    If Not(ADataset.State In dsEditModes) Then
      ADataset.Edit;
  End;
End;

Procedure TMainData.ProgressBookingAfterContinuation;
Var
  lHolidayData, lHotel, lRooms, lHolidays: Boolean;
  lProcess: String;
Begin

  lHolidayData := HolidayData.Active;
  lHotel := HotelDataset.Active;
  lRooms := RoomAllocations.Active;
  lHolidays := HolidaysDataset.Active;
  lProcess := TRttiEnumerationType.GetName<TProcessingState>(ProcessingState);

  If HolidayData.Active And HotelDataset.Active And RoomAllocations.Active And HolidaysDataset.Active Then
  Begin
    LoadBookingFromHoliday;
    ChoiceComplete := True;
    If FContinuingAfterLogin Then
    Begin
      BookingDatasetBookingState.Value := Booking_State[TBookingState.CustomerIdentified];
      If HolidayBooking.WaitList <> TWaitListStep.wlAdd Then
        FContinuingAfterLogin := False;
    End;

    If HolidayBooking.WaitList = TWaitListStep.wlAdd Then
    Begin
      Await(AddToWaitList);
      SaveBookingDatasets(TBookingState.WaitingList);
      Exit;
    End
    Else
      ProcessingState := TProcessingState.psStandard;

    CreateBookingPage;
  End;
End;

Function TMainData.FindCustomer: TsmxRecordState;
Var
  lCustomerId: Integer;
Begin

  lCustomerId := FCustomerId;

  If CustomerDataset.Active Then
  Begin
    If CustomerDatasetCustomerId.Value <> FCustomerId Then
    Begin
      CustomerDataset.DisableControls;
      TXDataDSUtils.CloseDataset(CustomerDataset);
    End
    Else
      Exit(TsmxRecordState.rsExisting)
  End;

  FCustomerTypeLoading := TCustomerType.Primary;
  CustomerDataset.QueryString := '$filter=(CustomerId eq ' + FCustomerId.ToString + ')';
  CustomerDataset.Load;
  Result := TsmxRecordState.rsLoading;
End;

Function TMainData.FindGuest: TsmxRecordState;
Begin

  Customer2.DisableControls;

  If (Not Customer2.Active) Or Customer2.IsEmpty Then
  Begin

    If (GuestId > 0) Then
    Begin
      FCustomerTypeLoading := TCustomerType.Secondary;
      Customer2.QueryString := '$filter=(CustomerId eq ' + GuestId.ToString + ')';
      Customer2.Load;
      Exit(TsmxRecordState.rsLoading);
    End;

    Begin
      If Not Customer2.Active Then
        Customer2.Open;

      If Customer2.IsEmpty Then
      Begin
        Customer2.Edit;
        Result := TsmxRecordState.rsNew;
      End
      Else
      Begin
        // EditDataset(Customer2);
        Result := TsmxRecordState.rsExisting;
      End;

      Customer2.EnableControls;

    End;
  End
  Else
  Begin
    // EditDataset(Customer2);
    Result := TsmxRecordState.rsExisting;
    Customer2.EnableControls;
  End;

End;

Function TMainData.FindGuestRecord(ACustomerType: TCustomerType): TsmxRecordState;
Var
  AId: Integer;
  lIsActive: Boolean;
  lDataset: TDataSet;
Begin

  FGuestType := ACustomerType;

  Case ACustomerType Of
    TCustomerType.Primary:
      Begin
        AId := CustomerId;
        lDataset := GuestDataset;
      End;
    TCustomerType.Secondary:
      Begin
        AId := GuestId;
        lDataset := GuestDataset2;
      End;
  End;

  If lDataset.Active Then
  Begin
    If lDataset.Locate('CustomerId', AId, []) Then
      Result := TsmxRecordState.rsExisting
    Else
    Begin
      lDataset.Insert;
      lDataset.FieldByName('CustomerId').AsInteger := AId;
      Result := TsmxRecordState.rsNew;
    End;
  End
  Else
  Begin
    lDataset.Open;
    lDataset.Edit;
    lDataset.FieldByName('CustomerId').AsInteger := AId;
    Result := TsmxRecordState.rsNew;
  End;
End;

Procedure TMainData.FindHoliday(Const HolidayId: Integer);
Begin
  If Not HolidaysDataset.Active Then
    Exit;

  If Not HolidaysDatasetHolidayId.Value <> HolidayId Then
  Begin
    If Not HolidaysDataset.Locate('HolidayId', HolidayId, []) Then
      Raise Exception.Create('Cannot locate holiday ' + HolidayId.ToString);
  End;

  HolidayBooking.HolidayRef := HolidaysDatasetHolidayRef.Value;
  HolidayBooking.StartDate := DateOf(HolidaysDatasetStartDate.Value);
  HolidayBooking.EndDate := DateOf(HolidaysDatasetEndDate.Value);
  HolidayBooking.BalanceDueDate := DateOf(IncDay(HolidayBooking.StartDate, -Balance_Due_Days));

End;

Function TMainData.GetBookingId: Integer;
Begin
  If BookingDataset.Active And (Not BookingDataset.IsEmpty) Then
    Result := BookingDatasetBookingId.Value
  Else
    Result := 0;
End;

Function TMainData.GetChoiceComplete: Boolean;
Begin
  Result := HolidayBooking.ChoiceComplete;
End;

Function TMainData.GetCustomer: TBasicPerson;
Begin
  If Not Assigned(FCustomer) Then
  Begin
    FCustomer := TBasicPerson.Create;

  End;
  Result := FCustomer;
End;

Function TMainData.GetCustomerEmailAddress: String;
Begin
  Result := AuthService.EmailAddress;
End;

Function TMainData.GetCustomerId: Integer;
Begin
  If (FCustomerId = 0) And IsLoggedIn Then
    FCustomerId := AuthService.PersonId;
  Result := FCustomerId;
  { TODO : Just read from Auth? }
End;

Function TMainData.GetCustomerName: String;
Begin
  If IsLoggedIn Then
    Result := Customer.FullName
  Else
    Result := '';
End;

Function TMainData.GetDebugInfo: String;
Const
  Is_Confirmed: Array [Boolean] Of String = (' (Not Confirmed)', ' (Confirmed)');
Begin
{$IFNDEF RELEASE}
  If HolidayBooking.HolidayId = 0 Then
    Result := 'Holiday id: Not selected'
  Else
  Begin
    If HolidayData.Active Then
    Begin
      Result := Is_Confirmed[HolidayDataConfirmed.Value] + ' (MediaCount: ' + MediaCount.ToString + ')';
    End
    Else
      Result := ' (not active)';
    Result := 'Holiday Id: ' + HolidayBooking.HolidayId.ToString + Result;
  End;

  If BookingDataset.Active Then
    Result := Result + '; Booking Id: ' + BookingDatasetBookingId.AsString
  Else
    Result := Result + '; Booking: not active';

  If HotelDataset.Active Then
    Result := Result + '; Hotel Id: ' + HotelDatasetHotelId.AsString
  Else
    Result := Result + '; Hotel not selected';

  If IsLoggedIn Then
  Begin
    If MainData.IsFirstRegistration Then
      Result := Result + '; Logged in: first registration ' + CustomerId.ToString
    Else
      Result := Result + '; Logged in: ' + CustomerId.ToString;
  End
  Else
    Result := Result + '; Not logged in';
{$ELSE}
  Result := '';
{$ENDIF}
End;

Function TMainData.GetAdditionalGuestData: TAdditionalGuestData;
Begin
  If Not Assigned(FAdditionalGuestData) Then
    FAdditionalGuestData := TAdditionalGuestData.Create;
  Result := FAdditionalGuestData;
End;

Function TMainData.GetGuestId: Integer;
Begin
  If FGuestId = 0 Then
  Begin
    If Customer2.Active And (Not Customer2.IsEmpty) Then
      FGuestId := Customer2CustomerId.Value;
  End;
  Result := FGuestId;
End;

Function TMainData.GetHolidayBooking: THolidayBooking;
Begin
  If Not Assigned(FHolidayBooking) Then
    FHolidayBooking := THolidayBooking.Create;
  Result := FHolidayBooking;
End;

Function TMainData.GetHolidayDescription: String;
Begin
  If Not HolidaysDataset.Active Then
    Exit('');
  Result := THolidayUtils.HolidayDescription(HolidaysDataset) + ' at ' + HolidayBooking.HotelName + ', ' +
    HolidayBooking.VenueName;
End;

Function TMainData.GetHotelId: Integer;
Begin
  Result := HolidayBooking.HotelId;
End;

Function TMainData.GetIsActiveBooking: Boolean;
Begin
  Result := Assigned(FHolidayBooking) And FHolidayBooking.ChoiceComplete;
End;

Function TMainData.GetIsFirstRegistration: Boolean;
Begin
  Result := FFirstRegistration;
End;

Function TMainData.GetIsLoggedIn: Boolean;
Begin
  Result := SphinxLogin.IsLoggedIn;
End;

Procedure TMainData.GetLastEmergencyInfo;
Const
  emergency_svc = 'ICustomerService.LastEmergencyInfo';
Var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
Begin
  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(emergency_svc, [CustomerId]));
  lResult := lRetval.ResultAsObject;

  FAdditionalGuestData := TAdditionalGuestData.Create;
  FAdditionalGuestData.Assign(lResult);
End;

Function TMainData.GetLocalValue(Const Key: String): String;
Begin
  Result := TLocalStorage.GetValue('Alpha-' + Key);
End;

Function TMainData.GetPageSwitched: Boolean;
Begin
  Result := (FPreviousMainPage <> TMainAppPage.mpNone) And (FPreviousMainPage <> FMainPage);
End;

Function TMainData.GetRequestLink: TDeepLink;
Begin
  If Not Assigned(FRequestLink) Then
  Begin
    FRequestLink := TDeepLink.Create;
    FRequestLink.LinkKind := TLinkKind.None;
  End;
  Result := FRequestLink;
End;

Function TMainData.GetSelectedVenue: THolidayVenue;
Begin
  If FSelectedVenue = -1 Then
    Result := Nil
  Else
    Result := FVenues[FSelectedVenue];
End;

Function TMainData.GetSessionValue(Const Key: String): String;
Begin
  Result := TSessionStorage.GetValue('Alpha-' + Key);
End;

Procedure TMainData.Customer2AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Var
  lResolveId, lSize: Integer;
  lMessage: String;
Begin

  lMessage := '';
  lSize := Length(Info.Records);

  If Not TXDataDSUtils.ResolvedAsExpected(Info, 'Guest', lMessage) Then
    RaiseException(lMessage);

  If (Info.Records[0].Status = TUpdateStatus.usInserted) Then
  Begin
    lResolveId := JS.toInteger(TJSObject(Info.Records[0].Data)['CustomerId']);
    TJSObject(Customer2.CurrentData)['CustomerId'] := lResolveId;
    GuestId := lResolveId;
    EditDataset(BookingDataset);
    BookingDatasetGuestId.Value := lResolveId;
  End;

  // This should be the only Processing State that invokes this
  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Customer2 := True;
    SaveAllDataCheck;
  End;

  Assert(ProcessingState = TProcessingState.psSavingData, 'should not get to Guest2AfterApplyUpdates');

End;

Procedure TMainData.Customer2AfterOpen(Dataset: TDataSet);
Var
  lCustomerFound, lModified: Boolean;
  lCount: Integer;
Begin

  lModified := TXDataDSUtils.IsModified(Customer2);
  lCount := Customer2.RecordCount;

  If (BookingDatasetGuestId.Value <> GuestId) And (GuestId <> 0) Then
  Begin
    EditDataset(BookingDataset);
    BookingDatasetGuestId.Value := GuestId;
  End;

  FCustomerTypeLoading := TCustomerType.None;
  Customer2.EnableControls;

  If Assigned(FCustomerOpenCallBack) Then
    FCustomerOpenCallBack(Self);

  If FProcessingState = psLoadingPreviousBooking Then
  Begin
    FBookingOpen.Customer2 := True;
    CallbackAfterSelectedBookingLoaded;
  End;

End;

Procedure TMainData.Customer2EmailSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToLower;
End;

Procedure TMainData.GuestDataset2AfterOpen(Dataset: TDataSet);
Var
  lCount: Integer;
Begin
  lCount := Dataset.RecordCount;
  If FProcessingState = TProcessingState.psLoadingPreviousBooking Then
  Begin
    FBookingOpen.GuestDataset2 := True;
    CallbackAfterSelectedBookingLoaded;
  End;
End;

Procedure TMainData.Customer2NewRecord(Dataset: TDataSet);
Begin
  Customer2HearAboutUs.Value := 0;
  Customer2CountryCode.Value := 'GB';
  Customer2FullyVaccinated.Value := 0;
  Customer2ParentCustomerId.Value := CustomerId;
  Customer2CustomerType.Value := TRttiEnumerationType.GetName<TCustomerType>(TCustomerType.Secondary);
End;

Procedure TMainData.Customer2PostCodeSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToUpper;
End;

Procedure TMainData.GuestDataset2AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Begin

  TXDataDSUtils.UpdateAllIds(GuestDataset2, Info, 'GuestId');

  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.GuestDataset2 := True;
    SaveAllDataCheck;
    Exit;
  End;

  If ProcessingState = TProcessingState.psAccountUpdate Then
  Begin
    ProcessingState := FStoredProcessingState;
    If Assigned(FApplyGuestAccountChange) Then
      FApplyGuestAccountChange(Self);
    Exit;
  End;

  If Assigned(FOnReadyToProgress) Then
    FOnReadyToProgress(True);

End;

Procedure TMainData.GuestDataset2NewRecord(Dataset: TDataSet);
Var
  lBookingId: Integer;
  lMedia: String;
  lMediaType: TMediaType;
Begin
  // TCustomerType.Secondary:

  lBookingId := BookingDatasetBookingId.Value;
  GuestDataset2BookingId.Value := BookingDatasetBookingId.Value;
  GuestDataset2EaselRequired.Value := False;
  GuestDataset2FoldingChairRequired.Value := False;
  GuestDataset2BoardRequired.Value := False;

  GuestDataset2GuestType.Value := guest_type_bool[HolidayBooking.NonPainters = 1];
  GuestDataset2CustomerId.Value := GuestId;
  GuestDataset2GuestNumber.Value := 2;

  GuestDataset2RoomTypeId.Value := Integer(HolidayBooking.RoomType2);
  GuestDataset2Media2.Value := Media_Types[TMediaType.None];
  If (GuestDataset2GuestType.Value = 'Painter') And (MainData.MediaCount = 1) Then
  Begin
    lMediaType := MediaSelection;
    lMedia := Media_Types[lMediaType];
    GuestDataset2Media1.Value := lMedia;
  End
  Else
  Begin
    GuestDataset2Media1.Value := Media_Types[TMediaType.None];
    GuestDataset2Media2.Value := Media_Types[TMediaType.None];
  End;

End;

Procedure TMainData.GuestDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Begin

  TXDataDSUtils.UpdateAllIds(GuestDataset, Info, 'GuestId');

  If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.GuestDataset := True;
    SaveAllDataCheck;
    Exit;
  End;

  If ProcessingState = TProcessingState.psAccountUpdate Then
  Begin
    ProcessingState := FStoredProcessingState;
    If Assigned(FApplyGuestAccountChange) Then
      FApplyGuestAccountChange(Self);
    Exit;
  End;

  If Assigned(FOnReadyToProgress) Then
    FOnReadyToProgress(True);
End;

Procedure TMainData.GuestDatasetAfterOpen(Dataset: TDataSet);
Var
  lCount: Integer;
Begin
  lCount := Dataset.RecordCount;
  If FProcessingState = TProcessingState.psLoadingPreviousBooking Then
  Begin
    FBookingOpen.GuestDataset := True;
    CallbackAfterSelectedBookingLoaded;
  End;
End;

Procedure TMainData.GuestDatasetNewRecord(Dataset: TDataSet);
Var
  lBookingId: Integer;
  lMedia: String;
  lMediaType: TMediaType;
Begin
  // TCustomerType.Primary:

  lBookingId := BookingDatasetBookingId.Value;
  GuestDatasetBookingId.Value := BookingDatasetBookingId.Value;
  GuestDatasetEaselRequired.Value := False;
  GuestDatasetFoldingChairRequired.Value := False;
  GuestDatasetBoardRequired.Value := False;

  GuestDatasetGuestType.Value := 'Painter';
  GuestDatasetCustomerId.Value := CustomerId;
  GuestDatasetGuestNumber.Value := 1;
  GuestDatasetRoomTypeId.Value := Integer(HolidayBooking.RoomType);

  If MainData.MediaCount = 1 Then
  Begin
    lMediaType := MediaSelection;
    lMedia := Media_Types[lMediaType];
    GuestDatasetMedia1.Value := lMedia;
  End
  Else
  Begin
    GuestDatasetMedia1.Value := Media_Types[TMediaType.None];
    GuestDatasetMedia2.Value := Media_Types[TMediaType.None];
  End;

  GuestDatasetEmergencyContactName.Value := AdditionalGuestData.ContactName;
  GuestDatasetEmergencyContactRelation.Value := FAdditionalGuestData.Relation;
  GuestDatasetEmergencyContactTelephone.Value := FAdditionalGuestData.Telephone;
  GuestDatasetOtherNeeds.Value := FAdditionalGuestData.OtherNeeds;
  GuestDatasetDietaryRequirements.Value := FAdditionalGuestData.DietaryRequirements;
  GuestDatasetMobility.Value := FAdditionalGuestData.Mobility;

End;

Function TMainData.GuestIsPainter(Const ACustomerType: TCustomerType): Boolean;
Begin
  Result := ACustomerType = TCustomerType.Primary;
  If Result Then
    Exit;
  Result := HolidayBooking.Painters = 2;
End;

Procedure TMainData.HandleAccountAfterLogin;
Begin
  // SetLoginState(TLoginState.lisLoggedIn);
  CreateMyAccountPage;
End;

Procedure TMainData.HandleBookingAfterLogin;
Var
  lProcess, lBookingRef, lValue: String;
  lCustomerId: Integer;
  lBookingExists: Boolean;
Begin

  LoadHolidayBooking;

  lCustomerId := FCustomerId;

  If FCustomerId > 0 Then
  Begin
    lBookingRef := THolidayUtils.CreateBookingRef(HolidayBooking.HolidayRef, FCustomerId);
    lBookingExists := Await(BookingExists(lBookingRef));
  End;

  lProcess := TRttiEnumerationType.GetName<TProcessingState>(ProcessingState);

  If ProcessingState <> TProcessingState.psBookingExists Then
  Begin
    FContinuingAfterLogin := True;
    LoadHolidayContinuation; // the end of this process will check for waitlist
  End
  Else
    CreateBookingPage;

End;

Procedure TMainData.HolidayDataAfterOpen(Dataset: TDataSet);
Begin

  Case ProcessingState Of
    psLoadingPreviousBooking:
      Begin
        FHolidayOpen.HolidayData := True;
        HolidayBooking.HotelId := HolidayDataMainHotelId.Value;
        SelectVenue(HolidayDataVenueId.Value);
        LoadHotel(HolidayDataMainHotelId.Value);
        LoadHolidays(HolidayDataMainHotelId.Value);
        // CallbackAfterSelectedBookingLoaded;
      End;
    psContinuingBooking:
      ProgressBookingAfterContinuation;
    psLogInProcessing:
      ProgressBookingAfterContinuation;
  End;

End;

Procedure TMainData.HolidayDataCalcFields(Dataset: TDataSet);
Begin

  If HolidayDataSingleOccupancy.Value > 0 Then
    HolidayDataNPPSingleOccupancy.Value := HolidayDataNPPPrice.Value +
      (HolidayDataSingleOccupancy.Value - HolidayDataPainterPrice.Value)
  Else
    HolidayDataNPPSingleOccupancy.Value := HolidayDataNPPPrice.Value;

End;

Procedure TMainData.HolidaysDatasetAfterOpen(Dataset: TDataSet);
Var
  lFound: Boolean;
  lRecords: Integer;
Begin
  lRecords := HolidaysDataset.RecordCount;

  HolidaysDataset.EnableControls;

  If HolidayBooking.HolidayId > 0 Then
    lFound := HolidaysDataset.Locate('HolidayId', HolidayBooking.HolidayId, []);

  Case ProcessingState Of
    psLoadingPreviousBooking:
      Begin
        FHolidayOpen.Holidays := True;
        CallbackAfterSelectedBookingLoaded;
      End;
    psContinuingBooking:
      ProgressBookingAfterContinuation;
    psLogInProcessing:
      ProgressBookingAfterContinuation;
  End;
End;

Procedure TMainData.HolidaysDatasetCalcFields(Dataset: TDataSet);
Begin
  HolidaysDatasetStartDateFormatted.Value := FormatDateTime('dd-mm-yyyy', HolidaysDatasetStartDate.Value);
  HolidaysDatasetEndDateFormatted.Value := FormatDateTime('dd-mm-yyyy', HolidaysDatasetEndDate.Value);
End;

Function TMainData.HolidayStillAvailable(Const AHolidayId: Integer): Boolean;
Const
  holiday_exists_svc = 'IHolidayService.HolidayAvailable'; // (BookingRef)
Var
  lRetval: TXDataClientResponse;
  lExistingBooking: Integer;
Begin
  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_exists_svc, [AHolidayId]));
  Result := JS.toBoolean(lRetval.ResultAsObject['value']);
End;

Procedure TMainData.HotelDatasetAfterOpen(Dataset: TDataSet);
Begin

  Case ProcessingState Of
    psLoadingPreviousBooking:
      Begin
        FHolidayOpen.Hotel := True;
        HolidayBooking.HotelName := HotelDatasetName.Value;
        CallbackAfterSelectedBookingLoaded;
      End;
    psContinuingBooking:
      ProgressBookingAfterContinuation;
    psLogInProcessing:
      ProgressBookingAfterContinuation;
  End;
End;

Procedure TMainData.InitModule(Const CalledAfterLogin: Boolean);
Var
  lLastSection: TMainAppPage;
  s: String;
  lSessionId: Integer;
  lLogLink: Boolean;
Begin
  { TODO : Accept feed ins from query }
  // if HasQueryParam('holidayid', lHolidayId) then
  // LocalValue['holiday-id'] := lHolidayId;

  ProcessingState := TProcessingState.psStandard;

  Await(DataConnection.OpenAsync);

  RoomTypes.Load;
  HearAboutOptions.Load;
  LoadMailingAddress;
  LoadBankDetails;
  LoadPaymentKeys;

  lLogLink := False;
  If Not CalledAfterLogin Then
  Begin
    Await(ProcessQuery);
    If RequestLink.LinkKind In [TLinkKind.Venue, TLinkKind.Hotel, TLinkKind.Holiday] Then
       lLogLink := True;
  End;

  ProcessingState := TProcessingState.psStandard;

  lSessionId := Await(SessionId);

  if lLogLink then
  begin
    LogSomething('Deep-Link', 'DeepLink', Integer(RequestLink.LinkKind), 'HolidayId: ' + RequestLink.LogData, TLogKind.Trace);
    lLogLink := False;
  end;

  s := TRttiEnumerationType.GetName<TLoginSource>(FLoginSource);

  Case FLoginSource Of
    lsNotSpecified:
      Begin
        s := SessionValue[Last_Section];
        If s <> '' Then
          lLastSection := TRttiEnumerationType.GetValue<TMainAppPage>(s)
        Else
          lLastSection := TMainAppPage.mpBooking;

        Case lLastSection Of
          mpBooking:
            CreateBookingPage;
          mpMyAccount:
            ShowMyAccountPage;
        End;
      End;

    lsMyAccount:
      ShowMyAccountPage;

    lsBooking:
      Begin
        If Not CalledAfterLogin Then
          CreateBookingPage;
      End;

  End;

  FInitDone := True;

End;

Procedure TMainData.LoadBookingCosts(Const ABookingId: Integer);
Begin
  If BookingCosts.Active Then
    TXDataDSUtils.CloseDataset(BookingCosts);

  If Not BookingCosts.Active Then
  Begin
    BookingCosts.QueryString := '$filter=BookingId eq ' + ABookingId.ToString; { TODO : Order By? }
    BookingCosts.Load;
  End;
End;

Procedure TMainData.LoadBookingFromHoliday;
Var
  lCurrentState: TBookingState;
  lState: String;
  lCount: Integer;
Begin
  OpenBooking;

  lCurrentState := THolidayUtils.StrToBookingState(BookingDatasetBookingState.Value);
  lState := TRttiEnumerationType.GetName<TBookingState>(lCurrentState);
  If lCurrentState > TBookingState.New Then
  Begin
    UpdateBookingFromHoliday;
    Exit;
  End;

  UpdateBookingFromHolidayBasic;
  BookingDatasetBookingState.Value := Booking_State[TBookingState.New];

  CreateBaseBookingCosts;
  BookingDatasetTotalHolidayPrice.Value := TotalHolidayPrice;

End;

Procedure TMainData.LoadConfig;
Var
  Conn: TXDataWebConnection;
  Response: IHttpResponse;
Begin

  Conn := TXDataWebConnection.Create(Nil);
  Try

    Response := Await(Conn.SendRequestAsync(THttpRequest.Create('config/config.json')));
    If Response.StatusCode = 200 Then
    Begin
      AppConfig.Load(Response.ContentAsText);
      DataConnection.URL := AppConfig.BaseUrl;
      SphinxLogin.Authority := AppConfig.AuthURL;

      If AppConfig.SphinxClientId = '' Then
        SphinxLogin.ClientId := 'AlphaWebApp'
      Else
        SphinxLogin.ClientId := AppConfig.SphinxClientId;

      SphinxLogin.RedirectUri := SphinxRedirectUri;

    End;

  Finally
    Conn.Free;
  End;

End;

Procedure TMainData.LoadBankDetails;
Const
  bank_details_svc = 'IHolidayService.BankDetails';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin
  If FBankDetails = Nil Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(bank_details_svc, []));
    lData := lRetval.ResultAsObject;
    FBankDetails := TBankDetails.Create;
    FBankDetails.Load(lData);
  End;
End;

Procedure TMainData.LoadBookableHolidayById(Const HolidayId: Integer);
Const
  holiday_by_id_svc = 'IHolidayService.HolidayById';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSArray;
Begin
  If HolidayId = 0 Then
    Exit;

  { TODO : Check if Active and already on the correct id }
  HolidaysDataset.DisableControls;
  Try
    HolidaysDataset.Active := False;

    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_by_id_svc, [HolidayId]));
    lData := TJSArray(lRetval.ResultAsObject['value']);

    HolidaysDataset.Rows := lData;
    HolidaysDataset.Active := True;

  Finally
    HolidaysDataset.EnableControls;
  End;

  LoadRoomAllocations;

End;

Procedure TMainData.LoadHoliday(Const HolidayId: Integer);
Begin

  FindHoliday(HolidayId);

  If HolidayData.Active And (HolidayDataHolidayid.Value <> HolidayId) Then
    TXDataDSUtils.CloseDataset(HolidayData);

  If Not HolidayData.Active Then
  Begin
    HolidayData.QueryString := '$filter=HolidayId eq ' + HolidayId.ToString;
    HolidayData.Load;
  End
  Else If ProcessingState = TProcessingState.psLoadingPreviousBooking Then
    FHolidayOpen.HolidayData := True;

  If HolidayBooking.HolidayId <> HolidayId Then
  Begin
    HolidayBooking.HolidayId := HolidayId;

    If HolidaysDataset.Locate('HolidayId', MainData.HolidayBooking.HolidayId, []) Then
    Begin
      HolidayBooking.HolidayRef := HolidaysDatasetHolidayRef.Value;
      HolidayBooking.StartDate := DateOf(HolidaysDatasetStartDate.Value);
      HolidayBooking.EndDate := DateOf(HolidaysDatasetEndDate.Value);
      HolidayBooking.BalanceDueDate := DateOf(IncDay(HolidayBooking.StartDate, -Balance_Due_Days));
    End;
  End;

  LoadRoomAllocations;

End;

Function TMainData.LoadHolidayBooking: Boolean;
Var
  lValue: String;
Begin
  lValue := SessionValue[Holiday_Booking];
  If lValue = '' Then
    Exit(False);
  HolidayBooking.Load(TJSJSON.parseObject(lValue));
  SessionValue[Holiday_Booking] := '';
  Result := True;
End;

Procedure TMainData.LoadHolidayContinuation;
Begin
  ProcessingState := TProcessingState.psContinuingBooking;
  Await(LoadVenues);
  SelectVenue(HolidayBooking.VenueId);
  LoadHotel(HolidayBooking.HotelId);
  LoadHolidays(HolidayBooking.HotelId);
  LoadHoliday(HolidayBooking.HolidayId);

  ProgressBookingAfterContinuation;

End;

Procedure TMainData.LoadHolidayFromBooking;
Var
  lPainterCount: Integer;
Begin
  HolidayBooking.HolidayId := BookingDatasetHolidayId.Value;
  FHolidayBooking.HolidayRef := THolidayUtils.HolidayRefFromBookingRef(BookingDatasetBookingReference.Value);
  FHolidayBooking.BookingId := BookingDatasetBookingId.Value;
  lPainterCount := BookingDatasetNumberOfPainters.Value;
  FHolidayBooking.Painters := BookingDatasetNumberOfPainters.Value;
  If BookingDatasetNonPaintingPartner.Value Then
    FHolidayBooking.NonPainters := 1;

  FHolidayBooking.RoomType := TRoomType(BookingDatasetFirstRoomTypeId.Value);
  FHolidayBooking.RoomType2 := TRoomType(BookingDatasetSecondRoomTypeId.Value);
  FHolidayBooking.RoomShare := BookingDatasetShareRoom.Value;

  // FHolidayBooking.CustomerId := BookingDatasetCustomerId.Value;
  // FHolidayBooking.GuestId := BookingDatasetGuestId.Value;
  FHolidayBooking.BalanceDueDate := BookingDatasetFinalBalanceDueOn.Value;
  FHolidayBooking.LatestBookingState := BookingDatasetBookingState.Value;
  FHolidayBooking.ChoiceComplete := FHolidayBooking.HighestBookingState >= TBookingState.HolidaySelected;

End;

Procedure TMainData.LoadHolidays(Const HotelId: Integer);
Const
  holiday_svc = 'IHolidayService.BookableHolidays';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSArray;
  lFound: Boolean;
Begin
  If ProcessingState = TProcessingState.psLoadFromLink Then
    HolidaysDataset.Close
  Else If HolidaysDataset.Active And (Not HolidaysDataset.IsEmpty) And (HolidaysDatasetHotelId.Value = HotelId) Then
  Begin
    If ProcessingState = TProcessingState.psLoadingPreviousBooking Then
      FHolidayOpen.Holidays := True;
    Exit;
  End;

  HolidaysDataset.DisableControls;
  Try
    If HolidaysDataset.Active Then
      HolidaysDataset.Close;

    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_svc, [HotelId]));
    lData := TJSArray(lRetval.ResultAsObject['value']);

    HolidaysDataset.Rows := lData;
    If Not HolidaysDataset.Active Then
      HolidaysDataset.Open;

  Finally
    // controls enabled in AfterOpen
  End;

End;

Procedure TMainData.LoadHotel(Const AHotelId: Integer);
Var
  lRetval: TXDataClientResponse;
Begin

  If HolidayBooking.HotelId <> AHotelId Then
    HolidayBooking.HotelId := AHotelId;
  If HotelDataset.Active And (HotelDatasetHotelId.Value <> HolidayBooking.HotelId) Then
    TXDataDSUtils.CloseDataset(HotelDataset);

  If Not HotelDataset.Active Then
  Begin
    HotelDataset.QueryString := '$filter=HotelId eq ' + HolidayBooking.HotelId.ToString;
    HotelDataset.Load;
    Exit;
  End;

  Case ProcessingState Of
    psLoadingPreviousBooking:
      CallbackAfterSelectedBookingLoaded;
    psContinuingBooking:
      ProgressBookingAfterContinuation;
    psLogInProcessing:
      ProgressBookingAfterContinuation;
  End;

End;

Procedure TMainData.LoadHotels(Const VenueId: Integer);
Const
  holiday_svc = 'IHolidayService.BookableHotels';
Var
  lRetval: TXDataClientResponse;
  lArray: JS.TJSArray;
  lHotel: TJSObject;
  lHolidayHotel: THolidayHotel;
  I: Integer;
Begin

  If Assigned(FHotels) And (FHotels.Count > 0) And (FHotels[0].VenueId <> VenueId) Then
    Exit;

  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_svc, [VenueId]));
  lArray := TJSArray(lRetval.ResultAsObject['value']);

  If Not Assigned(FHotels) Then
    FHotels := TList<THolidayHotel>.Create
  Else
    FHotels.Clear;

  For I := 0 To lArray.Length - 1 Do
  Begin
    lHotel := TJSObject(lArray[I]);
    lHolidayHotel := THolidayHotel.Create;
    lHolidayHotel.Load(lHotel);
    FHotels.Add(lHolidayHotel);
  End;

End;

Procedure TMainData.LoadMailingAddress;
Const
  mailing_address_svc = 'IHolidayService.MailingAddress';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin
  If Not Assigned(FMailingAddress) Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(mailing_address_svc, []));
    lData := lRetval.ResultAsObject;
    FMailingAddress := TMailingAddress.Create;
    FMailingAddress.Load(lData);
  End;
End;

Procedure TMainData.LoadPaymentKeys;
Const
  payment_keys_svc = 'IHolidayService.PaymentAPIKeys';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin

  If FPaymentAPIKeys = Nil Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(payment_keys_svc, []));
    lData := lRetval.ResultAsObject;
    FPaymentAPIKeys := TPaymentAPIKeys.Create;
    FPaymentAPIKeys.Load(lData);
  End;

End;

Procedure TMainData.LoadPayments(Const ABookingId: Integer);
Begin
  If Payments.Active And (PaymentsBookingId.Value <> ABookingId) Then
    TXDataDSUtils.CloseDataset(Payments);
  If Not Payments.Active Then
  Begin
    Payments.QueryString := '$filter=BookingId eq ' + ABookingId.ToString; { TODO : Order By? }
    Payments.Load;
  End;
End;

Procedure TMainData.LoadRoomAllocations;
Begin
  If RoomAllocations.Active Then
  Begin
    If RoomAllocationsHolidayid.Value = HolidayBooking.HolidayId Then
    Begin
      If ProcessingState = TProcessingState.psLoadingPreviousBooking Then
        FHolidayOpen.RoomAllocations := True;
      Exit;
    End
    Else
      TXDataDSUtils.CloseDataset(RoomAllocations);
  End;
  RoomAllocations.QueryString := '$filter=Holidayid eq ' + HolidayBooking.HolidayId.ToString;
  RoomAllocations.Load;
End;

Procedure TMainData.LoadSelectedBooking(Const ABookingId: Integer);
Var
  latestState: TBookingState;
Begin
  ProcessingState := psLoadingPreviousBooking;
  latestState := THolidayUtils.StrToBookingState(HolidayBooking.LatestBookingState);

  If latestState > TBookingState.HolidaySelected Then
    SessionValue[Stored_Holiday_Booking] := TJSJSON.Stringify(FHolidayBooking);

  FSelectedBookingHandled := False;
  FHolidayOpen.Clear;
  FBookingOpen.Clear;

  ResetHolidayOptions;

  { TODO : Close Linked Datasets? }
  If BookingDataset.Active Then
    TXDataDSUtils.CloseDataset(BookingDataset);
  BookingDataset.QueryString := '$filter=Bookingid eq ' + ABookingId.ToString;
  BookingDataset.Load;

  LoadBookingCosts(ABookingId);
  LoadPayments(ABookingId);

End;

Procedure TMainData.LoadSelectedBookingAdditionalData;
Const
  guest_filter = '$filter=(BookingId eq %s) and (CustomerId eq %s)';
Var
  lFilter: String;
Begin
  LoadHolidayFromBooking;

  If CustomerDataset.Active Then
    TXDataDSUtils.CloseDataset(CustomerDataset);

  OpenCustomerDataset;

  If GuestDataset.Active Then
    TXDataDSUtils.CloseDataset(GuestDataset);
  GuestDataset.QueryString := Format(guest_filter, [BookingDatasetBookingId.AsString,
    BookingDatasetCustomerId.AsString]);
  GuestDataset.Load;

  If Customer2.Active Then
    TXDataDSUtils.CloseDataset(Customer2);

  If BookingDatasetGuestId.Value > 0 Then
  Begin
    lFilter := 'CustomerId eq ' + BookingDatasetGuestId.AsString;
    Customer2.QueryString := '$filter=' + lFilter;
    Customer2.Load;

    GuestDataset2.QueryString := Format(guest_filter, [BookingDatasetBookingId.AsString,
      BookingDatasetGuestId.AsString]);
    GuestDataset2.Load;
  End
  Else
  Begin
    FBookingOpen.Customer2 := True;
    FBookingOpen.GuestDataset2 := True;
  End;

  Await(LoadVenues);

  LoadHoliday(HolidayBooking.HolidayId);
  // Call from HolidayData.AfterOpen
  // LoadHotel(HolidayBooking.HotelId); //  SelectVenue(HolidayBooking.VenueId);
  // LoadHolidays(HolidayBooking.HotelId);

  CallbackAfterSelectedBookingLoaded;

End;

Function TMainData.LoadSelectedGuest(Const AGuestId: Integer): TsmxRecordState;
Begin
  If Customer2.Active Then
  Begin
    If Customer2CustomerId.Value = AGuestId Then
      Exit(TsmxRecordState.rsExisting)
    Else
      TXDataDSUtils.CloseDataset(Customer2);
  End;

  FCustomerTypeLoading := TCustomerType.Secondary;
  Customer2.QueryString := '$filter=(CustomerId eq ' + GuestId.ToString + ')';
  Customer2.Load;
  Result := TsmxRecordState.rsLoading;
End;

Procedure TMainData.LoadVenues;
Const
  holiday_svc = 'IHolidayService.BookableVenues';
Var
  lRetval: TXDataClientResponse;
  lArray: JS.TJSArray;
  lVenue: TJSObject;
  lHolidayVenue: THolidayVenue;
  I: Integer;
Begin

  If Assigned(FVenues) And (FVenues.Count > 0) Then
    Exit;

  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_svc, []));
  lArray := TJSArray(lRetval.ResultAsObject['value']);

  If Not Assigned(FVenues) Then
    FVenues := TList<THolidayVenue>.Create
  Else
    FVenues.Clear;

  For I := 0 To lArray.Length - 1 Do
  Begin
    lVenue := TJSObject(lArray[I]);
    lHolidayVenue := THolidayVenue.Create;
    lHolidayVenue.Load(lVenue);
    FVenues.Add(lHolidayVenue);
  End;

End;

Function TMainData.LocalValueKeyStartsWith(Const Value: String): String;
Begin
  // for I := 0 to TWebLocalStorage.Count - 1 do

End;

Procedure TMainData.Login(Const ASource: TLoginSource);
Var
  lRedirect, lBaseURL, lScope, lClientId: String;
Begin

  lRedirect := SphinxLogin.RedirectUri;
  lBaseURL := SphinxLogin.Authority;
  lScope := SphinxLogin.Scope;
  lClientId := SphinxLogin.ClientId;

  FLoginSource := ASource;
  SessionValue[Login_Source] := TRttiEnumerationType.GetName<TLoginSource>(ASource);

  ProcessingState := TProcessingState.psLoggingIn;
  SessionValue[Process_State] := TRttiEnumerationType.GetName<TProcessingState>(ProcessingState);

  If ASource = TLoginSource.lsBooking Then
    SaveHolidayBooking;

  LogSomething('Login', 'Sphinx', 1, 'Sphinx login started', TLogKind.Info);

  SphinxLogin.Login;
End;

Procedure TMainData.Logout(Const LogoutMethod: TLogoutMethod);
Begin
  LogSomething('Logout', 'Sphinx', 2, 'Sphinx Logout', TLogKind.Info);
  SphinxLogin.Logout;
  SessionValue[Login_Source] := '';
  SessionValue[Holiday_Booking] := '';
  SessionValue[Process_State] := '';
  TLocalStorage.RemoveKey(Paypal_Storage);
  CustomerId := 0;
  ResetHolidayOptions;

  ProcessingState := TProcessingState.psStandard;
  If (LogoutMethod = TLogoutMethod.lomManual) And Assigned(FAfterLogout) Then
    FAfterLogout(Self);
  SwitchMainPage(TMainAppPage.mpBooking);
End;

Procedure TMainData.LogPageChange(Const AFromPage, AToPage, ADirection: String);
Const
  log_svc = 'IHolidayService.LogPageChange';
  // (Const MainPage, FromPage, ToPage, Direction: String);
Begin
  WebClient.RawInvokeAsync(log_svc, [FMainPageText, AFromPage, AToPage, ADirection]);
End;

Procedure TMainData.LogSomething(Const AAction, IdType: String; Const IdValue: Integer; Const ALogMessage: String;
  Const ALogKind: TLogKind);
Const
  log_svc = 'IHolidayService.LogSomething';
Var
  lMessage: String;
Begin
  If ALogMessage.StartsWith('{') Then
    lMessage := ALogMessage
  Else
    lMessage := '{"Value":"' + ALogMessage + '"}';
  WebClient.RawInvokeAsync(log_svc, [FMainPageText, AAction, IdType, IdValue, lMessage,
    TRttiEnumerationType.GetName<TLogKind>(ALogKind)]);
End;

Function TMainData.MediaCount: Integer;
Begin
  Result := HolidayDataMediaWaterColours.AsInteger + HolidayDataMediaOil.AsInteger + HolidayDataMediaAcrylic.AsInteger +
    HolidayDataMediaPastel.AsInteger;
End;

Function TMainData.MediaSelection: TMediaType;
Begin
  If MediaCount > 1 Then
    Exit(TMediaType.None);

  If HolidayDataMediaWaterColours.AsInteger = 1 Then
    Result := TMediaType.Watercolours
  Else If HolidayDataMediaOil.AsInteger = 1 Then
    Result := TMediaType.Oils
  Else If HolidayDataMediaAcrylic.AsInteger = 1 Then
    Result := TMediaType.Acrylics
  Else If HolidayDataMediaPastel.AsInteger = 1 Then
    Result := TMediaType.Pastels
  Else
    Result := TMediaType.None;
End;

Function TMainData.NeedsFullPayment: Boolean;
Begin
  Result := HolidayBooking.BalanceDueDate <= Today; // BookingDatasetFinalBalanceDueOn.Value <= Today;
End;

Procedure TMainData.NonPaymentConfirmation(APaymentProvider: TPaymentProvider);
Const
  confirmation_email_svc = 'IHolidayService.SendConfirmationEmail';
Begin

  Assert(HolidayBooking.BookingId > 0, 'Oops, no booking id');

  ProcessingState := TProcessingState.psConfirmation;
  PaymentProvider := APaymentProvider;

  WebClient.RawInvokeAsync(confirmation_email_svc, [HolidayBooking.BookingId, 0,
    Payment_Provider[APaymentProvider], True]);
  ProcessingState := TProcessingState.psStandard;

End;

Procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; Var Handled: Boolean);
Begin
  If Not Assigned(AError.AError) Then
    Exit;
  LogSomething('Exception', 'Error', 0, TJSJSON.Stringify(AError), TLogKind.Error);
  If Assigned(FErrorMessageProc) Then
  Begin
    FErrorMessageProc('We''re sorry, there''s been a problem with this. We are looking into it.'
{$IFDEF DEBUG} + '; DebugInfo: See Console and database log'{$ENDIF});
    Handled := True;
  End;

End;

Procedure TMainData.OnApplicationException(Sender: TObject; E: Exception);
Begin
  LogSomething('Exception', 'Error', 0, TJSJSON.Stringify(E), TLogKind.AnException);
  If Assigned(FErrorMessageProc) Then
  Begin
    FErrorMessageProc('We''re sorry, there''s been a problem with this. We are looking into it.'
{$IFDEF DEBUG} + '; DebugInfo: See Console and database log'{$ENDIF});
  End;
End;

Function TMainData.OpenBooking: TsmxRecordState;
Begin
  If Not BookingDataset.Active Then
  Begin
    BookingDataset.Open;
    BookingDataset.Edit;
    Result := TsmxRecordState.rsNew;
  End
  Else
  Begin
    If Not(BookingDataset.State In dsEditModes) Then
      BookingDataset.Edit;
    Result := TsmxRecordState.rsExisting;
  End;
End;

Function TMainData.OpenCustomerDataset: Boolean;
Var
  lFilter: String;
Begin
  If CustomerDataset.Active Then
  Begin
    Exit(True);
  End;

  lFilter := '';
  If BookingDataset.Active And (Not BookingDataset.IsEmpty) Then
  Begin
    If BookingDatasetCustomerId.Value > 0 Then
      lFilter := 'CustomerId eq ' + BookingDatasetCustomerId.AsString;

    If lFilter <> '' Then
    Begin
      CustomerDataset.QueryString := '$filter=' + lFilter;
      CustomerDataset.Load;
      Exit(False);
    End
    Else
    Begin
      CustomerDataset.Open;
      Exit(True);
    End;
  End
  Else
  Begin
    CustomerDataset.Open;
    Exit(True);
  End;
End;

Function TMainData.OpenGuest2: Boolean;
Var
  lFilter: String;
Begin
  If Customer2.Active Then
  Begin
    Exit(True);
  End;

  lFilter := '';
  If BookingDataset.Active And (Not BookingDataset.IsEmpty) Then
  Begin
    If BookingDatasetGuestId.Value > 0 Then
    Begin
      lFilter := 'CustomerId eq ' + BookingDatasetGuestId.AsString;
      Customer2.QueryString := '$filter=' + lFilter;
      Customer2.Load;
      Exit(False);
    End
    Else
    Begin
      Customer2.Open;
      Exit(True);
    End;
  End
  Else
  Begin
    Customer2.Open;
    Exit(True);
  End;
End;

Function TMainData.PaymentAPIKey(Const AProvider: TPaymentProvider): String;
Const
  payment_keys_svc = 'IHolidayService.PaymentAPIKeys';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;

Begin
  Result := '';
  If FPaymentAPIKeys = Nil Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(payment_keys_svc, []));
    lData := lRetval.ResultAsObject;
    FPaymentAPIKeys := TPaymentAPIKeys.Create;
    FPaymentAPIKeys.Load(lData);
  End;

  Case AProvider Of
    TPaymentProvider.None:
      ;
    TPaymentProvider.PayPal:
      Result := FPaymentAPIKeys.PayPal;
    TPaymentProvider.Revolut:
      Result := FPaymentAPIKeys.RevolutMode;
    TPaymentProvider.BACS:
      ;
    TPaymentProvider.Cheque:
      ;
  End;

End;

Procedure TMainData.PaymentsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Begin
  If (Info.Records[0].Status = TUpdateStatus.usInserted) Then
  Begin
    TXDataDSUtils.UpdateFieldValue(Payments, Info, 0, 'Id');
  End;

  // Only called for successful payments
  If FProcessingState = TProcessingState.psPaymentProcessing Then
  Begin
    EditDataset(BookingDataset);
    BookingDatasetTotalPaid.Value := BookingDatasetTotalPaid.Value + PaymentsAmount.Value;
    BookingDatasetBookingState.Value := Booking_State[THolidayUtils.BookingStateCalc(BookingDatasetBalanceDue.Value,
      BookingDatasetTotalHolidayPrice.Value)];
    If Assigned(FAfterPaymentProcessed) Then
      FAfterPaymentProcessed(TPaymentState.Success);
  End
  Else If FProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Payments := True;
    SaveAllDataCheck;
  End;
End;

Procedure TMainData.PaymentsAfterOpen(Dataset: TDataSet);
Begin
  If FProcessingState = psLoadingPreviousBooking Then
  Begin
    FBookingOpen.Payments := True;
    CallbackAfterSelectedBookingLoaded;
  End;
End;

Procedure TMainData.PaymentsNewRecord(Dataset: TDataSet);
Begin
  PaymentsBookingId.Value := BookingDatasetBookingId.Value;
  PaymentsPaymentDate.Value := Now;
End;

Procedure TMainData.PostDepatureDays(Const Value: Integer);
Begin
  EditDataset(BookingDataset);
  BookingDatasetFirstRoomExtraNightsAfter.Value := Value;
  BookingDatasetDepartureDate.Value := IncDay(HolidayDataEndDate.Value, Value);
  CalcExtraNights;
End;

Procedure TMainData.PreArrivalDays(Const Value: Integer);
Begin
  EditDataset(BookingDataset);
  BookingDatasetFirstRoomExtraNightsBefore.Value := Value;
  BookingDatasetArrivalDate.Value := IncDay(HolidayDataStartDate.Value, -Value);
  CalcExtraNights;
End;

Procedure TMainData.ProcessPayment(APaymentProvider: TPaymentProvider; Const OnInitialBooking: Boolean);
Begin
  Assert(MainData.PaymentsState.Value = Payment_State[TPaymentState.Success],
    'only should be called for successful payments');
  FPaymentOnBooking := OnInitialBooking;
  PaymentProvider := APaymentProvider;
  ProcessingState := TProcessingState.psPaymentProcessing;
  MainData.Payments.Post;
  MainData.Payments.ApplyUpdates;
End;

Procedure TMainData.ProcessQuery;
Const
  holiday_by_ref_svc = 'IHolidayService.GetHolidayLinkingByCode';
  holiday_by_id_svc = 'IHolidayService.GetHolidayLinkingById';

Var
  lParamValue: String;
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;

Begin
  lRetval := Nil;
  If HasQueryParam('hr', lParamValue, False) Then
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_by_ref_svc, [lParamValue]))
  Else If HasQueryParam('hi', lParamValue, False) Then
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(holiday_by_id_svc, [lParamValue.toInteger]));

  If Assigned(lRetval) Then
  Begin
    FRequestLink := TDeepLink.Create(lRetval.ResultAsObject);
    Exit;
  End;

End;

Procedure TMainData.RaiseException(Const AMessage: String);
Begin
  TheMainPage.HideWaitMessage;
  Raise Exception.Create(AMessage);
End;

Procedure TMainData.ReloadCustomer;
Begin
  // Used after editing My Details in MyAccount
  If CustomerDataset.Active Then
  Begin
    FStoredProcessingState := ProcessingState;
    ProcessingState := TProcessingState.psReloadingCustomer;
    CustomerDataset.Close;
    CustomerDataset.Load;
  End;
End;

Procedure TMainData.ResetHolidayOptions(Const WaitListStep: TWaitListStep = TWaitListStep.wlNoAdd);
Begin
  ChoiceComplete := False;

  HolidayBooking.ClearHolidayInfo;
  FHolidayBooking.Free;
  FHolidayBooking := Nil;
  HolidayBooking.WaitList := WaitListStep;

  If HolidaysDataset.Active Then
    HolidaysDataset.Close;
  If RoomAllocations.Active Then
    TXDataDSUtils.CloseDataset(RoomAllocations);
  If HotelDataset.Active Then
    TXDataDSUtils.CloseDataset(HotelDataset);
  If HolidayData.Active Then
    TXDataDSUtils.CloseDataset(HolidayData);

  If Assigned(FHotels) Then
  Begin
    FHotels.Free;
    FHotels := Nil;
  End;

End;

Procedure TMainData.RoomAllocationsAfterOpen(Dataset: TDataSet);
Var
  lCount: Integer;
Begin

  If Assigned(FRoomAllocationsCallBack) Then
    FRoomAllocationsCallBack(Self);

  Case ProcessingState Of
    psLoadingPreviousBooking:
      Begin
        FHolidayOpen.RoomAllocations := True;
        lCount := RoomAllocations.RecordCount;
        CallbackAfterSelectedBookingLoaded;
      End;
    psContinuingBooking:
      ProgressBookingAfterContinuation;
    psLogInProcessing:
      ProgressBookingAfterContinuation;
  End;

End;

Function TMainData.RoomAvailable(Const ARoomType: TRoomType; Const ACount: Integer): Boolean;
Var
  MaxAllocations, Allocated, Available: Integer;
Begin

  Result := False;
  RoomAllocations.First;
  While Not RoomAllocations.Eof Do
  Begin

    If RoomAllocationsRoomTypeId.Value = Room_Type_Int[ARoomType] Then
    Begin
      MaxAllocations := RoomAllocationsMaxAllocation.Value;
      Allocated := RoomAllocationsAllocated.Value;
      Available := MaxAllocations - Allocated;

      Exit(Available >= ACount);
    End;

    RoomAllocations.Next;
  End;
End;

Function TMainData.RoomsAvailableCount: Integer;
Var
  MaxAllocations, Allocated, Available: Integer;
Begin
  Result := 0;
  RoomAllocations.First;
  While Not RoomAllocations.Eof Do
  Begin
    MaxAllocations := RoomAllocationsMaxAllocation.Value;
    Allocated := RoomAllocationsAllocated.Value;
    Available := MaxAllocations - Allocated;

    Result := Result + Available;
    RoomAllocations.Next;
  End;
End;

Procedure TMainData.SaveBookingDatasets(Const ABookingState: TBookingState);
Var
  lBookingState: String;
  lCurrentState: TBookingState;
Begin

  FBookingState := ABookingState;
  lBookingState := TRttiEnumerationType.GetName<TBookingState>(ABookingState);

  If ABookingState < Earliest_Save_State Then
  Begin
    HolidayBooking.HighestBookingState := ABookingState;
    If Assigned(FOnReadyToProgress) Then
      FOnReadyToProgress(True);
    Exit;
  End;

  FStoredProcessingState := ProcessingState;
  ProcessingState := TProcessingState.psSavingData;
  lCurrentState := THolidayUtils.StrToBookingState(BookingDatasetBookingState.Value);
  FBookingSaved.Clear;

  EditDataset(BookingDataset);

  If ABookingState = TBookingState.OptionalExtrasComplete Then
    BookingDatasetTotalHolidayPrice.Value := TotalHolidayPrice;

  If lCurrentState < ABookingState Then
  Begin
    HolidayBooking.HighestBookingState := ABookingState;
    BookingDatasetBookingState.Value := Booking_State[ABookingState];
  End;

  BookingDataset.Post;

  If TXDataDSUtils.IsModified(BookingDataset) Then
    BookingDataset.ApplyUpdates
  Else
  Begin
    FBookingSaved.Booking := True;
    SaveAllData;
  End;

End;

Procedure TMainData.SaveAllData;
Begin
  // Booking is handled in SaveBookingDatasets

  If TXDataDSUtils.CheckIsModified(CustomerDataset) Then
    CustomerDataset.ApplyUpdates
  Else
    FBookingSaved.Customers := True;

  If TXDataDSUtils.CheckIsModified(Customer2) Then
    Customer2.ApplyUpdates
  Else
    FBookingSaved.Customer2 := True;

  If TXDataDSUtils.CheckIsModified(GuestDataset) Then
    GuestDataset.ApplyUpdates
  Else
    FBookingSaved.GuestDataset := True;

  If TXDataDSUtils.CheckIsModified(GuestDataset2) Then
    GuestDataset2.ApplyUpdates
  Else
    FBookingSaved.GuestDataset2 := True;

  If TXDataDSUtils.CheckIsModified(BookingCosts) Then
    BookingCosts.ApplyUpdates
  Else
    FBookingSaved.Costs := True;

  If TXDataDSUtils.CheckIsModified(Payments) Then
    Payments.ApplyUpdates
  Else
    FBookingSaved.Payments := True;

  SaveAllDataCheck;
End;

Procedure TMainData.SaveAllDataCheck;
Var
  lState, lBookingState: String;
Begin
  If FBookingSaved.AllDone Then
  Begin
    ProcessingState := FStoredProcessingState;
    FStoredProcessingState := TProcessingState.psStandard;
    lState := TRttiEnumerationType.GetName<TProcessingState>(ProcessingState);
    Case ProcessingState Of
      psStandard:
        Begin
          If (FBookingState In Finalised_Booking_States) Then
          Begin
            FLastBookingId := BookingDatasetBookingId.Value;
          End;
        End;
      psLoadingPreviousBooking:
        ;
      psContinuingBooking:
        ;
      psBookingExists:
        ;
      psWaitList:
        Begin
          If Customer.FullName <> '' Then
          Begin
            AfterAddToWaitList;
            If Assigned(FWaitingListProcessedCallBack) Then
              FWaitingListProcessedCallBack(Self);
          End;
          If FContinuingAfterLogin Then
          Begin
            CreateBookingPage;
            FContinuingAfterLogin := False;
          End;
        End;

      psPaymentProcessing:
        Begin
          SendPaymentEmail(BookingDatasetBookingId.Value, PaymentsId.Value, PaymentsProvider.Value);
          If (FBookingState In Finalised_Booking_States) Then
          Begin
            FLastBookingId := BookingDatasetBookingId.Value;
          End;

        End;

      psConfirmation:
        ;
      psLoggingIn:
        ;
      psLogInProcessing:
        ;
      psSavingData:
        ;
    End;

    If Assigned(FOnReadyToProgress) Then
      FOnReadyToProgress(True);
  End;
End;

Procedure TMainData.SaveBookingCosts;
Begin
  If (BookingCosts.State In dsEditModes) Then
    BookingCosts.Post;

  If TXDataDSUtils.IsModified(BookingCosts) Then
    BookingCosts.ApplyUpdates
  Else If ProcessingState = TProcessingState.psSavingData Then
  Begin
    FBookingSaved.Costs := True;
    SaveAllDataCheck;
  End;

End;

Function TMainData.UpdateBookingData: Boolean;
Var
  lRef, lBookingRef: String;
Begin

  If Not BookingDataset.Active Then
    BookingDataset.Open;

  EditDataset(BookingDataset);

  UpdateBookingFromHolidayBasic;

  If BookingDatasetCustomerId.Value <> CustomerId Then
    BookingDatasetCustomerId.Value := CustomerId;

  lBookingRef := BookingDatasetBookingReference.Value;
  If lBookingRef = '' Then
    lBookingRef := HolidayBooking.HolidayRef;
  lRef := THolidayUtils.CreateBookingRef(lBookingRef, FCustomerId);
  If BookingDatasetBookingReference.Value <> lRef Then
    BookingDatasetBookingReference.Value := lRef;

  // if ProcessingState = TProcessingState.psWaitList then
  // BookingDatasetBookingState.Value := Booking_State[TBookingState.WaitingList]
  // else
  // BookingDatasetBookingState.Value := Booking_State[TBookingState.CustomerIdentified];

  BookingDataset.Post;

End;

Procedure TMainData.SaveCustomer;
Begin

  FSavingGuestType := TCustomerType.Primary;

  EditDataset(CustomerDataset);

  If (CustomerDatasetUserId.Value <> FUserId) Then
    CustomerDatasetUserId.Value := FUserId;

  If (CustomerDataset.State In dsEditModes) Then
    CustomerDataset.Post;

  Customer.FirstName := CustomerDatasetFirstName.Value;
  Customer.LastName := CustomerDatasetLastName.Value;

  If TXDataDSUtils.IsModified(CustomerDataset) Then
    CustomerDataset.ApplyUpdates
  Else
    UpdateBookingData;

End;

Procedure TMainData.SaveGuest(ACustomerType: TCustomerType);
Begin
  If ACustomerType = TCustomerType.Primary Then
  Begin
    AdditionalGuestData.ContactName := GuestDatasetEmergencyContactName.Value;
    FAdditionalGuestData.Telephone := GuestDatasetEmergencyContactTelephone.Value;
    FAdditionalGuestData.Relation := GuestDatasetEmergencyContactRelation.Value;
    FAdditionalGuestData.DietaryRequirements := GuestDatasetDietaryRequirements.Value;
    FAdditionalGuestData.Mobility := GuestDatasetMobility.Value;
    FAdditionalGuestData.OtherNeeds := GuestDatasetOtherNeeds.Value;

    If GuestDatasetCustomerId.Value = 0 Then
    Begin
      EditDataset(GuestDataset);
      GuestDatasetCustomerId.Value := CustomerId
    End;

    If GuestDataset.State In dsEditModes Then
      GuestDataset.Post;
    If ACustomerType = TCustomerType.Primary Then
    Begin
      EditDataset(BookingDataset);
      BookingDatasetTransportId.Value := GuestDatasetTransportId.Value;
      BookingDatasetOtherTravelRequirements.Value := GuestDatasetOtherTravelRequirements.Value;
    End;

  End
  Else
  Begin
    If GuestDataset2CustomerId.Value = 0 Then
    Begin
      EditDataset(GuestDataset2);
      GuestDataset2CustomerId.Value := GuestId;
      If GuestDataset2.State In dsEditModes Then
        GuestDataset2.Post;
    End;
  End;
End;

Procedure TMainData.SaveCustomer2;
Begin
  If Customer2AddressSameAsParent.Value Then
    CopyCustomerAddress;

  HolidayBooking.GuestName := Customer2FirstName.Value + ' ' + Customer2LastName.Value;
End;

// Procedure TMainData.SaveGuests;
// Begin
// If (GuestDataset.State In dsEditModes) Then
// GuestDataset.Post;
//
// If TXDataDSUtils.IsModified(GuestDataset) Then
// GuestDataset.ApplyUpdates
// Else If ProcessingState = TProcessingState.psSavingData Then
// Begin
// FBookingSaved.Guests := True;
// SaveAllDataCheck;
// End;
// End;

Procedure TMainData.SaveHolidayBooking;
Begin
  HolidayBooking.TimeDataSaved := Now;
  SessionValue[Holiday_Booking] := TJSJSON.Stringify(FHolidayBooking);
End;

Procedure TMainData.SelectVenue(Const VenueId: Integer);
Var
  I: Integer;
Begin
  FSelectedVenue := -1;
  HolidayBooking.VenueId := VenueId;
  For I := 0 To Venues.Count - 1 Do
  Begin
    If Venues[I].VenueId = VenueId Then
    Begin
      FSelectedVenue := I;
      HolidayBooking.VenueName := Venues[I].VenueName;
      Break;
    End;
  End;
End;

Procedure TMainData.SendPaymentEmail(Const ABookingId, APaymentId: Integer; Const APaymentProvider: String);
Const
  confirmation_email_svc = 'IHolidayService.SendConfirmationEmail';
Begin
  WebClient.RawInvokeAsync(confirmation_email_svc, [ABookingId, APaymentId, APaymentProvider, FPaymentOnBooking]);
End;

Function TMainData.SessionId: Integer;
Const
  Session_svc = 'IHolidayService.NewSession';
Var
  lRetval: TXDataClientResponse;
  sk, prevSession: Integer;
Begin
  If FSessionId = 0 Then
  Begin
    FSessionId := StrToIntDef(SessionValue['SessionId'], 0);
    If FSessionId = 0 Then
    Begin
      prevSession := StrToIntDef(LocalValue['LastSession'], 0);
      sk := Ord(TSessionKind.Web);
      lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(Session_svc, [sk, prevSession]));
      FSessionId := JS.toInteger(lRetval.ResultAsObject['value']);
      SessionValue['SessionId'] := FSessionId.ToString;
      LocalValue['LastSession'] := FSessionId.ToString;
    End;
  End;
  Result := FSessionId;
End;

Procedure TMainData.SetChoiceComplete(Const Value: Boolean);
Begin
  HolidayBooking.ChoiceComplete := Value;
End;

Procedure TMainData.SetCustomerId(Const Value: Integer);
Begin
  // Customer.CustomerId := Value;
  FCustomerId := Value;
End;

Procedure TMainData.SetGuestId(Const Value: Integer);
Begin
  FGuestId := Value;
End;

Procedure TMainData.SetLocalValue(Const Key, Value: String);
Begin
  If Value = '' Then
    TLocalStorage.RemoveKey('Alpha-' + Key)
  Else
    TLocalStorage.SetValue('Alpha-' + Key, Value);
End;

Procedure TMainData.SetMainPage(Const Value: TMainAppPage);
Begin
  FMainPage := Value;
  FMainPageText := TRttiEnumerationType.GetName<TMainAppPage>(FMainPage);
  SessionValue[Last_Section] := TRttiEnumerationType.GetName<TMainAppPage>(Value);
End;

Procedure TMainData.SetPaymentProvider(Const Value: TPaymentProvider);
Begin
  FPaymentProvider := Value;
End;

Procedure TMainData.SetProcessingState(Const Value: TProcessingState);
Begin
  FProcessingState := Value;
End;

Procedure TMainData.SetSessionValue(Const Key, Value: String);
Begin
  // if Value = '' then
  // TWebSessionStorage.RemoveKey('Alpha-' + Key)
  // else
  TSessionStorage.SetValue('Alpha-' + Key, Value);
End;

Procedure TMainData.ShowMyAccountPage;
Begin
  If SphinxLogin.IsLoggedIn Then
    CreateMyAccountPage
  Else
    Login(TLoginSource.lsMyAccount);
End;

Procedure TMainData.SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs);
Const
  Session_svc = 'IHolidayService.SetSessionUserId';
Var
  lValue, tValue, rValue: String;
  lLoginSource: TLoginSource;
  lSessionId: Integer;
Begin

  ProcessingState := TProcessingState.psLogInProcessing;

  Await(InitModule(True));

  FUserId := AuthService.UserId;
  CustomerId := AuthService.PersonId;
  Customer.FirstName := AuthService.GetClaimValue(CLAIM_FIRSTNAME, TSphinxTokenType.sttId);
  Customer.LastName := AuthService.GetClaimValue(CLAIM_LASTNAME, TSphinxTokenType.sttId);

  lSessionId := Await(SessionId);
  WebClient.RawInvokeAsync(Session_svc, [lSessionId, FUserId]);
  LogSomething('Login', 'Sphinx', 2, 'Sphinx login success', TLogKind.Info);

  tValue := YesNo[True];
  rValue := AuthService.GetClaimValue(CLAIM_DATA_COMPLETE, TSphinxTokenType.sttId);
  FFirstRegistration := rValue <> tValue;
  If Not FFirstRegistration Then
    Await(GetLastEmergencyInfo); // Not sure we need await here

  Case FLoginSource Of
    lsMyAccount:
      Begin
        TDocUtils.pushState(SphinxRedirectUri + '#bpHolidayChoice');
        TDocUtils.pushState(SphinxRedirectUri + '#AccountMyBookings');
        Await(HandleAccountAfterLogin);
      End;
    lsBooking:
      Begin
        // TDocUtils.replaceState(SphinxRedirectUri + '#bpHolidayChoice'); //, 'Alpha Painting Holidays', );
        TDocUtils.pushState(SphinxRedirectUri + '#bpHolidayChoice');
        Await(HandleBookingAfterLogin);
      End;
  End;

  SessionValue[Login_Source] := '';
End;

Function TMainData.SphinxRedirectUri: String;
Var
  I: Integer;
Begin
  Result := Application.ExeName;
  I := Result.IndexOf('?');
  If I > 0 Then
    Result := Result.Substring(0, I);
  I := Result.IndexOf('#');
  If I > 0 Then
    Result := Result.Substring(0, I);
End;

Procedure TMainData.StartBooking;
Begin
  LoadBookingFromHoliday;
  ChoiceComplete := True;
End;

Procedure TMainData.SwitchMainPage(Const APage: TMainAppPage);
Var
  lFromTo: String;
Begin

  If FMainPage = APage Then
    Exit;

  FPreviousMainPage := FMainPage;

  lFromTo := Main_App_Page[FPreviousMainPage] + ' to ';

  Case APage Of
    mpBooking:
      Begin
        lFromTo := lFromTo + Main_App_Page[mpBooking];
        UnlinkAllCallBacks;
        If ProcessingState = TProcessingState.psLoadingPreviousBooking Then
        Begin
          LogSomething('Section-Change', 'Customer', 1, lFromTo + ': Load a previous booking', TLogKind.Trace);
          CreateBookingPage;
        End
        Else If LoadHolidayBooking Then
        Begin
          LogSomething('Section-Change', 'Customer', 2, lFromTo + ': Continue current booking', TLogKind.Trace);
          ChoiceComplete := False;
          ProcessingState := TProcessingState.psContinuingBooking;
          LoadHolidayContinuation;
        End
        Else
        Begin
          LogSomething('Section-Change', 'Customer', 3, lFromTo + ': Make a new booking', TLogKind.Trace);
          ResetHolidayOptions;
          CreateBookingPage;
        End;
      End;
    mpMyAccount:
      Begin
        lFromTo := lFromTo + Main_App_Page[mpMyAccount];
        LogSomething('Section-Change', 'Customer', 4, lFromTo + ': Switch to My Account', TLogKind.Trace);
        UnlinkAllCallBacks;
        If ChoiceComplete Then
          SaveHolidayBooking;
        ShowMyAccountPage;
      End;
  End;
End;

Function TMainData.TotalHolidayPrice: Double;
Begin
  Result := 0.00;
  If Not BookingCosts.Active Then
    Exit;

  BookingCosts.First;
  While Not BookingCosts.Eof Do
  Begin
    Result := Result + BookingCostsTotalCost.Value;
    BookingCosts.Next;
  End;
End;

Function TMainData.TotalPaid: Double;
Begin
  Result := 0.00;
  If Not Payments.Active Then
    Exit;

  Payments.First;
  While Not Payments.Eof Do
  Begin
    If PaymentsState.Value = Payment_State[TPaymentState.Success] Then
      Result := Result + PaymentsAmount.Value;
    Payments.Next;
  End;

End;

Procedure TMainData.UnlinkAllCallBacks;
Begin
  FRoomAllocationsCallBack := Nil;
  FCustomerOpenCallBack := Nil;
  FOnReadyToProgress := Nil;
  FAfterPaymentProcessed := Nil;
  FAfterLogout := Nil;
  FOnSelectedBookingLoaded := Nil;
End;

Procedure TMainData.UpdateBookingCostsWithId(Const BookingId: Integer);
Begin
  If Not BookingCosts.Active Then
    Exit;
  BookingCosts.First;
  While Not BookingCosts.Eof Do
  Begin
    If BookingCostsBookingId.Value <> BookingId Then
    Begin
      BookingCosts.Edit;
      BookingCostsBookingId.Value := BookingId;
      BookingCosts.Post;
    End;
    BookingCosts.Next;
  End;
End;

Procedure TMainData.UpdateBookingFromHoliday;
Begin
  THolidayGuestsUpdate.UpdateOnChange;
  THolidaySelectionUpdate.UpdateBooking;

  THolidayGuestsUpdate.PainterUpdateCost;
  THolidayGuestsUpdate.NPPUpdateCost;
  CalcExtraNights;

  BookingDatasetTotalHolidayPrice.Value := TotalHolidayPrice;
End;

Procedure TMainData.UpdateBookingFromHolidayBasic;
Var
  lCount: Integer;
Begin
  BookingDatasetCustomerId.Value := CustomerId;
  lCount := HolidayBooking.GuestCount;
  BookingDatasetNoOfGuests.Value := HolidayBooking.GuestCount;
  lCount := HolidayBooking.NonPainters;
  BookingDatasetNonPaintingPartner.Value := HolidayBooking.NonPainters = 1;
  BookingDatasetArrivalDate.Value := HolidayBooking.StartDate; // HolidaysDatasetStartDate.Value;
  BookingDatasetDepartureDate.Value := HolidayBooking.EndDate; // HolidaysDatasetEndDate.Value;
  BookingDatasetFirstRoomExtraNightsBefore.Value := 0;
  BookingDatasetFirstRoomExtraNightsAfter.Value := 0;
  BookingDatasetHasDog.Value := False;
  BookingDatasetTransportId.Value := 0; // Car
  BookingDatasetShareRoom.Value := HolidayBooking.RoomShareValue;
  BookingDatasetNumberOfPainters.Value := HolidayBooking.Painters;
  If IsLoggedIn Then
    BookingDatasetBookingReference.Value := THolidayUtils.CreateBookingRef(HolidaysDatasetHolidayRef.Value,
      FCustomerId);

  { TODO : how to check this? what if it's false? }
  BookingDatasetRoomAvailability.Value := True;
  BookingDatasetHolidayAvailability.Value := True;

  BookingDatasetCreatedOn.Value := Now;

  If HolidayBooking.BalanceDueDate > Now Then
    BookingDatasetFinalBalanceDueOn.Value := HolidayBooking.BalanceDueDate
  Else
    BookingDatasetFinalBalanceDueOn.Value := Today;
  If HolidayBooking.BalanceDueDate > Today Then
    BookingDatasetDepositDue.Value := HolidayDataDepositresidential.Value * HolidayBooking.GuestCount
  Else
    BookingDatasetDepositDue.Value := 0;

  BookingDatasetTotPriceAdjustment.Value := 0.00;
  BookingDatasetTotalPaid.Value := 0.00;

  BookingDatasetGuestId.Value := GuestId;
  BookingDatasetHolidayId.Value := HolidayBooking.HolidayId;

  BookingDatasetFirstRoomTypeId.Value := Room_Type_Int[HolidayBooking.RoomType];
  BookingDatasetSecondRoomTypeId.Value := Room_Type_Int[HolidayBooking.RoomType2];
  BookingDatasetTotalHolidayPrice.Value := TotalHolidayPrice;
End;

Procedure TMainData.UpdateDogCost(Const HasDog: Boolean);
Begin

  If BookingCosts.Locate('ItemType', Cost_Item_Type[TCostItemType.Dog], []) Then
  Begin
    If Not HasDog Then
      BookingCosts.Delete;
    Exit;
  End;

  BookingCosts.Append;

  BookingCostsItemType.Value := Cost_Item_Type[TCostItemType.Dog];
  BookingCostsUnitPrice.Value := HotelDatasetDogCost.Value;
  BookingCostsSubItemType.Value := Cost_Sub_Type[TCostSubType.Standard];

  If HotelDatasetDogCostPeriod.Value = Cost_Period_PerStay Then
  Begin
    BookingCostsQuantity.Value := 1;
    BookingCostsCostPeriod.Value := Cost_Period_PerStay;
    BookingCostsDescription.Value := 'Your Dog for entire stay';
  End
  Else
  Begin
    BookingCostsQuantity.Value := BookingDatasetTotalNumberOfNights.Value;
    BookingCostsCostPeriod.Value := Cost_Period_PerNight;
    BookingCostsDescription.Value := 'Your Dog';
  End;

  BookingCosts.Post;
  UpdateTotalBookingPrice;

End;

Procedure TMainData.UpdateGuestsWithId(Const BookingId: Integer);
Begin
  If Not GuestDataset.Active Then
    Exit;
  GuestDataset.First;
  While Not GuestDataset.Eof Do
  Begin
    If GuestDatasetBookingId.Value <> BookingId Then
    Begin
      GuestDataset.Edit;
      GuestDatasetBookingId.Value := BookingId;
      GuestDataset.Post;
    End;
    GuestDataset.Next;
  End;
End;

Procedure TMainData.UpdateTotalBookingPrice;
Begin
  If Not BookingDataset.Active Then
    Exit;
  If Not(BookingDataset.State In dsEditModes) Then
    BookingDataset.Edit;
  BookingDatasetTotalHolidayPrice.Value := TotalHolidayPrice;
End;

Procedure TMainData.WebClientError(Error: TXDataClientError);
Var
  lHandled: Boolean;
Begin
  // If Not FIsLoggingException Then
  // Begin
  // lHandled := False;
  // HandleAnException(EGeneralXDataException.Create(Error), lHandled);
  // End;
End;

Procedure TMainData.WebDataModuleCreate(Sender: TObject);
Begin
  FPreviousMainPage := TMainAppPage.mpNone;
  FInitDone := False;
  FIsLoggingException := False;

  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';
  FSelectedVenue := -1;

{$IFDEF DEBUG}
  Application.ErrorType := aeFooter; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := OnApplicationError;
  Application.OnException := OnApplicationException;
  // DataConnection.OnError := DataConnectionError;
  // WebClient.OnError := WebClientError;
  DataConnection.OnError := Nil;
  WebClient.OnError := Nil;
{$ELSE}
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := OnApplicationError;
  // DataConnection.OnError := DataConnectionError;
  // WebClient.OnError := WebClientError;
{$ENDIF}
  Await(ConfigInit);

  If ProcessingState = TProcessingState.psLoggingIn Then
    SphinxLogin.Login
  Else
    InitModule(False);
End;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DataConnection := TXDataWebConnection.Create(Self);
  WebClient := TXDataWebClient.Create(Self);
  CustomerDataset := TXDataWebDataSet.Create(Self);
  CustomerDatasetCustomerId := TIntegerField.Create(Self);
  CustomerDatasetTitle := TStringField.Create(Self);
  CustomerDatasetFirstName := TStringField.Create(Self);
  CustomerDatasetMiddleName := TStringField.Create(Self);
  CustomerDatasetLastName := TStringField.Create(Self);
  CustomerDatasetHomeTelephoneNumber := TStringField.Create(Self);
  CustomerDatasetMobileNumber := TStringField.Create(Self);
  CustomerDatasetEmail := TStringField.Create(Self);
  CustomerDatasetAddressLine1 := TStringField.Create(Self);
  CustomerDatasetAddressLine2 := TStringField.Create(Self);
  CustomerDatasetAddressLine3 := TStringField.Create(Self);
  CustomerDatasetTown := TStringField.Create(Self);
  CustomerDatasetCounty := TStringField.Create(Self);
  CustomerDatasetPostCode := TStringField.Create(Self);
  CustomerDatasetDetailsComplete := TBooleanField.Create(Self);
  CustomerDatasetFullyVaccinated := TIntegerField.Create(Self);
  CustomerDatasetDateOfLastVaccination := TDateTimeField.Create(Self);
  CustomerDatasetCountryCode := TStringField.Create(Self);
  CustomerDatasetDiscountId := TIntegerField.Create(Self);
  CustomerDatasetUserId := TStringField.Create(Self);
  CustomerDatasetCustomerCode := TStringField.Create(Self);
  CustomerDatasetCustomerType := TStringField.Create(Self);
  CustomerDatasetHearAboutUs := TIntegerField.Create(Self);
  CustomerDatasetParentCustomerId := TIntegerField.Create(Self);
  CustomerDatasetAddressSameAsParent := TBooleanField.Create(Self);
  CustomerDatasetContactDetailId := TIntegerField.Create(Self);
  CustomerDatasetDateCreated := TDateTimeField.Create(Self);
  GuestDataset := TXDataWebDataSet.Create(Self);
  GuestDatasetGuestId := TIntegerField.Create(Self);
  GuestDatasetCustomerId := TIntegerField.Create(Self);
  GuestDatasetBookingId := TIntegerField.Create(Self);
  GuestDatasetGuestType := TStringField.Create(Self);
  GuestDatasetMedia1 := TStringField.Create(Self);
  GuestDatasetMedia2 := TStringField.Create(Self);
  GuestDatasetMedia3 := TStringField.Create(Self);
  GuestDatasetMedia4 := TStringField.Create(Self);
  GuestDatasetExtraNightsCharge := TFloatField.Create(Self);
  GuestDatasetRoomTypeId := TIntegerField.Create(Self);
  GuestDatasetRoomUpgradeDescription := TStringField.Create(Self);
  GuestDatasetIncomplete := TBooleanField.Create(Self);
  GuestDatasetGuestNumber := TIntegerField.Create(Self);
  GuestDatasetEaselRequired := TBooleanField.Create(Self);
  GuestDatasetFoldingChairRequired := TBooleanField.Create(Self);
  GuestDatasetDietaryRequirements := TStringField.Create(Self);
  GuestDatasetMobility := TStringField.Create(Self);
  GuestDatasetOtherNeeds := TStringField.Create(Self);
  GuestDatasetEmergencyContactName := TStringField.Create(Self);
  GuestDatasetEmergencyContactTelephone := TStringField.Create(Self);
  GuestDatasetEmergencyContactRelation := TStringField.Create(Self);
  GuestDatasetOrigAirport := TStringField.Create(Self);
  GuestDatasetPrefAirline := TStringField.Create(Self);
  GuestDatasetBoardRequired := TBooleanField.Create(Self);
  GuestDatasetTotalPersonPrice := TFloatField.Create(Self);
  GuestDatasetDepositDue := TFloatField.Create(Self);
  GuestDatasetSameContactDetails := TBooleanField.Create(Self);
  GuestDatasetOtherTravelRequirements := TStringField.Create(Self);
  GuestDatasetTransportId := TIntegerField.Create(Self);
  HolidaysDataset := TClientDataSet.Create(Self);
  HolidaysDatasetHolidayId := TIntegerField.Create(Self);
  HolidaysDatasetHolidayRef := TStringField.Create(Self);
  HolidaysDatasetTitle := TStringField.Create(Self);
  HolidaysDatasetHolidayType := TStringField.Create(Self);
  HolidaysDatasetStartDate := TDateField.Create(Self);
  HolidaysDatasetEndDate := TDateField.Create(Self);
  HolidaysDatasetTutor := TStringField.Create(Self);
  HolidaysDatasetAvailability := TIntegerField.Create(Self);
  HolidaysDatasetHotelId := TIntegerField.Create(Self);
  HolidaysDatasetVenueId := TIntegerField.Create(Self);
  HolidaysDatasetConfirmed := TBooleanField.Create(Self);
  HolidaysDatasetMedia := TStringField.Create(Self);
  HolidaysDatasetStartDateFormatted := TStringField.Create(Self);
  HolidaysDatasetEndDateFormatted := TStringField.Create(Self);
  BookingDataset := TXDataWebDataSet.Create(Self);
  BookingDatasetBookingId := TIntegerField.Create(Self);
  BookingDatasetBookingState := TStringField.Create(Self);
  BookingDatasetNoOfGuests := TIntegerField.Create(Self);
  BookingDatasetNonPaintingPartner := TBooleanField.Create(Self);
  BookingDatasetArrivalDate := TDateTimeField.Create(Self);
  BookingDatasetDepartureDate := TDateTimeField.Create(Self);
  BookingDatasetFirstRoomExtraNightsBefore := TIntegerField.Create(Self);
  BookingDatasetFirstRoomExtraNightsAfter := TIntegerField.Create(Self);
  BookingDatasetFirstRoomUpgrade := TStringField.Create(Self);
  BookingDatasetHasDog := TBooleanField.Create(Self);
  BookingDatasetTransportId := TIntegerField.Create(Self);
  BookingDatasetShareRoom := TBooleanField.Create(Self);
  BookingDatasetNumberOfPainters := TIntegerField.Create(Self);
  BookingDatasetBookingReference := TStringField.Create(Self);
  BookingDatasetDepositDue := TFloatField.Create(Self);
  BookingDatasetTotalHolidayPrice := TFloatField.Create(Self);
  BookingDatasetFinalBalanceDueOn := TDateTimeField.Create(Self);
  BookingDatasetRoomAvailability := TBooleanField.Create(Self);
  BookingDatasetHolidayAvailability := TBooleanField.Create(Self);
  BookingDatasetLogid := TIntegerField.Create(Self);
  BookingDatasetCreatedOn := TDateTimeField.Create(Self);
  BookingDatasetWheredidyouhearaboutus := TIntegerField.Create(Self);
  BookingDatasetOtherTravelRequirements := TStringField.Create(Self);
  BookingDatasetTotPriceAdjustment := TFloatField.Create(Self);
  BookingDatasetTotalPaid := TFloatField.Create(Self);
  BookingDatasetExported := TIntegerField.Create(Self);
  BookingDatasetExportedOn := TStringField.Create(Self);
  BookingDatasetCustomerId := TIntegerField.Create(Self);
  BookingDatasetHolidayId := TIntegerField.Create(Self);
  BookingDatasetFirstRoomTypeId := TIntegerField.Create(Self);
  BookingDatasetSecondRoomTypeId := TIntegerField.Create(Self);
  BookingDatasetGuestId := TIntegerField.Create(Self);
  BookingDatasetNonPainterCount := TIntegerField.Create(Self);
  BookingDatasetTotalNumberOfNights := TIntegerField.Create(Self);
  BookingDatasetBalanceDue := TFloatField.Create(Self);
  BookingDatasetExtraNights := TIntegerField.Create(Self);
  BookingCosts := TXDataWebDataSet.Create(Self);
  BookingCostsBookingcostid := TIntegerField.Create(Self);
  BookingCostsBookingId := TIntegerField.Create(Self);
  BookingCostsItemcode := TStringField.Create(Self);
  BookingCostsDescription := TStringField.Create(Self);
  BookingCostsUnitPrice := TFloatField.Create(Self);
  BookingCostsCostPeriod := TStringField.Create(Self);
  BookingCostsQuantity := TIntegerField.Create(Self);
  BookingCostsPayerId := TIntegerField.Create(Self);
  BookingCostsItemType := TStringField.Create(Self);
  BookingCostsSubItemType := TStringField.Create(Self);
  BookingCostsTotalCost := TFloatField.Create(Self);
  BookingCostsFormattedDescription := TStringField.Create(Self);
  SphinxLogin := TSphinxWebLogin.Create(Self);
  RoomAllocations := TXDataWebDataSet.Create(Self);
  RoomAllocationsHolidayroomallocationid := TIntegerField.Create(Self);
  RoomAllocationsHolidayid := TIntegerField.Create(Self);
  RoomAllocationsRoomTypeId := TIntegerField.Create(Self);
  RoomAllocationsMaxAllocation := TIntegerField.Create(Self);
  RoomAllocationsAllocated := TIntegerField.Create(Self);
  RoomTypes := TXDataWebDataSet.Create(Self);
  RoomTypesRoomtypeid := TIntegerField.Create(Self);
  RoomTypesRoomtypecode := TStringField.Create(Self);
  RoomTypesRoomdescription := TStringField.Create(Self);
  RoomTypesIssingleroom := TBooleanField.Create(Self);
  RoomTypesNonresidential := TBooleanField.Create(Self);
  HotelDataset := TXDataWebDataSet.Create(Self);
  HotelDatasetHotelId := TIntegerField.Create(Self);
  HotelDatasetCode := TStringField.Create(Self);
  HotelDatasetName := TStringField.Create(Self);
  HotelDatasetVenueId := TIntegerField.Create(Self);
  HotelDatasetSingleroomsavailable := TBooleanField.Create(Self);
  HotelDatasetDogCostPeriod := TStringField.Create(Self);
  HotelDatasetDogCost := TFloatField.Create(Self);
  HotelDatasetDogsAllowed := TBooleanField.Create(Self);
  HolidayData := TXDataWebDataSet.Create(Self);
  HolidayDataHolidayid := TIntegerField.Create(Self);
  HolidayDataReference := TStringField.Create(Self);
  HolidayDataTitle := TStringField.Create(Self);
  HolidayDataHolidayType := TStringField.Create(Self);
  HolidayDataStartDate := TDateTimeField.Create(Self);
  HolidayDataEndDate := TDateTimeField.Create(Self);
  HolidayDataMaxNumberOfStudents := TIntegerField.Create(Self);
  HolidayDataVenueId := TIntegerField.Create(Self);
  HolidayDataDepositresidential := TFloatField.Create(Self);
  HolidayDataActive := TBooleanField.Create(Self);
  HolidayDataMediaWaterColours := TBooleanField.Create(Self);
  HolidayDataMediaOil := TBooleanField.Create(Self);
  HolidayDataMediaAcrylic := TBooleanField.Create(Self);
  HolidayDataMediaPastel := TBooleanField.Create(Self);
  HolidayDataTotalAdjPainters := TIntegerField.Create(Self);
  HolidayDataTotalAdjNonPainters := TIntegerField.Create(Self);
  HolidayDataConfirmed := TBooleanField.Create(Self);
  HolidayDataMainHotelId := TIntegerField.Create(Self);
  HolidayDataSecondaryHotelId := TIntegerField.Create(Self);
  HolidayDataTutorId := TIntegerField.Create(Self);
  HolidayDataPainterPrice := TFloatField.Create(Self);
  HolidayDataNPPPrice := TFloatField.Create(Self);
  HolidayDataSingleOccupancy := TFloatField.Create(Self);
  HolidayDataSingleRoom := TFloatField.Create(Self);
  HolidayDataNPPSingleRoom := TFloatField.Create(Self);
  HolidayDataExtraNight := TFloatField.Create(Self);
  HolidayDataExtraNightSingleRoom := TFloatField.Create(Self);
  HolidayDataExtraNightSingleOccupancy := TFloatField.Create(Self);
  HolidayDataNPPSingleOccupancy := TFloatField.Create(Self);
  HolidayDataExtraNightOption := TStringField.Create(Self);
  Payments := TXDataWebDataSet.Create(Self);
  PaymentsId := TIntegerField.Create(Self);
  PaymentsProvider := TStringField.Create(Self);
  PaymentsState := TStringField.Create(Self);
  PaymentsPaymentDate := TDateTimeField.Create(Self);
  PaymentsBookingId := TIntegerField.Create(Self);
  PaymentsAmount := TFloatField.Create(Self);
  PaymentsReference := TStringField.Create(Self);
  PaymentsOutcome := TStringField.Create(Self);
  PaymentsCurrencyCode := TStringField.Create(Self);
  PaymentsPaymentType := TStringField.Create(Self);
  PaymentsPaymentLevel := TStringField.Create(Self);
  DummyLookUp := TClientDataSet.Create(Self);
  DummyLookUpSource := TDataSource.Create(Self);
  HearAboutOptions := TXDataWebDataSet.Create(Self);
  HearAboutOptionsHearAboutId := TIntegerField.Create(Self);
  HearAboutOptionsDescription := TStringField.Create(Self);
  HearAboutOptionsHearAboutStatus := TStringField.Create(Self);
  Customer2 := TXDataWebDataSet.Create(Self);
  Customer2CustomerId := TIntegerField.Create(Self);
  Customer2Title := TStringField.Create(Self);
  Customer2FirstName := TStringField.Create(Self);
  Customer2MiddleName := TStringField.Create(Self);
  Customer2LastName := TStringField.Create(Self);
  Customer2HomeTelephoneNumber := TStringField.Create(Self);
  Customer2MobileNumber := TStringField.Create(Self);
  Customer2Email := TStringField.Create(Self);
  Customer2AddressLine1 := TStringField.Create(Self);
  Customer2AddressLine2 := TStringField.Create(Self);
  Customer2AddressLine3 := TStringField.Create(Self);
  Customer2Town := TStringField.Create(Self);
  Customer2County := TStringField.Create(Self);
  Customer2PostCode := TStringField.Create(Self);
  Customer2DetailsComplete := TBooleanField.Create(Self);
  Customer2FullyVaccinated := TIntegerField.Create(Self);
  Customer2DateOfLastVaccination := TDateTimeField.Create(Self);
  Customer2CountryCode := TStringField.Create(Self);
  Customer2DiscountId := TIntegerField.Create(Self);
  Customer2UserId := TStringField.Create(Self);
  Customer2CustomerCode := TStringField.Create(Self);
  Customer2HearAboutUs := TIntegerField.Create(Self);
  Customer2CustomerType := TStringField.Create(Self);
  Customer2ParentCustomerId := TIntegerField.Create(Self);
  Customer2AddressSameAsParent := TBooleanField.Create(Self);
  Customer2ContactDetailId := TIntegerField.Create(Self);
  Customer2DateCreated := TDateTimeField.Create(Self);
  GuestDataset2 := TXDataWebDataSet.Create(Self);
  GuestDataset2GuestId := TIntegerField.Create(Self);
  GuestDataset2GuestType := TStringField.Create(Self);
  GuestDataset2RoomTypeId := TIntegerField.Create(Self);
  GuestDataset2RoomUpgradeDescription := TStringField.Create(Self);
  GuestDataset2Incomplete := TBooleanField.Create(Self);
  GuestDataset2GuestNumber := TIntegerField.Create(Self);
  GuestDataset2EaselRequired := TBooleanField.Create(Self);
  GuestDataset2FoldingChairRequired := TBooleanField.Create(Self);
  GuestDataset2Media1 := TStringField.Create(Self);
  GuestDataset2Media2 := TStringField.Create(Self);
  GuestDataset2Media3 := TStringField.Create(Self);
  GuestDataset2Media4 := TStringField.Create(Self);
  GuestDataset2DietaryRequirements := TStringField.Create(Self);
  GuestDataset2Mobility := TStringField.Create(Self);
  GuestDataset2OtherNeeds := TStringField.Create(Self);
  GuestDataset2EmergencyContactName := TStringField.Create(Self);
  GuestDataset2EmergencyContactTelephone := TStringField.Create(Self);
  GuestDataset2SameContactDetails := TBooleanField.Create(Self);
  GuestDataset2EmergencyContactRelation := TStringField.Create(Self);
  GuestDataset2OrigAirport := TStringField.Create(Self);
  GuestDataset2PrefAirline := TStringField.Create(Self);
  GuestDataset2BoardRequired := TBooleanField.Create(Self);
  GuestDataset2BookingId := TIntegerField.Create(Self);
  GuestDataset2CustomerId := TIntegerField.Create(Self);
  GuestDataset2TotalPersonPrice := TFloatField.Create(Self);
  GuestDataset2DepositDue := TFloatField.Create(Self);
  GuestDataset2ExtraNightsCharge := TFloatField.Create(Self);
  GuestDataset2OtherTravelRequirements := TStringField.Create(Self);
  GuestDataset2TransportId := TIntegerField.Create(Self);

  DataConnection.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  CustomerDataset.BeforeLoadDFMValues;
  CustomerDatasetCustomerId.BeforeLoadDFMValues;
  CustomerDatasetTitle.BeforeLoadDFMValues;
  CustomerDatasetFirstName.BeforeLoadDFMValues;
  CustomerDatasetMiddleName.BeforeLoadDFMValues;
  CustomerDatasetLastName.BeforeLoadDFMValues;
  CustomerDatasetHomeTelephoneNumber.BeforeLoadDFMValues;
  CustomerDatasetMobileNumber.BeforeLoadDFMValues;
  CustomerDatasetEmail.BeforeLoadDFMValues;
  CustomerDatasetAddressLine1.BeforeLoadDFMValues;
  CustomerDatasetAddressLine2.BeforeLoadDFMValues;
  CustomerDatasetAddressLine3.BeforeLoadDFMValues;
  CustomerDatasetTown.BeforeLoadDFMValues;
  CustomerDatasetCounty.BeforeLoadDFMValues;
  CustomerDatasetPostCode.BeforeLoadDFMValues;
  CustomerDatasetDetailsComplete.BeforeLoadDFMValues;
  CustomerDatasetFullyVaccinated.BeforeLoadDFMValues;
  CustomerDatasetDateOfLastVaccination.BeforeLoadDFMValues;
  CustomerDatasetCountryCode.BeforeLoadDFMValues;
  CustomerDatasetDiscountId.BeforeLoadDFMValues;
  CustomerDatasetUserId.BeforeLoadDFMValues;
  CustomerDatasetCustomerCode.BeforeLoadDFMValues;
  CustomerDatasetCustomerType.BeforeLoadDFMValues;
  CustomerDatasetHearAboutUs.BeforeLoadDFMValues;
  CustomerDatasetParentCustomerId.BeforeLoadDFMValues;
  CustomerDatasetAddressSameAsParent.BeforeLoadDFMValues;
  CustomerDatasetContactDetailId.BeforeLoadDFMValues;
  CustomerDatasetDateCreated.BeforeLoadDFMValues;
  GuestDataset.BeforeLoadDFMValues;
  GuestDatasetGuestId.BeforeLoadDFMValues;
  GuestDatasetCustomerId.BeforeLoadDFMValues;
  GuestDatasetBookingId.BeforeLoadDFMValues;
  GuestDatasetGuestType.BeforeLoadDFMValues;
  GuestDatasetMedia1.BeforeLoadDFMValues;
  GuestDatasetMedia2.BeforeLoadDFMValues;
  GuestDatasetMedia3.BeforeLoadDFMValues;
  GuestDatasetMedia4.BeforeLoadDFMValues;
  GuestDatasetExtraNightsCharge.BeforeLoadDFMValues;
  GuestDatasetRoomTypeId.BeforeLoadDFMValues;
  GuestDatasetRoomUpgradeDescription.BeforeLoadDFMValues;
  GuestDatasetIncomplete.BeforeLoadDFMValues;
  GuestDatasetGuestNumber.BeforeLoadDFMValues;
  GuestDatasetEaselRequired.BeforeLoadDFMValues;
  GuestDatasetFoldingChairRequired.BeforeLoadDFMValues;
  GuestDatasetDietaryRequirements.BeforeLoadDFMValues;
  GuestDatasetMobility.BeforeLoadDFMValues;
  GuestDatasetOtherNeeds.BeforeLoadDFMValues;
  GuestDatasetEmergencyContactName.BeforeLoadDFMValues;
  GuestDatasetEmergencyContactTelephone.BeforeLoadDFMValues;
  GuestDatasetEmergencyContactRelation.BeforeLoadDFMValues;
  GuestDatasetOrigAirport.BeforeLoadDFMValues;
  GuestDatasetPrefAirline.BeforeLoadDFMValues;
  GuestDatasetBoardRequired.BeforeLoadDFMValues;
  GuestDatasetTotalPersonPrice.BeforeLoadDFMValues;
  GuestDatasetDepositDue.BeforeLoadDFMValues;
  GuestDatasetSameContactDetails.BeforeLoadDFMValues;
  GuestDatasetOtherTravelRequirements.BeforeLoadDFMValues;
  GuestDatasetTransportId.BeforeLoadDFMValues;
  HolidaysDataset.BeforeLoadDFMValues;
  HolidaysDatasetHolidayId.BeforeLoadDFMValues;
  HolidaysDatasetHolidayRef.BeforeLoadDFMValues;
  HolidaysDatasetTitle.BeforeLoadDFMValues;
  HolidaysDatasetHolidayType.BeforeLoadDFMValues;
  HolidaysDatasetStartDate.BeforeLoadDFMValues;
  HolidaysDatasetEndDate.BeforeLoadDFMValues;
  HolidaysDatasetTutor.BeforeLoadDFMValues;
  HolidaysDatasetAvailability.BeforeLoadDFMValues;
  HolidaysDatasetHotelId.BeforeLoadDFMValues;
  HolidaysDatasetVenueId.BeforeLoadDFMValues;
  HolidaysDatasetConfirmed.BeforeLoadDFMValues;
  HolidaysDatasetMedia.BeforeLoadDFMValues;
  HolidaysDatasetStartDateFormatted.BeforeLoadDFMValues;
  HolidaysDatasetEndDateFormatted.BeforeLoadDFMValues;
  BookingDataset.BeforeLoadDFMValues;
  BookingDatasetBookingId.BeforeLoadDFMValues;
  BookingDatasetBookingState.BeforeLoadDFMValues;
  BookingDatasetNoOfGuests.BeforeLoadDFMValues;
  BookingDatasetNonPaintingPartner.BeforeLoadDFMValues;
  BookingDatasetArrivalDate.BeforeLoadDFMValues;
  BookingDatasetDepartureDate.BeforeLoadDFMValues;
  BookingDatasetFirstRoomExtraNightsBefore.BeforeLoadDFMValues;
  BookingDatasetFirstRoomExtraNightsAfter.BeforeLoadDFMValues;
  BookingDatasetFirstRoomUpgrade.BeforeLoadDFMValues;
  BookingDatasetHasDog.BeforeLoadDFMValues;
  BookingDatasetTransportId.BeforeLoadDFMValues;
  BookingDatasetShareRoom.BeforeLoadDFMValues;
  BookingDatasetNumberOfPainters.BeforeLoadDFMValues;
  BookingDatasetBookingReference.BeforeLoadDFMValues;
  BookingDatasetDepositDue.BeforeLoadDFMValues;
  BookingDatasetTotalHolidayPrice.BeforeLoadDFMValues;
  BookingDatasetFinalBalanceDueOn.BeforeLoadDFMValues;
  BookingDatasetRoomAvailability.BeforeLoadDFMValues;
  BookingDatasetHolidayAvailability.BeforeLoadDFMValues;
  BookingDatasetLogid.BeforeLoadDFMValues;
  BookingDatasetCreatedOn.BeforeLoadDFMValues;
  BookingDatasetWheredidyouhearaboutus.BeforeLoadDFMValues;
  BookingDatasetOtherTravelRequirements.BeforeLoadDFMValues;
  BookingDatasetTotPriceAdjustment.BeforeLoadDFMValues;
  BookingDatasetTotalPaid.BeforeLoadDFMValues;
  BookingDatasetExported.BeforeLoadDFMValues;
  BookingDatasetExportedOn.BeforeLoadDFMValues;
  BookingDatasetCustomerId.BeforeLoadDFMValues;
  BookingDatasetHolidayId.BeforeLoadDFMValues;
  BookingDatasetFirstRoomTypeId.BeforeLoadDFMValues;
  BookingDatasetSecondRoomTypeId.BeforeLoadDFMValues;
  BookingDatasetGuestId.BeforeLoadDFMValues;
  BookingDatasetNonPainterCount.BeforeLoadDFMValues;
  BookingDatasetTotalNumberOfNights.BeforeLoadDFMValues;
  BookingDatasetBalanceDue.BeforeLoadDFMValues;
  BookingDatasetExtraNights.BeforeLoadDFMValues;
  BookingCosts.BeforeLoadDFMValues;
  BookingCostsBookingcostid.BeforeLoadDFMValues;
  BookingCostsBookingId.BeforeLoadDFMValues;
  BookingCostsItemcode.BeforeLoadDFMValues;
  BookingCostsDescription.BeforeLoadDFMValues;
  BookingCostsUnitPrice.BeforeLoadDFMValues;
  BookingCostsCostPeriod.BeforeLoadDFMValues;
  BookingCostsQuantity.BeforeLoadDFMValues;
  BookingCostsPayerId.BeforeLoadDFMValues;
  BookingCostsItemType.BeforeLoadDFMValues;
  BookingCostsSubItemType.BeforeLoadDFMValues;
  BookingCostsTotalCost.BeforeLoadDFMValues;
  BookingCostsFormattedDescription.BeforeLoadDFMValues;
  SphinxLogin.BeforeLoadDFMValues;
  RoomAllocations.BeforeLoadDFMValues;
  RoomAllocationsHolidayroomallocationid.BeforeLoadDFMValues;
  RoomAllocationsHolidayid.BeforeLoadDFMValues;
  RoomAllocationsRoomTypeId.BeforeLoadDFMValues;
  RoomAllocationsMaxAllocation.BeforeLoadDFMValues;
  RoomAllocationsAllocated.BeforeLoadDFMValues;
  RoomTypes.BeforeLoadDFMValues;
  RoomTypesRoomtypeid.BeforeLoadDFMValues;
  RoomTypesRoomtypecode.BeforeLoadDFMValues;
  RoomTypesRoomdescription.BeforeLoadDFMValues;
  RoomTypesIssingleroom.BeforeLoadDFMValues;
  RoomTypesNonresidential.BeforeLoadDFMValues;
  HotelDataset.BeforeLoadDFMValues;
  HotelDatasetHotelId.BeforeLoadDFMValues;
  HotelDatasetCode.BeforeLoadDFMValues;
  HotelDatasetName.BeforeLoadDFMValues;
  HotelDatasetVenueId.BeforeLoadDFMValues;
  HotelDatasetSingleroomsavailable.BeforeLoadDFMValues;
  HotelDatasetDogCostPeriod.BeforeLoadDFMValues;
  HotelDatasetDogCost.BeforeLoadDFMValues;
  HotelDatasetDogsAllowed.BeforeLoadDFMValues;
  HolidayData.BeforeLoadDFMValues;
  HolidayDataHolidayid.BeforeLoadDFMValues;
  HolidayDataReference.BeforeLoadDFMValues;
  HolidayDataTitle.BeforeLoadDFMValues;
  HolidayDataHolidayType.BeforeLoadDFMValues;
  HolidayDataStartDate.BeforeLoadDFMValues;
  HolidayDataEndDate.BeforeLoadDFMValues;
  HolidayDataMaxNumberOfStudents.BeforeLoadDFMValues;
  HolidayDataVenueId.BeforeLoadDFMValues;
  HolidayDataDepositresidential.BeforeLoadDFMValues;
  HolidayDataActive.BeforeLoadDFMValues;
  HolidayDataMediaWaterColours.BeforeLoadDFMValues;
  HolidayDataMediaOil.BeforeLoadDFMValues;
  HolidayDataMediaAcrylic.BeforeLoadDFMValues;
  HolidayDataMediaPastel.BeforeLoadDFMValues;
  HolidayDataTotalAdjPainters.BeforeLoadDFMValues;
  HolidayDataTotalAdjNonPainters.BeforeLoadDFMValues;
  HolidayDataConfirmed.BeforeLoadDFMValues;
  HolidayDataMainHotelId.BeforeLoadDFMValues;
  HolidayDataSecondaryHotelId.BeforeLoadDFMValues;
  HolidayDataTutorId.BeforeLoadDFMValues;
  HolidayDataPainterPrice.BeforeLoadDFMValues;
  HolidayDataNPPPrice.BeforeLoadDFMValues;
  HolidayDataSingleOccupancy.BeforeLoadDFMValues;
  HolidayDataSingleRoom.BeforeLoadDFMValues;
  HolidayDataNPPSingleRoom.BeforeLoadDFMValues;
  HolidayDataExtraNight.BeforeLoadDFMValues;
  HolidayDataExtraNightSingleRoom.BeforeLoadDFMValues;
  HolidayDataExtraNightSingleOccupancy.BeforeLoadDFMValues;
  HolidayDataNPPSingleOccupancy.BeforeLoadDFMValues;
  HolidayDataExtraNightOption.BeforeLoadDFMValues;
  Payments.BeforeLoadDFMValues;
  PaymentsId.BeforeLoadDFMValues;
  PaymentsProvider.BeforeLoadDFMValues;
  PaymentsState.BeforeLoadDFMValues;
  PaymentsPaymentDate.BeforeLoadDFMValues;
  PaymentsBookingId.BeforeLoadDFMValues;
  PaymentsAmount.BeforeLoadDFMValues;
  PaymentsReference.BeforeLoadDFMValues;
  PaymentsOutcome.BeforeLoadDFMValues;
  PaymentsCurrencyCode.BeforeLoadDFMValues;
  PaymentsPaymentType.BeforeLoadDFMValues;
  PaymentsPaymentLevel.BeforeLoadDFMValues;
  DummyLookUp.BeforeLoadDFMValues;
  DummyLookUpSource.BeforeLoadDFMValues;
  HearAboutOptions.BeforeLoadDFMValues;
  HearAboutOptionsHearAboutId.BeforeLoadDFMValues;
  HearAboutOptionsDescription.BeforeLoadDFMValues;
  HearAboutOptionsHearAboutStatus.BeforeLoadDFMValues;
  Customer2.BeforeLoadDFMValues;
  Customer2CustomerId.BeforeLoadDFMValues;
  Customer2Title.BeforeLoadDFMValues;
  Customer2FirstName.BeforeLoadDFMValues;
  Customer2MiddleName.BeforeLoadDFMValues;
  Customer2LastName.BeforeLoadDFMValues;
  Customer2HomeTelephoneNumber.BeforeLoadDFMValues;
  Customer2MobileNumber.BeforeLoadDFMValues;
  Customer2Email.BeforeLoadDFMValues;
  Customer2AddressLine1.BeforeLoadDFMValues;
  Customer2AddressLine2.BeforeLoadDFMValues;
  Customer2AddressLine3.BeforeLoadDFMValues;
  Customer2Town.BeforeLoadDFMValues;
  Customer2County.BeforeLoadDFMValues;
  Customer2PostCode.BeforeLoadDFMValues;
  Customer2DetailsComplete.BeforeLoadDFMValues;
  Customer2FullyVaccinated.BeforeLoadDFMValues;
  Customer2DateOfLastVaccination.BeforeLoadDFMValues;
  Customer2CountryCode.BeforeLoadDFMValues;
  Customer2DiscountId.BeforeLoadDFMValues;
  Customer2UserId.BeforeLoadDFMValues;
  Customer2CustomerCode.BeforeLoadDFMValues;
  Customer2HearAboutUs.BeforeLoadDFMValues;
  Customer2CustomerType.BeforeLoadDFMValues;
  Customer2ParentCustomerId.BeforeLoadDFMValues;
  Customer2AddressSameAsParent.BeforeLoadDFMValues;
  Customer2ContactDetailId.BeforeLoadDFMValues;
  Customer2DateCreated.BeforeLoadDFMValues;
  GuestDataset2.BeforeLoadDFMValues;
  GuestDataset2GuestId.BeforeLoadDFMValues;
  GuestDataset2GuestType.BeforeLoadDFMValues;
  GuestDataset2RoomTypeId.BeforeLoadDFMValues;
  GuestDataset2RoomUpgradeDescription.BeforeLoadDFMValues;
  GuestDataset2Incomplete.BeforeLoadDFMValues;
  GuestDataset2GuestNumber.BeforeLoadDFMValues;
  GuestDataset2EaselRequired.BeforeLoadDFMValues;
  GuestDataset2FoldingChairRequired.BeforeLoadDFMValues;
  GuestDataset2Media1.BeforeLoadDFMValues;
  GuestDataset2Media2.BeforeLoadDFMValues;
  GuestDataset2Media3.BeforeLoadDFMValues;
  GuestDataset2Media4.BeforeLoadDFMValues;
  GuestDataset2DietaryRequirements.BeforeLoadDFMValues;
  GuestDataset2Mobility.BeforeLoadDFMValues;
  GuestDataset2OtherNeeds.BeforeLoadDFMValues;
  GuestDataset2EmergencyContactName.BeforeLoadDFMValues;
  GuestDataset2EmergencyContactTelephone.BeforeLoadDFMValues;
  GuestDataset2SameContactDetails.BeforeLoadDFMValues;
  GuestDataset2EmergencyContactRelation.BeforeLoadDFMValues;
  GuestDataset2OrigAirport.BeforeLoadDFMValues;
  GuestDataset2PrefAirline.BeforeLoadDFMValues;
  GuestDataset2BoardRequired.BeforeLoadDFMValues;
  GuestDataset2BookingId.BeforeLoadDFMValues;
  GuestDataset2CustomerId.BeforeLoadDFMValues;
  GuestDataset2TotalPersonPrice.BeforeLoadDFMValues;
  GuestDataset2DepositDue.BeforeLoadDFMValues;
  GuestDataset2ExtraNightsCharge.BeforeLoadDFMValues;
  GuestDataset2OtherTravelRequirements.BeforeLoadDFMValues;
  GuestDataset2TransportId.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 497;
    Width := 571;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2024/AlphaServer';
    SetEvent(DataConnection, Self, 'OnError', 'DataConnectionError');
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    DataConnection.Left := 64;
    DataConnection.Top := 32;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    SetEvent(WebClient, Self, 'OnError', 'WebClientError');
    WebClient.Left := 64;
    WebClient.Top := 104;
    CustomerDataset.SetParentComponent(Self);
    CustomerDataset.Name := 'CustomerDataset';
    CustomerDataset.AfterApplyUpdates := CustomerDatasetAfterApplyUpdates;
    CustomerDataset.AfterOpen := CustomerDatasetAfterOpen;
    CustomerDataset.EntitySetName := 'Customer';
    CustomerDataset.Connection := DataConnection;
    CustomerDataset.Left := 376;
    CustomerDataset.Top := 176;
    CustomerDatasetCustomerId.SetParentComponent(CustomerDataset);
    CustomerDatasetCustomerId.Name := 'CustomerDatasetCustomerId';
    CustomerDatasetCustomerId.FieldName := 'CustomerId';
    CustomerDatasetCustomerId.Required := True;
    CustomerDatasetTitle.SetParentComponent(CustomerDataset);
    CustomerDatasetTitle.Name := 'CustomerDatasetTitle';
    CustomerDatasetTitle.FieldName := 'Title';
    CustomerDatasetTitle.Size := 15;
    CustomerDatasetFirstName.SetParentComponent(CustomerDataset);
    CustomerDatasetFirstName.Name := 'CustomerDatasetFirstName';
    CustomerDatasetFirstName.FieldName := 'FirstName';
    CustomerDatasetFirstName.Required := True;
    CustomerDatasetFirstName.Size := 50;
    CustomerDatasetMiddleName.SetParentComponent(CustomerDataset);
    CustomerDatasetMiddleName.Name := 'CustomerDatasetMiddleName';
    CustomerDatasetMiddleName.FieldName := 'MiddleName';
    CustomerDatasetMiddleName.Size := 50;
    CustomerDatasetLastName.SetParentComponent(CustomerDataset);
    CustomerDatasetLastName.Name := 'CustomerDatasetLastName';
    CustomerDatasetLastName.FieldName := 'LastName';
    CustomerDatasetLastName.Required := True;
    CustomerDatasetLastName.Size := 50;
    CustomerDatasetHomeTelephoneNumber.SetParentComponent(CustomerDataset);
    CustomerDatasetHomeTelephoneNumber.Name := 'CustomerDatasetHomeTelephoneNumber';
    CustomerDatasetHomeTelephoneNumber.FieldName := 'HomeTelephoneNumber';
    CustomerDatasetHomeTelephoneNumber.Size := 30;
    CustomerDatasetMobileNumber.SetParentComponent(CustomerDataset);
    CustomerDatasetMobileNumber.Name := 'CustomerDatasetMobileNumber';
    CustomerDatasetMobileNumber.FieldName := 'MobileNumber';
    CustomerDatasetMobileNumber.Size := 30;
    CustomerDatasetEmail.SetParentComponent(CustomerDataset);
    CustomerDatasetEmail.Name := 'CustomerDatasetEmail';
    CustomerDatasetEmail.FieldName := 'Email';
    SetEvent(CustomerDatasetEmail, Self, 'OnSetText', 'CustomerDatasetEmailSetText');
    CustomerDatasetEmail.Size := 255;
    CustomerDatasetAddressLine1.SetParentComponent(CustomerDataset);
    CustomerDatasetAddressLine1.Name := 'CustomerDatasetAddressLine1';
    CustomerDatasetAddressLine1.FieldName := 'AddressLine1';
    CustomerDatasetAddressLine1.Required := True;
    CustomerDatasetAddressLine1.Size := 100;
    CustomerDatasetAddressLine2.SetParentComponent(CustomerDataset);
    CustomerDatasetAddressLine2.Name := 'CustomerDatasetAddressLine2';
    CustomerDatasetAddressLine2.FieldName := 'AddressLine2';
    CustomerDatasetAddressLine2.Size := 100;
    CustomerDatasetAddressLine3.SetParentComponent(CustomerDataset);
    CustomerDatasetAddressLine3.Name := 'CustomerDatasetAddressLine3';
    CustomerDatasetAddressLine3.FieldName := 'AddressLine3';
    CustomerDatasetAddressLine3.Size := 100;
    CustomerDatasetTown.SetParentComponent(CustomerDataset);
    CustomerDatasetTown.Name := 'CustomerDatasetTown';
    CustomerDatasetTown.FieldName := 'Town';
    CustomerDatasetTown.Size := 50;
    CustomerDatasetCounty.SetParentComponent(CustomerDataset);
    CustomerDatasetCounty.Name := 'CustomerDatasetCounty';
    CustomerDatasetCounty.FieldName := 'County';
    CustomerDatasetCounty.Size := 50;
    CustomerDatasetPostCode.SetParentComponent(CustomerDataset);
    CustomerDatasetPostCode.Name := 'CustomerDatasetPostCode';
    CustomerDatasetPostCode.FieldName := 'PostCode';
    SetEvent(CustomerDatasetPostCode, Self, 'OnSetText', 'CustomerDatasetPostCodeSetText');
    CustomerDatasetPostCode.Size := 30;
    CustomerDatasetDetailsComplete.SetParentComponent(CustomerDataset);
    CustomerDatasetDetailsComplete.Name := 'CustomerDatasetDetailsComplete';
    CustomerDatasetDetailsComplete.FieldName := 'DetailsComplete';
    CustomerDatasetDetailsComplete.Required := True;
    CustomerDatasetFullyVaccinated.SetParentComponent(CustomerDataset);
    CustomerDatasetFullyVaccinated.Name := 'CustomerDatasetFullyVaccinated';
    CustomerDatasetFullyVaccinated.FieldName := 'FullyVaccinated';
    CustomerDatasetFullyVaccinated.Required := True;
    CustomerDatasetDateOfLastVaccination.SetParentComponent(CustomerDataset);
    CustomerDatasetDateOfLastVaccination.Name := 'CustomerDatasetDateOfLastVaccination';
    CustomerDatasetDateOfLastVaccination.FieldName := 'DateOfLastVaccination';
    CustomerDatasetCountryCode.SetParentComponent(CustomerDataset);
    CustomerDatasetCountryCode.Name := 'CustomerDatasetCountryCode';
    CustomerDatasetCountryCode.FieldName := 'CountryCode';
    CustomerDatasetCountryCode.Size := 5;
    CustomerDatasetDiscountId.SetParentComponent(CustomerDataset);
    CustomerDatasetDiscountId.Name := 'CustomerDatasetDiscountId';
    CustomerDatasetDiscountId.FieldName := 'DiscountId';
    CustomerDatasetUserId.SetParentComponent(CustomerDataset);
    CustomerDatasetUserId.Name := 'CustomerDatasetUserId';
    CustomerDatasetUserId.FieldName := 'UserId';
    CustomerDatasetUserId.Size := 128;
    CustomerDatasetCustomerCode.SetParentComponent(CustomerDataset);
    CustomerDatasetCustomerCode.Name := 'CustomerDatasetCustomerCode';
    CustomerDatasetCustomerCode.FieldName := 'CustomerCode';
    CustomerDatasetCustomerCode.Size := 15;
    CustomerDatasetCustomerType.SetParentComponent(CustomerDataset);
    CustomerDatasetCustomerType.Name := 'CustomerDatasetCustomerType';
    CustomerDatasetCustomerType.FieldName := 'CustomerType';
    CustomerDatasetCustomerType.Size := 9;
    CustomerDatasetHearAboutUs.SetParentComponent(CustomerDataset);
    CustomerDatasetHearAboutUs.Name := 'CustomerDatasetHearAboutUs';
    CustomerDatasetHearAboutUs.FieldName := 'HearAboutUs';
    CustomerDatasetParentCustomerId.SetParentComponent(CustomerDataset);
    CustomerDatasetParentCustomerId.Name := 'CustomerDatasetParentCustomerId';
    CustomerDatasetParentCustomerId.FieldName := 'ParentCustomerId';
    CustomerDatasetAddressSameAsParent.SetParentComponent(CustomerDataset);
    CustomerDatasetAddressSameAsParent.Name := 'CustomerDatasetAddressSameAsParent';
    CustomerDatasetAddressSameAsParent.FieldName := 'AddressSameAsParent';
    CustomerDatasetContactDetailId.SetParentComponent(CustomerDataset);
    CustomerDatasetContactDetailId.Name := 'CustomerDatasetContactDetailId';
    CustomerDatasetContactDetailId.FieldName := 'ContactDetailId';
    CustomerDatasetDateCreated.SetParentComponent(CustomerDataset);
    CustomerDatasetDateCreated.Name := 'CustomerDatasetDateCreated';
    CustomerDatasetDateCreated.FieldName := 'DateCreated';
    GuestDataset.SetParentComponent(Self);
    GuestDataset.Name := 'GuestDataset';
    GuestDataset.AfterApplyUpdates := GuestDatasetAfterApplyUpdates;
    GuestDataset.AfterOpen := GuestDatasetAfterOpen;
    SetEvent(GuestDataset, Self, 'OnNewRecord', 'GuestDatasetNewRecord');
    GuestDataset.EntitySetName := 'Guest';
    GuestDataset.Connection := DataConnection;
    GuestDataset.Left := 376;
    GuestDataset.Top := 232;
    GuestDatasetGuestId.SetParentComponent(GuestDataset);
    GuestDatasetGuestId.Name := 'GuestDatasetGuestId';
    GuestDatasetGuestId.FieldName := 'GuestId';
    GuestDatasetGuestId.Required := True;
    GuestDatasetCustomerId.SetParentComponent(GuestDataset);
    GuestDatasetCustomerId.Name := 'GuestDatasetCustomerId';
    GuestDatasetCustomerId.FieldName := 'CustomerId';
    GuestDatasetBookingId.SetParentComponent(GuestDataset);
    GuestDatasetBookingId.Name := 'GuestDatasetBookingId';
    GuestDatasetBookingId.FieldName := 'BookingId';
    GuestDatasetGuestType.SetParentComponent(GuestDataset);
    GuestDatasetGuestType.Name := 'GuestDatasetGuestType';
    GuestDatasetGuestType.FieldName := 'GuestType';
    GuestDatasetGuestType.Required := True;
    GuestDatasetGuestType.Size := 10;
    GuestDatasetMedia1.SetParentComponent(GuestDataset);
    GuestDatasetMedia1.Name := 'GuestDatasetMedia1';
    GuestDatasetMedia1.FieldName := 'Media1';
    GuestDatasetMedia1.Size := 12;
    GuestDatasetMedia2.SetParentComponent(GuestDataset);
    GuestDatasetMedia2.Name := 'GuestDatasetMedia2';
    GuestDatasetMedia2.FieldName := 'Media2';
    GuestDatasetMedia2.Size := 12;
    GuestDatasetMedia3.SetParentComponent(GuestDataset);
    GuestDatasetMedia3.Name := 'GuestDatasetMedia3';
    GuestDatasetMedia3.FieldName := 'Media3';
    GuestDatasetMedia3.Size := 12;
    GuestDatasetMedia4.SetParentComponent(GuestDataset);
    GuestDatasetMedia4.Name := 'GuestDatasetMedia4';
    GuestDatasetMedia4.FieldName := 'Media4';
    GuestDatasetMedia4.Size := 12;
    GuestDatasetExtraNightsCharge.SetParentComponent(GuestDataset);
    GuestDatasetExtraNightsCharge.Name := 'GuestDatasetExtraNightsCharge';
    GuestDatasetExtraNightsCharge.FieldName := 'ExtraNightsCharge';
    GuestDatasetExtraNightsCharge.Required := True;
    GuestDatasetRoomTypeId.SetParentComponent(GuestDataset);
    GuestDatasetRoomTypeId.Name := 'GuestDatasetRoomTypeId';
    GuestDatasetRoomTypeId.FieldName := 'RoomTypeId';
    GuestDatasetRoomUpgradeDescription.SetParentComponent(GuestDataset);
    GuestDatasetRoomUpgradeDescription.Name := 'GuestDatasetRoomUpgradeDescription';
    GuestDatasetRoomUpgradeDescription.FieldName := 'RoomUpgradeDescription';
    GuestDatasetRoomUpgradeDescription.Size := 1024;
    GuestDatasetIncomplete.SetParentComponent(GuestDataset);
    GuestDatasetIncomplete.Name := 'GuestDatasetIncomplete';
    GuestDatasetIncomplete.FieldName := 'Incomplete';
    GuestDatasetIncomplete.Required := True;
    GuestDatasetGuestNumber.SetParentComponent(GuestDataset);
    GuestDatasetGuestNumber.Name := 'GuestDatasetGuestNumber';
    GuestDatasetGuestNumber.FieldName := 'GuestNumber';
    GuestDatasetGuestNumber.Required := True;
    GuestDatasetEaselRequired.SetParentComponent(GuestDataset);
    GuestDatasetEaselRequired.Name := 'GuestDatasetEaselRequired';
    GuestDatasetEaselRequired.FieldName := 'EaselRequired';
    GuestDatasetEaselRequired.Required := True;
    GuestDatasetFoldingChairRequired.SetParentComponent(GuestDataset);
    GuestDatasetFoldingChairRequired.Name := 'GuestDatasetFoldingChairRequired';
    GuestDatasetFoldingChairRequired.FieldName := 'FoldingChairRequired';
    GuestDatasetFoldingChairRequired.Required := True;
    GuestDatasetDietaryRequirements.SetParentComponent(GuestDataset);
    GuestDatasetDietaryRequirements.Name := 'GuestDatasetDietaryRequirements';
    GuestDatasetDietaryRequirements.FieldName := 'DietaryRequirements';
    GuestDatasetDietaryRequirements.Size := 1024;
    GuestDatasetMobility.SetParentComponent(GuestDataset);
    GuestDatasetMobility.Name := 'GuestDatasetMobility';
    GuestDatasetMobility.FieldName := 'Mobility';
    GuestDatasetMobility.Size := 1024;
    GuestDatasetOtherNeeds.SetParentComponent(GuestDataset);
    GuestDatasetOtherNeeds.Name := 'GuestDatasetOtherNeeds';
    GuestDatasetOtherNeeds.FieldName := 'OtherNeeds';
    GuestDatasetOtherNeeds.Size := 1024;
    GuestDatasetEmergencyContactName.SetParentComponent(GuestDataset);
    GuestDatasetEmergencyContactName.Name := 'GuestDatasetEmergencyContactName';
    GuestDatasetEmergencyContactName.FieldName := 'EmergencyContactName';
    GuestDatasetEmergencyContactName.Size := 255;
    GuestDatasetEmergencyContactTelephone.SetParentComponent(GuestDataset);
    GuestDatasetEmergencyContactTelephone.Name := 'GuestDatasetEmergencyContactTelephone';
    GuestDatasetEmergencyContactTelephone.FieldName := 'EmergencyContactTelephone';
    GuestDatasetEmergencyContactTelephone.Size := 255;
    GuestDatasetEmergencyContactRelation.SetParentComponent(GuestDataset);
    GuestDatasetEmergencyContactRelation.Name := 'GuestDatasetEmergencyContactRelation';
    GuestDatasetEmergencyContactRelation.FieldName := 'EmergencyContactRelation';
    GuestDatasetEmergencyContactRelation.Size := 50;
    GuestDatasetOrigAirport.SetParentComponent(GuestDataset);
    GuestDatasetOrigAirport.Name := 'GuestDatasetOrigAirport';
    GuestDatasetOrigAirport.FieldName := 'OrigAirport';
    GuestDatasetOrigAirport.Size := 50;
    GuestDatasetPrefAirline.SetParentComponent(GuestDataset);
    GuestDatasetPrefAirline.Name := 'GuestDatasetPrefAirline';
    GuestDatasetPrefAirline.FieldName := 'PrefAirline';
    GuestDatasetPrefAirline.Size := 50;
    GuestDatasetBoardRequired.SetParentComponent(GuestDataset);
    GuestDatasetBoardRequired.Name := 'GuestDatasetBoardRequired';
    GuestDatasetBoardRequired.FieldName := 'BoardRequired';
    GuestDatasetTotalPersonPrice.SetParentComponent(GuestDataset);
    GuestDatasetTotalPersonPrice.Name := 'GuestDatasetTotalPersonPrice';
    GuestDatasetTotalPersonPrice.FieldName := 'TotalPersonPrice';
    GuestDatasetTotalPersonPrice.Required := True;
    GuestDatasetDepositDue.SetParentComponent(GuestDataset);
    GuestDatasetDepositDue.Name := 'GuestDatasetDepositDue';
    GuestDatasetDepositDue.FieldName := 'DepositDue';
    GuestDatasetDepositDue.Required := True;
    GuestDatasetSameContactDetails.SetParentComponent(GuestDataset);
    GuestDatasetSameContactDetails.Name := 'GuestDatasetSameContactDetails';
    GuestDatasetSameContactDetails.FieldName := 'SameContactDetails';
    GuestDatasetSameContactDetails.Required := True;
    GuestDatasetOtherTravelRequirements.SetParentComponent(GuestDataset);
    GuestDatasetOtherTravelRequirements.Name := 'GuestDatasetOtherTravelRequirements';
    GuestDatasetOtherTravelRequirements.FieldName := 'OtherTravelRequirements';
    GuestDatasetOtherTravelRequirements.Size := 255;
    GuestDatasetTransportId.SetParentComponent(GuestDataset);
    GuestDatasetTransportId.Name := 'GuestDatasetTransportId';
    GuestDatasetTransportId.FieldName := 'TransportId';
    HolidaysDataset.SetParentComponent(Self);
    HolidaysDataset.Name := 'HolidaysDataset';
    HolidaysDataset.AfterOpen := HolidaysDatasetAfterOpen;
    SetEvent(HolidaysDataset, Self, 'OnCalcFields', 'HolidaysDatasetCalcFields');
    HolidaysDataset.Left := 49;
    HolidaysDataset.Top := 304;
    HolidaysDatasetHolidayId.SetParentComponent(HolidaysDataset);
    HolidaysDatasetHolidayId.Name := 'HolidaysDatasetHolidayId';
    HolidaysDatasetHolidayId.FieldName := 'HolidayId';
    HolidaysDatasetHolidayRef.SetParentComponent(HolidaysDataset);
    HolidaysDatasetHolidayRef.Name := 'HolidaysDatasetHolidayRef';
    HolidaysDatasetHolidayRef.FieldName := 'HolidayRef';
    HolidaysDatasetHolidayRef.Size := 50;
    HolidaysDatasetTitle.SetParentComponent(HolidaysDataset);
    HolidaysDatasetTitle.Name := 'HolidaysDatasetTitle';
    HolidaysDatasetTitle.FieldName := 'Title';
    HolidaysDatasetTitle.Size := 255;
    HolidaysDatasetHolidayType.SetParentComponent(HolidaysDataset);
    HolidaysDatasetHolidayType.Name := 'HolidaysDatasetHolidayType';
    HolidaysDatasetHolidayType.FieldName := 'HolidayType';
    HolidaysDatasetHolidayType.Size := 255;
    HolidaysDatasetStartDate.SetParentComponent(HolidaysDataset);
    HolidaysDatasetStartDate.Name := 'HolidaysDatasetStartDate';
    HolidaysDatasetStartDate.FieldName := 'StartDate';
    HolidaysDatasetEndDate.SetParentComponent(HolidaysDataset);
    HolidaysDatasetEndDate.Name := 'HolidaysDatasetEndDate';
    HolidaysDatasetEndDate.FieldName := 'EndDate';
    HolidaysDatasetTutor.SetParentComponent(HolidaysDataset);
    HolidaysDatasetTutor.Name := 'HolidaysDatasetTutor';
    HolidaysDatasetTutor.FieldName := 'Tutor';
    HolidaysDatasetTutor.Size := 75;
    HolidaysDatasetAvailability.SetParentComponent(HolidaysDataset);
    HolidaysDatasetAvailability.Name := 'HolidaysDatasetAvailability';
    HolidaysDatasetAvailability.FieldName := 'Availability';
    HolidaysDatasetHotelId.SetParentComponent(HolidaysDataset);
    HolidaysDatasetHotelId.Name := 'HolidaysDatasetHotelId';
    HolidaysDatasetHotelId.FieldName := 'HotelId';
    HolidaysDatasetVenueId.SetParentComponent(HolidaysDataset);
    HolidaysDatasetVenueId.Name := 'HolidaysDatasetVenueId';
    HolidaysDatasetVenueId.FieldName := 'VenueId';
    HolidaysDatasetConfirmed.SetParentComponent(HolidaysDataset);
    HolidaysDatasetConfirmed.Name := 'HolidaysDatasetConfirmed';
    HolidaysDatasetConfirmed.FieldName := 'Confirmed';
    HolidaysDatasetMedia.SetParentComponent(HolidaysDataset);
    HolidaysDatasetMedia.Name := 'HolidaysDatasetMedia';
    HolidaysDatasetMedia.FieldName := 'Media';
    HolidaysDatasetMedia.Size := 250;
    HolidaysDatasetStartDateFormatted.SetParentComponent(HolidaysDataset);
    HolidaysDatasetStartDateFormatted.Name := 'HolidaysDatasetStartDateFormatted';
    HolidaysDatasetStartDateFormatted.FieldKind := fkCalculated;
    HolidaysDatasetStartDateFormatted.FieldName := 'StartDateFormatted';
    HolidaysDatasetStartDateFormatted.Size := 12;
    HolidaysDatasetStartDateFormatted.Calculated := True;
    HolidaysDatasetEndDateFormatted.SetParentComponent(HolidaysDataset);
    HolidaysDatasetEndDateFormatted.Name := 'HolidaysDatasetEndDateFormatted';
    HolidaysDatasetEndDateFormatted.FieldKind := fkCalculated;
    HolidaysDatasetEndDateFormatted.FieldName := 'EndDateFormatted';
    HolidaysDatasetEndDateFormatted.Size := 12;
    HolidaysDatasetEndDateFormatted.Calculated := True;
    BookingDataset.SetParentComponent(Self);
    BookingDataset.Name := 'BookingDataset';
    BookingDataset.AfterApplyUpdates := BookingDatasetAfterApplyUpdates;
    BookingDataset.AfterOpen := BookingDatasetAfterOpen;
    SetEvent(BookingDataset, Self, 'OnCalcFields', 'BookingDatasetCalcFields');
    SetEvent(BookingDataset, Self, 'OnNewRecord', 'BookingDatasetNewRecord');
    BookingDataset.EntitySetName := 'Booking';
    BookingDataset.Connection := DataConnection;
    BookingDataset.Left := 456;
    BookingDataset.Top := 120;
    BookingDatasetBookingId.SetParentComponent(BookingDataset);
    BookingDatasetBookingId.Name := 'BookingDatasetBookingId';
    BookingDatasetBookingId.FieldName := 'BookingId';
    BookingDatasetBookingId.Required := True;
    BookingDatasetBookingState.SetParentComponent(BookingDataset);
    BookingDatasetBookingState.Name := 'BookingDatasetBookingState';
    BookingDatasetBookingState.FieldName := 'BookingState';
    BookingDatasetBookingState.Required := True;
    BookingDatasetBookingState.Size := 22;
    BookingDatasetNoOfGuests.SetParentComponent(BookingDataset);
    BookingDatasetNoOfGuests.Name := 'BookingDatasetNoOfGuests';
    BookingDatasetNoOfGuests.FieldName := 'NoOfGuests';
    BookingDatasetNoOfGuests.Required := True;
    BookingDatasetNonPaintingPartner.SetParentComponent(BookingDataset);
    BookingDatasetNonPaintingPartner.Name := 'BookingDatasetNonPaintingPartner';
    BookingDatasetNonPaintingPartner.FieldName := 'NonPaintingPartner';
    BookingDatasetNonPaintingPartner.Required := True;
    BookingDatasetArrivalDate.SetParentComponent(BookingDataset);
    BookingDatasetArrivalDate.Name := 'BookingDatasetArrivalDate';
    BookingDatasetArrivalDate.FieldName := 'ArrivalDate';
    BookingDatasetArrivalDate.Required := True;
    BookingDatasetDepartureDate.SetParentComponent(BookingDataset);
    BookingDatasetDepartureDate.Name := 'BookingDatasetDepartureDate';
    BookingDatasetDepartureDate.FieldName := 'DepartureDate';
    BookingDatasetDepartureDate.Required := True;
    BookingDatasetFirstRoomExtraNightsBefore.SetParentComponent(BookingDataset);
    BookingDatasetFirstRoomExtraNightsBefore.Name := 'BookingDatasetFirstRoomExtraNightsBefore';
    BookingDatasetFirstRoomExtraNightsBefore.FieldName := 'FirstRoomExtraNightsBefore';
    BookingDatasetFirstRoomExtraNightsBefore.Required := True;
    BookingDatasetFirstRoomExtraNightsAfter.SetParentComponent(BookingDataset);
    BookingDatasetFirstRoomExtraNightsAfter.Name := 'BookingDatasetFirstRoomExtraNightsAfter';
    BookingDatasetFirstRoomExtraNightsAfter.FieldName := 'FirstRoomExtraNightsAfter';
    BookingDatasetFirstRoomExtraNightsAfter.Required := True;
    BookingDatasetFirstRoomUpgrade.SetParentComponent(BookingDataset);
    BookingDatasetFirstRoomUpgrade.Name := 'BookingDatasetFirstRoomUpgrade';
    BookingDatasetFirstRoomUpgrade.FieldName := 'FirstRoomUpgrade';
    BookingDatasetFirstRoomUpgrade.Size := 255;
    BookingDatasetHasDog.SetParentComponent(BookingDataset);
    BookingDatasetHasDog.Name := 'BookingDatasetHasDog';
    BookingDatasetHasDog.FieldName := 'HasDog';
    BookingDatasetHasDog.Required := True;
    BookingDatasetTransportId.SetParentComponent(BookingDataset);
    BookingDatasetTransportId.Name := 'BookingDatasetTransportId';
    BookingDatasetTransportId.FieldName := 'TransportId';
    BookingDatasetTransportId.Required := True;
    BookingDatasetShareRoom.SetParentComponent(BookingDataset);
    BookingDatasetShareRoom.Name := 'BookingDatasetShareRoom';
    BookingDatasetShareRoom.FieldName := 'ShareRoom';
    BookingDatasetShareRoom.Required := True;
    BookingDatasetNumberOfPainters.SetParentComponent(BookingDataset);
    BookingDatasetNumberOfPainters.Name := 'BookingDatasetNumberOfPainters';
    BookingDatasetNumberOfPainters.FieldName := 'NumberOfPainters';
    BookingDatasetNumberOfPainters.Required := True;
    BookingDatasetBookingReference.SetParentComponent(BookingDataset);
    BookingDatasetBookingReference.Name := 'BookingDatasetBookingReference';
    BookingDatasetBookingReference.FieldName := 'BookingReference';
    BookingDatasetBookingReference.Required := True;
    BookingDatasetBookingReference.Size := 50;
    BookingDatasetDepositDue.SetParentComponent(BookingDataset);
    BookingDatasetDepositDue.Name := 'BookingDatasetDepositDue';
    BookingDatasetDepositDue.FieldName := 'DepositDue';
    BookingDatasetDepositDue.Required := True;
    BookingDatasetTotalHolidayPrice.SetParentComponent(BookingDataset);
    BookingDatasetTotalHolidayPrice.Name := 'BookingDatasetTotalHolidayPrice';
    BookingDatasetTotalHolidayPrice.FieldName := 'TotalHolidayPrice';
    BookingDatasetTotalHolidayPrice.Required := True;
    BookingDatasetFinalBalanceDueOn.SetParentComponent(BookingDataset);
    BookingDatasetFinalBalanceDueOn.Name := 'BookingDatasetFinalBalanceDueOn';
    BookingDatasetFinalBalanceDueOn.FieldName := 'FinalBalanceDueOn';
    BookingDatasetRoomAvailability.SetParentComponent(BookingDataset);
    BookingDatasetRoomAvailability.Name := 'BookingDatasetRoomAvailability';
    BookingDatasetRoomAvailability.FieldName := 'RoomAvailability';
    BookingDatasetRoomAvailability.Required := True;
    BookingDatasetHolidayAvailability.SetParentComponent(BookingDataset);
    BookingDatasetHolidayAvailability.Name := 'BookingDatasetHolidayAvailability';
    BookingDatasetHolidayAvailability.FieldName := 'HolidayAvailability';
    BookingDatasetHolidayAvailability.Required := True;
    BookingDatasetLogid.SetParentComponent(BookingDataset);
    BookingDatasetLogid.Name := 'BookingDatasetLogid';
    BookingDatasetLogid.FieldName := 'Logid';
    BookingDatasetCreatedOn.SetParentComponent(BookingDataset);
    BookingDatasetCreatedOn.Name := 'BookingDatasetCreatedOn';
    BookingDatasetCreatedOn.FieldName := 'CreatedOn';
    BookingDatasetWheredidyouhearaboutus.SetParentComponent(BookingDataset);
    BookingDatasetWheredidyouhearaboutus.Name := 'BookingDatasetWheredidyouhearaboutus';
    BookingDatasetWheredidyouhearaboutus.FieldName := 'Wheredidyouhearaboutus';
    BookingDatasetOtherTravelRequirements.SetParentComponent(BookingDataset);
    BookingDatasetOtherTravelRequirements.Name := 'BookingDatasetOtherTravelRequirements';
    BookingDatasetOtherTravelRequirements.FieldName := 'OtherTravelRequirements';
    BookingDatasetOtherTravelRequirements.Size := 255;
    BookingDatasetTotPriceAdjustment.SetParentComponent(BookingDataset);
    BookingDatasetTotPriceAdjustment.Name := 'BookingDatasetTotPriceAdjustment';
    BookingDatasetTotPriceAdjustment.FieldName := 'TotPriceAdjustment';
    BookingDatasetTotalPaid.SetParentComponent(BookingDataset);
    BookingDatasetTotalPaid.Name := 'BookingDatasetTotalPaid';
    BookingDatasetTotalPaid.FieldName := 'TotalPaid';
    BookingDatasetTotalPaid.Required := True;
    BookingDatasetExported.SetParentComponent(BookingDataset);
    BookingDatasetExported.Name := 'BookingDatasetExported';
    BookingDatasetExported.FieldName := 'Exported';
    BookingDatasetExportedOn.SetParentComponent(BookingDataset);
    BookingDatasetExportedOn.Name := 'BookingDatasetExportedOn';
    BookingDatasetExportedOn.FieldName := 'ExportedOn';
    BookingDatasetExportedOn.Size := 24;
    BookingDatasetCustomerId.SetParentComponent(BookingDataset);
    BookingDatasetCustomerId.Name := 'BookingDatasetCustomerId';
    BookingDatasetCustomerId.FieldName := 'CustomerId';
    BookingDatasetHolidayId.SetParentComponent(BookingDataset);
    BookingDatasetHolidayId.Name := 'BookingDatasetHolidayId';
    BookingDatasetHolidayId.FieldName := 'HolidayId';
    BookingDatasetFirstRoomTypeId.SetParentComponent(BookingDataset);
    BookingDatasetFirstRoomTypeId.Name := 'BookingDatasetFirstRoomTypeId';
    BookingDatasetFirstRoomTypeId.FieldName := 'FirstRoomTypeId';
    BookingDatasetSecondRoomTypeId.SetParentComponent(BookingDataset);
    BookingDatasetSecondRoomTypeId.Name := 'BookingDatasetSecondRoomTypeId';
    BookingDatasetSecondRoomTypeId.FieldName := 'SecondRoomTypeId';
    BookingDatasetGuestId.SetParentComponent(BookingDataset);
    BookingDatasetGuestId.Name := 'BookingDatasetGuestId';
    BookingDatasetGuestId.FieldName := 'GuestId';
    BookingDatasetNonPainterCount.SetParentComponent(BookingDataset);
    BookingDatasetNonPainterCount.Name := 'BookingDatasetNonPainterCount';
    BookingDatasetNonPainterCount.FieldKind := fkCalculated;
    BookingDatasetNonPainterCount.FieldName := 'NonPainterCount';
    BookingDatasetNonPainterCount.Calculated := True;
    BookingDatasetTotalNumberOfNights.SetParentComponent(BookingDataset);
    BookingDatasetTotalNumberOfNights.Name := 'BookingDatasetTotalNumberOfNights';
    BookingDatasetTotalNumberOfNights.FieldKind := fkCalculated;
    BookingDatasetTotalNumberOfNights.FieldName := 'TotalNumberOfNights';
    BookingDatasetTotalNumberOfNights.Calculated := True;
    BookingDatasetBalanceDue.SetParentComponent(BookingDataset);
    BookingDatasetBalanceDue.Name := 'BookingDatasetBalanceDue';
    BookingDatasetBalanceDue.FieldKind := fkCalculated;
    BookingDatasetBalanceDue.FieldName := 'BalanceDue';
    BookingDatasetBalanceDue.Calculated := True;
    BookingDatasetExtraNights.SetParentComponent(BookingDataset);
    BookingDatasetExtraNights.Name := 'BookingDatasetExtraNights';
    BookingDatasetExtraNights.FieldKind := fkCalculated;
    BookingDatasetExtraNights.FieldName := 'ExtraNights';
    BookingDatasetExtraNights.Calculated := True;
    BookingCosts.SetParentComponent(Self);
    BookingCosts.Name := 'BookingCosts';
    BookingCosts.AfterApplyUpdates := BookingCostsAfterApplyUpdates;
    BookingCosts.AfterOpen := BookingCostsAfterOpen;
    SetEvent(BookingCosts, Self, 'OnCalcFields', 'BookingCostsCalcFields');
    SetEvent(BookingCosts, Self, 'OnNewRecord', 'BookingCostsNewRecord');
    BookingCosts.EntitySetName := 'Bookingcosts';
    BookingCosts.Connection := DataConnection;
    BookingCosts.Left := 456;
    BookingCosts.Top := 296;
    BookingCostsBookingcostid.SetParentComponent(BookingCosts);
    BookingCostsBookingcostid.Name := 'BookingCostsBookingcostid';
    BookingCostsBookingcostid.FieldName := 'BookingCostId';
    BookingCostsBookingcostid.Required := True;
    BookingCostsBookingId.SetParentComponent(BookingCosts);
    BookingCostsBookingId.Name := 'BookingCostsBookingId';
    BookingCostsBookingId.FieldName := 'BookingId';
    BookingCostsItemcode.SetParentComponent(BookingCosts);
    BookingCostsItemcode.Name := 'BookingCostsItemcode';
    BookingCostsItemcode.FieldName := 'ItemCode';
    BookingCostsItemcode.Required := True;
    BookingCostsItemcode.Size := 10;
    BookingCostsDescription.SetParentComponent(BookingCosts);
    BookingCostsDescription.Name := 'BookingCostsDescription';
    BookingCostsDescription.FieldName := 'Description';
    BookingCostsDescription.Required := True;
    BookingCostsDescription.Size := 512;
    BookingCostsUnitPrice.SetParentComponent(BookingCosts);
    BookingCostsUnitPrice.Name := 'BookingCostsUnitPrice';
    BookingCostsUnitPrice.FieldName := 'UnitPrice';
    BookingCostsUnitPrice.Required := True;
    BookingCostsCostPeriod.SetParentComponent(BookingCosts);
    BookingCostsCostPeriod.Name := 'BookingCostsCostPeriod';
    BookingCostsCostPeriod.FieldName := 'CostPeriod';
    BookingCostsCostPeriod.Size := 8;
    BookingCostsQuantity.SetParentComponent(BookingCosts);
    BookingCostsQuantity.Name := 'BookingCostsQuantity';
    BookingCostsQuantity.FieldName := 'Quantity';
    BookingCostsQuantity.Required := True;
    BookingCostsPayerId.SetParentComponent(BookingCosts);
    BookingCostsPayerId.Name := 'BookingCostsPayerId';
    BookingCostsPayerId.FieldName := 'PayerId';
    BookingCostsItemType.SetParentComponent(BookingCosts);
    BookingCostsItemType.Name := 'BookingCostsItemType';
    BookingCostsItemType.FieldName := 'ItemType';
    BookingCostsItemType.Size := 11;
    BookingCostsSubItemType.SetParentComponent(BookingCosts);
    BookingCostsSubItemType.Name := 'BookingCostsSubItemType';
    BookingCostsSubItemType.FieldName := 'SubItemType';
    BookingCostsSubItemType.Size := 14;
    BookingCostsTotalCost.SetParentComponent(BookingCosts);
    BookingCostsTotalCost.Name := 'BookingCostsTotalCost';
    BookingCostsTotalCost.FieldKind := fkCalculated;
    BookingCostsTotalCost.FieldName := 'TotalCost';
    BookingCostsTotalCost.Calculated := True;
    BookingCostsFormattedDescription.SetParentComponent(BookingCosts);
    BookingCostsFormattedDescription.Name := 'BookingCostsFormattedDescription';
    BookingCostsFormattedDescription.FieldKind := fkCalculated;
    BookingCostsFormattedDescription.FieldName := 'FormattedDescription';
    BookingCostsFormattedDescription.Size := 512;
    BookingCostsFormattedDescription.Calculated := True;
    SphinxLogin.SetParentComponent(Self);
    SphinxLogin.Name := 'SphinxLogin';
    SphinxLogin.ClientId := 'AlphaWebApp';
    SphinxLogin.Scope := 'openid email';
    SetEvent(SphinxLogin, Self, 'OnUserLoggedIn', 'SphinxLoginUserLoggedIn');
    SphinxLogin.Left := 200;
    SphinxLogin.Top := 120;
    RoomAllocations.SetParentComponent(Self);
    RoomAllocations.Name := 'RoomAllocations';
    RoomAllocations.AfterOpen := RoomAllocationsAfterOpen;
    RoomAllocations.EntitySetName := 'HolidayRoomAllocations';
    RoomAllocations.Connection := DataConnection;
    RoomAllocations.Left := 48;
    RoomAllocations.Top := 360;
    RoomAllocationsHolidayroomallocationid.SetParentComponent(RoomAllocations);
    RoomAllocationsHolidayroomallocationid.Name := 'RoomAllocationsHolidayroomallocationid';
    RoomAllocationsHolidayroomallocationid.FieldName := 'Holidayroomallocationid';
    RoomAllocationsHolidayroomallocationid.Required := True;
    RoomAllocationsHolidayid.SetParentComponent(RoomAllocations);
    RoomAllocationsHolidayid.Name := 'RoomAllocationsHolidayid';
    RoomAllocationsHolidayid.FieldName := 'Holidayid';
    RoomAllocationsHolidayid.Required := True;
    RoomAllocationsRoomTypeId.SetParentComponent(RoomAllocations);
    RoomAllocationsRoomTypeId.Name := 'RoomAllocationsRoomTypeId';
    RoomAllocationsRoomTypeId.FieldName := 'RoomTypeId';
    RoomAllocationsMaxAllocation.SetParentComponent(RoomAllocations);
    RoomAllocationsMaxAllocation.Name := 'RoomAllocationsMaxAllocation';
    RoomAllocationsMaxAllocation.FieldName := 'MaxAllocation';
    RoomAllocationsMaxAllocation.Required := True;
    RoomAllocationsAllocated.SetParentComponent(RoomAllocations);
    RoomAllocationsAllocated.Name := 'RoomAllocationsAllocated';
    RoomAllocationsAllocated.FieldName := 'Allocated';
    RoomTypes.SetParentComponent(Self);
    RoomTypes.Name := 'RoomTypes';
    RoomTypes.EntitySetName := 'Roomtype';
    RoomTypes.Connection := DataConnection;
    RoomTypes.Left := 176;
    RoomTypes.Top := 432;
    RoomTypesRoomtypeid.SetParentComponent(RoomTypes);
    RoomTypesRoomtypeid.Name := 'RoomTypesRoomtypeid';
    RoomTypesRoomtypeid.FieldName := 'Roomtypeid';
    RoomTypesRoomtypeid.Required := True;
    RoomTypesRoomtypecode.SetParentComponent(RoomTypes);
    RoomTypesRoomtypecode.Name := 'RoomTypesRoomtypecode';
    RoomTypesRoomtypecode.FieldName := 'Roomtypecode';
    RoomTypesRoomtypecode.Required := True;
    RoomTypesRoomtypecode.Size := 2;
    RoomTypesRoomdescription.SetParentComponent(RoomTypes);
    RoomTypesRoomdescription.Name := 'RoomTypesRoomdescription';
    RoomTypesRoomdescription.FieldName := 'Roomdescription';
    RoomTypesRoomdescription.Required := True;
    RoomTypesRoomdescription.Size := 50;
    RoomTypesIssingleroom.SetParentComponent(RoomTypes);
    RoomTypesIssingleroom.Name := 'RoomTypesIssingleroom';
    RoomTypesIssingleroom.FieldName := 'Issingleroom';
    RoomTypesIssingleroom.Required := True;
    RoomTypesNonresidential.SetParentComponent(RoomTypes);
    RoomTypesNonresidential.Name := 'RoomTypesNonresidential';
    RoomTypesNonresidential.FieldName := 'Nonresidential';
    RoomTypesNonresidential.Required := True;
    HotelDataset.SetParentComponent(Self);
    HotelDataset.Name := 'HotelDataset';
    HotelDataset.AfterOpen := HotelDatasetAfterOpen;
    HotelDataset.EntitySetName := 'Hotel';
    HotelDataset.Connection := DataConnection;
    HotelDataset.Left := 48;
    HotelDataset.Top := 240;
    HotelDatasetHotelId.SetParentComponent(HotelDataset);
    HotelDatasetHotelId.Name := 'HotelDatasetHotelId';
    HotelDatasetHotelId.FieldName := 'HotelId';
    HotelDatasetHotelId.Required := True;
    HotelDatasetCode.SetParentComponent(HotelDataset);
    HotelDatasetCode.Name := 'HotelDatasetCode';
    HotelDatasetCode.FieldName := 'Code';
    HotelDatasetCode.Required := True;
    HotelDatasetCode.Size := 10;
    HotelDatasetName.SetParentComponent(HotelDataset);
    HotelDatasetName.Name := 'HotelDatasetName';
    HotelDatasetName.FieldName := 'Name';
    HotelDatasetName.Required := True;
    HotelDatasetName.Size := 255;
    HotelDatasetVenueId.SetParentComponent(HotelDataset);
    HotelDatasetVenueId.Name := 'HotelDatasetVenueId';
    HotelDatasetVenueId.FieldName := 'VenueId';
    HotelDatasetSingleroomsavailable.SetParentComponent(HotelDataset);
    HotelDatasetSingleroomsavailable.Name := 'HotelDatasetSingleroomsavailable';
    HotelDatasetSingleroomsavailable.FieldName := 'Singleroomsavailable';
    HotelDatasetSingleroomsavailable.Required := True;
    HotelDatasetDogCostPeriod.SetParentComponent(HotelDataset);
    HotelDatasetDogCostPeriod.Name := 'HotelDatasetDogCostPeriod';
    HotelDatasetDogCostPeriod.FieldName := 'DogCostPeriod';
    HotelDatasetDogCostPeriod.Size := 8;
    HotelDatasetDogCost.SetParentComponent(HotelDataset);
    HotelDatasetDogCost.Name := 'HotelDatasetDogCost';
    HotelDatasetDogCost.FieldName := 'DogCost';
    HotelDatasetDogsAllowed.SetParentComponent(HotelDataset);
    HotelDatasetDogsAllowed.Name := 'HotelDatasetDogsAllowed';
    HotelDatasetDogsAllowed.FieldName := 'DogsAllowed';
    HotelDatasetDogsAllowed.Required := True;
    HolidayData.SetParentComponent(Self);
    HolidayData.Name := 'HolidayData';
    HolidayData.AfterOpen := HolidayDataAfterOpen;
    SetEvent(HolidayData, Self, 'OnCalcFields', 'HolidayDataCalcFields');
    HolidayData.EntitySetName := 'Holiday';
    HolidayData.Connection := DataConnection;
    HolidayData.Left := 176;
    HolidayData.Top := 304;
    HolidayDataHolidayid.SetParentComponent(HolidayData);
    HolidayDataHolidayid.Name := 'HolidayDataHolidayid';
    HolidayDataHolidayid.FieldName := 'Holidayid';
    HolidayDataHolidayid.Required := True;
    HolidayDataReference.SetParentComponent(HolidayData);
    HolidayDataReference.Name := 'HolidayDataReference';
    HolidayDataReference.FieldName := 'Reference';
    HolidayDataReference.Required := True;
    HolidayDataReference.Size := 50;
    HolidayDataTitle.SetParentComponent(HolidayData);
    HolidayDataTitle.Name := 'HolidayDataTitle';
    HolidayDataTitle.FieldName := 'Title';
    HolidayDataTitle.Required := True;
    HolidayDataTitle.Size := 255;
    HolidayDataHolidayType.SetParentComponent(HolidayData);
    HolidayDataHolidayType.Name := 'HolidayDataHolidayType';
    HolidayDataHolidayType.FieldName := 'HolidayType';
    HolidayDataHolidayType.Size := 255;
    HolidayDataStartDate.SetParentComponent(HolidayData);
    HolidayDataStartDate.Name := 'HolidayDataStartDate';
    HolidayDataStartDate.FieldName := 'StartDate';
    HolidayDataStartDate.Required := True;
    HolidayDataEndDate.SetParentComponent(HolidayData);
    HolidayDataEndDate.Name := 'HolidayDataEndDate';
    HolidayDataEndDate.FieldName := 'EndDate';
    HolidayDataEndDate.Required := True;
    HolidayDataMaxNumberOfStudents.SetParentComponent(HolidayData);
    HolidayDataMaxNumberOfStudents.Name := 'HolidayDataMaxNumberOfStudents';
    HolidayDataMaxNumberOfStudents.FieldName := 'MaxNumberOfStudents';
    HolidayDataMaxNumberOfStudents.Required := True;
    HolidayDataVenueId.SetParentComponent(HolidayData);
    HolidayDataVenueId.Name := 'HolidayDataVenueId';
    HolidayDataVenueId.FieldName := 'VenueId';
    HolidayDataVenueId.Required := True;
    HolidayDataDepositresidential.SetParentComponent(HolidayData);
    HolidayDataDepositresidential.Name := 'HolidayDataDepositresidential';
    HolidayDataDepositresidential.FieldName := 'Depositresidential';
    HolidayDataDepositresidential.Required := True;
    HolidayDataActive.SetParentComponent(HolidayData);
    HolidayDataActive.Name := 'HolidayDataActive';
    HolidayDataActive.FieldName := 'Active';
    HolidayDataActive.Required := True;
    HolidayDataMediaWaterColours.SetParentComponent(HolidayData);
    HolidayDataMediaWaterColours.Name := 'HolidayDataMediaWaterColours';
    HolidayDataMediaWaterColours.FieldName := 'MediaWaterColours';
    HolidayDataMediaWaterColours.Required := True;
    HolidayDataMediaOil.SetParentComponent(HolidayData);
    HolidayDataMediaOil.Name := 'HolidayDataMediaOil';
    HolidayDataMediaOil.FieldName := 'MediaOil';
    HolidayDataMediaOil.Required := True;
    HolidayDataMediaAcrylic.SetParentComponent(HolidayData);
    HolidayDataMediaAcrylic.Name := 'HolidayDataMediaAcrylic';
    HolidayDataMediaAcrylic.FieldName := 'MediaAcrylic';
    HolidayDataMediaAcrylic.Required := True;
    HolidayDataMediaPastel.SetParentComponent(HolidayData);
    HolidayDataMediaPastel.Name := 'HolidayDataMediaPastel';
    HolidayDataMediaPastel.FieldName := 'MediaPastel';
    HolidayDataMediaPastel.Required := True;
    HolidayDataTotalAdjPainters.SetParentComponent(HolidayData);
    HolidayDataTotalAdjPainters.Name := 'HolidayDataTotalAdjPainters';
    HolidayDataTotalAdjPainters.FieldName := 'TotalAdjPainters';
    HolidayDataTotalAdjPainters.Required := True;
    HolidayDataTotalAdjNonPainters.SetParentComponent(HolidayData);
    HolidayDataTotalAdjNonPainters.Name := 'HolidayDataTotalAdjNonPainters';
    HolidayDataTotalAdjNonPainters.FieldName := 'TotalAdjNonPainters';
    HolidayDataTotalAdjNonPainters.Required := True;
    HolidayDataConfirmed.SetParentComponent(HolidayData);
    HolidayDataConfirmed.Name := 'HolidayDataConfirmed';
    HolidayDataConfirmed.FieldName := 'Confirmed';
    HolidayDataConfirmed.Required := True;
    HolidayDataMainHotelId.SetParentComponent(HolidayData);
    HolidayDataMainHotelId.Name := 'HolidayDataMainHotelId';
    HolidayDataMainHotelId.FieldName := 'MainHotelId';
    HolidayDataSecondaryHotelId.SetParentComponent(HolidayData);
    HolidayDataSecondaryHotelId.Name := 'HolidayDataSecondaryHotelId';
    HolidayDataSecondaryHotelId.FieldName := 'SecondaryHotelId';
    HolidayDataTutorId.SetParentComponent(HolidayData);
    HolidayDataTutorId.Name := 'HolidayDataTutorId';
    HolidayDataTutorId.FieldName := 'TutorId';
    HolidayDataPainterPrice.SetParentComponent(HolidayData);
    HolidayDataPainterPrice.Name := 'HolidayDataPainterPrice';
    HolidayDataPainterPrice.FieldName := 'PainterPrice';
    HolidayDataNPPPrice.SetParentComponent(HolidayData);
    HolidayDataNPPPrice.Name := 'HolidayDataNPPPrice';
    HolidayDataNPPPrice.FieldName := 'NPPPrice';
    HolidayDataNPPPrice.Required := True;
    HolidayDataSingleOccupancy.SetParentComponent(HolidayData);
    HolidayDataSingleOccupancy.Name := 'HolidayDataSingleOccupancy';
    HolidayDataSingleOccupancy.FieldName := 'SingleOccupancy';
    HolidayDataSingleRoom.SetParentComponent(HolidayData);
    HolidayDataSingleRoom.Name := 'HolidayDataSingleRoom';
    HolidayDataSingleRoom.FieldName := 'SingleRoom';
    HolidayDataNPPSingleRoom.SetParentComponent(HolidayData);
    HolidayDataNPPSingleRoom.Name := 'HolidayDataNPPSingleRoom';
    HolidayDataNPPSingleRoom.FieldName := 'NPPSingleRoom';
    HolidayDataExtraNight.SetParentComponent(HolidayData);
    HolidayDataExtraNight.Name := 'HolidayDataExtraNight';
    HolidayDataExtraNight.FieldName := 'ExtraNight';
    HolidayDataExtraNightSingleRoom.SetParentComponent(HolidayData);
    HolidayDataExtraNightSingleRoom.Name := 'HolidayDataExtraNightSingleRoom';
    HolidayDataExtraNightSingleRoom.FieldName := 'ExtraNightSingleRoom';
    HolidayDataExtraNightSingleOccupancy.SetParentComponent(HolidayData);
    HolidayDataExtraNightSingleOccupancy.Name := 'HolidayDataExtraNightSingleOccupancy';
    HolidayDataExtraNightSingleOccupancy.FieldName := 'ExtraNightSingleOccupancy';
    HolidayDataNPPSingleOccupancy.SetParentComponent(HolidayData);
    HolidayDataNPPSingleOccupancy.Name := 'HolidayDataNPPSingleOccupancy';
    HolidayDataNPPSingleOccupancy.FieldKind := fkCalculated;
    HolidayDataNPPSingleOccupancy.FieldName := 'NPPSingleOccupancy';
    HolidayDataNPPSingleOccupancy.Calculated := True;
    HolidayDataExtraNightOption.SetParentComponent(HolidayData);
    HolidayDataExtraNightOption.Name := 'HolidayDataExtraNightOption';
    HolidayDataExtraNightOption.FieldName := 'ExtraNightOption';
    HolidayDataExtraNightOption.Size := 15;
    Payments.SetParentComponent(Self);
    Payments.Name := 'Payments';
    Payments.AfterApplyUpdates := PaymentsAfterApplyUpdates;
    Payments.AfterOpen := PaymentsAfterOpen;
    SetEvent(Payments, Self, 'OnNewRecord', 'PaymentsNewRecord');
    Payments.EntitySetName := 'Payment';
    Payments.Connection := DataConnection;
    Payments.Left := 456;
    Payments.Top := 360;
    PaymentsId.SetParentComponent(Payments);
    PaymentsId.Name := 'PaymentsId';
    PaymentsId.FieldName := 'Id';
    PaymentsProvider.SetParentComponent(Payments);
    PaymentsProvider.Name := 'PaymentsProvider';
    PaymentsProvider.FieldName := 'Provider';
    PaymentsProvider.Required := True;
    PaymentsProvider.Size := 7;
    PaymentsState.SetParentComponent(Payments);
    PaymentsState.Name := 'PaymentsState';
    PaymentsState.FieldName := 'State';
    PaymentsState.Size := 7;
    PaymentsPaymentDate.SetParentComponent(Payments);
    PaymentsPaymentDate.Name := 'PaymentsPaymentDate';
    PaymentsPaymentDate.FieldName := 'PaymentDate';
    PaymentsPaymentDate.Required := True;
    PaymentsBookingId.SetParentComponent(Payments);
    PaymentsBookingId.Name := 'PaymentsBookingId';
    PaymentsBookingId.FieldName := 'BookingId';
    PaymentsBookingId.Required := True;
    PaymentsAmount.SetParentComponent(Payments);
    PaymentsAmount.Name := 'PaymentsAmount';
    PaymentsAmount.FieldName := 'Amount';
    PaymentsAmount.Required := True;
    PaymentsReference.SetParentComponent(Payments);
    PaymentsReference.Name := 'PaymentsReference';
    PaymentsReference.FieldName := 'Reference';
    PaymentsReference.Size := 50;
    PaymentsOutcome.SetParentComponent(Payments);
    PaymentsOutcome.Name := 'PaymentsOutcome';
    PaymentsOutcome.FieldName := 'Outcome';
    PaymentsOutcome.Size := 500;
    PaymentsCurrencyCode.SetParentComponent(Payments);
    PaymentsCurrencyCode.Name := 'PaymentsCurrencyCode';
    PaymentsCurrencyCode.FieldName := 'CurrencyCode';
    PaymentsCurrencyCode.Size := 3;
    PaymentsPaymentType.SetParentComponent(Payments);
    PaymentsPaymentType.Name := 'PaymentsPaymentType';
    PaymentsPaymentType.FieldName := 'PaymentType';
    PaymentsPaymentType.Size := 7;
    PaymentsPaymentLevel.SetParentComponent(Payments);
    PaymentsPaymentLevel.Name := 'PaymentsPaymentLevel';
    PaymentsPaymentLevel.FieldName := 'PaymentLevel';
    PaymentsPaymentLevel.Size := 7;
    DummyLookUp.SetParentComponent(Self);
    DummyLookUp.Name := 'DummyLookUp';
    DummyLookUp.Left := 256;
    DummyLookUp.Top := 48;
    DummyLookUpSource.SetParentComponent(Self);
    DummyLookUpSource.Name := 'DummyLookUpSource';
    DummyLookUpSource.DataSet := DummyLookUp;
    DummyLookUpSource.Left := 352;
    DummyLookUpSource.Top := 48;
    HearAboutOptions.SetParentComponent(Self);
    HearAboutOptions.Name := 'HearAboutOptions';
    HearAboutOptions.EntitySetName := 'HearAboutOption';
    HearAboutOptions.Connection := DataConnection;
    HearAboutOptions.QueryString := '$filter=HearAboutStatus eq Active';
    HearAboutOptions.Left := 288;
    HearAboutOptions.Top := 432;
    HearAboutOptionsHearAboutId.SetParentComponent(HearAboutOptions);
    HearAboutOptionsHearAboutId.Name := 'HearAboutOptionsHearAboutId';
    HearAboutOptionsHearAboutId.FieldName := 'HearAboutId';
    HearAboutOptionsHearAboutId.Required := True;
    HearAboutOptionsDescription.SetParentComponent(HearAboutOptions);
    HearAboutOptionsDescription.Name := 'HearAboutOptionsDescription';
    HearAboutOptionsDescription.FieldName := 'Description';
    HearAboutOptionsDescription.Size := 75;
    HearAboutOptionsHearAboutStatus.SetParentComponent(HearAboutOptions);
    HearAboutOptionsHearAboutStatus.Name := 'HearAboutOptionsHearAboutStatus';
    HearAboutOptionsHearAboutStatus.FieldName := 'HearAboutStatus';
    HearAboutOptionsHearAboutStatus.Size := 9;
    Customer2.SetParentComponent(Self);
    Customer2.Name := 'Customer2';
    Customer2.AfterApplyUpdates := Customer2AfterApplyUpdates;
    Customer2.AfterOpen := Customer2AfterOpen;
    SetEvent(Customer2, Self, 'OnNewRecord', 'Customer2NewRecord');
    Customer2.EntitySetName := 'Customer';
    Customer2.Connection := DataConnection;
    Customer2.Left := 488;
    Customer2.Top := 176;
    Customer2CustomerId.SetParentComponent(Customer2);
    Customer2CustomerId.Name := 'Customer2CustomerId';
    Customer2CustomerId.FieldName := 'CustomerId';
    Customer2CustomerId.Required := True;
    Customer2Title.SetParentComponent(Customer2);
    Customer2Title.Name := 'Customer2Title';
    Customer2Title.FieldName := 'Title';
    Customer2Title.Size := 15;
    Customer2FirstName.SetParentComponent(Customer2);
    Customer2FirstName.Name := 'Customer2FirstName';
    Customer2FirstName.FieldName := 'FirstName';
    Customer2FirstName.Required := True;
    Customer2FirstName.Size := 50;
    Customer2MiddleName.SetParentComponent(Customer2);
    Customer2MiddleName.Name := 'Customer2MiddleName';
    Customer2MiddleName.FieldName := 'MiddleName';
    Customer2MiddleName.Size := 50;
    Customer2LastName.SetParentComponent(Customer2);
    Customer2LastName.Name := 'Customer2LastName';
    Customer2LastName.FieldName := 'LastName';
    Customer2LastName.Required := True;
    Customer2LastName.Size := 50;
    Customer2HomeTelephoneNumber.SetParentComponent(Customer2);
    Customer2HomeTelephoneNumber.Name := 'Customer2HomeTelephoneNumber';
    Customer2HomeTelephoneNumber.FieldName := 'HomeTelephoneNumber';
    Customer2HomeTelephoneNumber.Size := 30;
    Customer2MobileNumber.SetParentComponent(Customer2);
    Customer2MobileNumber.Name := 'Customer2MobileNumber';
    Customer2MobileNumber.FieldName := 'MobileNumber';
    Customer2MobileNumber.Size := 30;
    Customer2Email.SetParentComponent(Customer2);
    Customer2Email.Name := 'Customer2Email';
    Customer2Email.FieldName := 'Email';
    SetEvent(Customer2Email, Self, 'OnSetText', 'Customer2EmailSetText');
    Customer2Email.Size := 255;
    Customer2AddressLine1.SetParentComponent(Customer2);
    Customer2AddressLine1.Name := 'Customer2AddressLine1';
    Customer2AddressLine1.FieldName := 'AddressLine1';
    Customer2AddressLine1.Required := True;
    Customer2AddressLine1.Size := 100;
    Customer2AddressLine2.SetParentComponent(Customer2);
    Customer2AddressLine2.Name := 'Customer2AddressLine2';
    Customer2AddressLine2.FieldName := 'AddressLine2';
    Customer2AddressLine2.Size := 100;
    Customer2AddressLine3.SetParentComponent(Customer2);
    Customer2AddressLine3.Name := 'Customer2AddressLine3';
    Customer2AddressLine3.FieldName := 'AddressLine3';
    Customer2AddressLine3.Size := 100;
    Customer2Town.SetParentComponent(Customer2);
    Customer2Town.Name := 'Customer2Town';
    Customer2Town.FieldName := 'Town';
    Customer2Town.Size := 50;
    Customer2County.SetParentComponent(Customer2);
    Customer2County.Name := 'Customer2County';
    Customer2County.FieldName := 'County';
    Customer2County.Size := 50;
    Customer2PostCode.SetParentComponent(Customer2);
    Customer2PostCode.Name := 'Customer2PostCode';
    Customer2PostCode.FieldName := 'PostCode';
    SetEvent(Customer2PostCode, Self, 'OnSetText', 'Customer2PostCodeSetText');
    Customer2PostCode.Size := 30;
    Customer2DetailsComplete.SetParentComponent(Customer2);
    Customer2DetailsComplete.Name := 'Customer2DetailsComplete';
    Customer2DetailsComplete.FieldName := 'DetailsComplete';
    Customer2DetailsComplete.Required := True;
    Customer2FullyVaccinated.SetParentComponent(Customer2);
    Customer2FullyVaccinated.Name := 'Customer2FullyVaccinated';
    Customer2FullyVaccinated.FieldName := 'FullyVaccinated';
    Customer2FullyVaccinated.Required := True;
    Customer2DateOfLastVaccination.SetParentComponent(Customer2);
    Customer2DateOfLastVaccination.Name := 'Customer2DateOfLastVaccination';
    Customer2DateOfLastVaccination.FieldName := 'DateOfLastVaccination';
    Customer2CountryCode.SetParentComponent(Customer2);
    Customer2CountryCode.Name := 'Customer2CountryCode';
    Customer2CountryCode.FieldName := 'CountryCode';
    Customer2CountryCode.Size := 5;
    Customer2DiscountId.SetParentComponent(Customer2);
    Customer2DiscountId.Name := 'Customer2DiscountId';
    Customer2DiscountId.FieldName := 'DiscountId';
    Customer2UserId.SetParentComponent(Customer2);
    Customer2UserId.Name := 'Customer2UserId';
    Customer2UserId.FieldName := 'UserId';
    Customer2UserId.Size := 128;
    Customer2CustomerCode.SetParentComponent(Customer2);
    Customer2CustomerCode.Name := 'Customer2CustomerCode';
    Customer2CustomerCode.FieldName := 'CustomerCode';
    Customer2CustomerCode.Size := 15;
    Customer2HearAboutUs.SetParentComponent(Customer2);
    Customer2HearAboutUs.Name := 'Customer2HearAboutUs';
    Customer2HearAboutUs.FieldName := 'HearAboutUs';
    Customer2CustomerType.SetParentComponent(Customer2);
    Customer2CustomerType.Name := 'Customer2CustomerType';
    Customer2CustomerType.FieldName := 'CustomerType';
    Customer2CustomerType.Size := 9;
    Customer2ParentCustomerId.SetParentComponent(Customer2);
    Customer2ParentCustomerId.Name := 'Customer2ParentCustomerId';
    Customer2ParentCustomerId.FieldName := 'ParentCustomerId';
    Customer2AddressSameAsParent.SetParentComponent(Customer2);
    Customer2AddressSameAsParent.Name := 'Customer2AddressSameAsParent';
    Customer2AddressSameAsParent.FieldName := 'AddressSameAsParent';
    Customer2ContactDetailId.SetParentComponent(Customer2);
    Customer2ContactDetailId.Name := 'Customer2ContactDetailId';
    Customer2ContactDetailId.FieldName := 'ContactDetailId';
    Customer2DateCreated.SetParentComponent(Customer2);
    Customer2DateCreated.Name := 'Customer2DateCreated';
    Customer2DateCreated.FieldName := 'DateCreated';
    GuestDataset2.SetParentComponent(Self);
    GuestDataset2.Name := 'GuestDataset2';
    GuestDataset2.AfterApplyUpdates := GuestDataset2AfterApplyUpdates;
    GuestDataset2.AfterOpen := GuestDataset2AfterOpen;
    SetEvent(GuestDataset2, Self, 'OnNewRecord', 'GuestDataset2NewRecord');
    GuestDataset2.EntitySetName := 'Guest';
    GuestDataset2.Connection := DataConnection;
    GuestDataset2.Left := 488;
    GuestDataset2.Top := 232;
    GuestDataset2GuestId.SetParentComponent(GuestDataset2);
    GuestDataset2GuestId.Name := 'GuestDataset2GuestId';
    GuestDataset2GuestId.FieldName := 'GuestId';
    GuestDataset2GuestId.Required := True;
    GuestDataset2GuestType.SetParentComponent(GuestDataset2);
    GuestDataset2GuestType.Name := 'GuestDataset2GuestType';
    GuestDataset2GuestType.FieldName := 'GuestType';
    GuestDataset2GuestType.Required := True;
    GuestDataset2GuestType.Size := 10;
    GuestDataset2RoomTypeId.SetParentComponent(GuestDataset2);
    GuestDataset2RoomTypeId.Name := 'GuestDataset2RoomTypeId';
    GuestDataset2RoomTypeId.FieldName := 'RoomTypeId';
    GuestDataset2RoomUpgradeDescription.SetParentComponent(GuestDataset2);
    GuestDataset2RoomUpgradeDescription.Name := 'GuestDataset2RoomUpgradeDescription';
    GuestDataset2RoomUpgradeDescription.FieldName := 'RoomUpgradeDescription';
    GuestDataset2RoomUpgradeDescription.Size := 1024;
    GuestDataset2Incomplete.SetParentComponent(GuestDataset2);
    GuestDataset2Incomplete.Name := 'GuestDataset2Incomplete';
    GuestDataset2Incomplete.FieldName := 'Incomplete';
    GuestDataset2Incomplete.Required := True;
    GuestDataset2GuestNumber.SetParentComponent(GuestDataset2);
    GuestDataset2GuestNumber.Name := 'GuestDataset2GuestNumber';
    GuestDataset2GuestNumber.FieldName := 'GuestNumber';
    GuestDataset2GuestNumber.Required := True;
    GuestDataset2EaselRequired.SetParentComponent(GuestDataset2);
    GuestDataset2EaselRequired.Name := 'GuestDataset2EaselRequired';
    GuestDataset2EaselRequired.FieldName := 'EaselRequired';
    GuestDataset2EaselRequired.Required := True;
    GuestDataset2FoldingChairRequired.SetParentComponent(GuestDataset2);
    GuestDataset2FoldingChairRequired.Name := 'GuestDataset2FoldingChairRequired';
    GuestDataset2FoldingChairRequired.FieldName := 'FoldingChairRequired';
    GuestDataset2FoldingChairRequired.Required := True;
    GuestDataset2Media1.SetParentComponent(GuestDataset2);
    GuestDataset2Media1.Name := 'GuestDataset2Media1';
    GuestDataset2Media1.FieldName := 'Media1';
    GuestDataset2Media1.Size := 12;
    GuestDataset2Media2.SetParentComponent(GuestDataset2);
    GuestDataset2Media2.Name := 'GuestDataset2Media2';
    GuestDataset2Media2.FieldName := 'Media2';
    GuestDataset2Media2.Size := 12;
    GuestDataset2Media3.SetParentComponent(GuestDataset2);
    GuestDataset2Media3.Name := 'GuestDataset2Media3';
    GuestDataset2Media3.FieldName := 'Media3';
    GuestDataset2Media3.Size := 12;
    GuestDataset2Media4.SetParentComponent(GuestDataset2);
    GuestDataset2Media4.Name := 'GuestDataset2Media4';
    GuestDataset2Media4.FieldName := 'Media4';
    GuestDataset2Media4.Size := 12;
    GuestDataset2DietaryRequirements.SetParentComponent(GuestDataset2);
    GuestDataset2DietaryRequirements.Name := 'GuestDataset2DietaryRequirements';
    GuestDataset2DietaryRequirements.FieldName := 'DietaryRequirements';
    GuestDataset2DietaryRequirements.Size := 1024;
    GuestDataset2Mobility.SetParentComponent(GuestDataset2);
    GuestDataset2Mobility.Name := 'GuestDataset2Mobility';
    GuestDataset2Mobility.FieldName := 'Mobility';
    GuestDataset2Mobility.Size := 1024;
    GuestDataset2OtherNeeds.SetParentComponent(GuestDataset2);
    GuestDataset2OtherNeeds.Name := 'GuestDataset2OtherNeeds';
    GuestDataset2OtherNeeds.FieldName := 'OtherNeeds';
    GuestDataset2OtherNeeds.Size := 1024;
    GuestDataset2EmergencyContactName.SetParentComponent(GuestDataset2);
    GuestDataset2EmergencyContactName.Name := 'GuestDataset2EmergencyContactName';
    GuestDataset2EmergencyContactName.FieldName := 'EmergencyContactName';
    GuestDataset2EmergencyContactName.Size := 255;
    GuestDataset2EmergencyContactTelephone.SetParentComponent(GuestDataset2);
    GuestDataset2EmergencyContactTelephone.Name := 'GuestDataset2EmergencyContactTelephone';
    GuestDataset2EmergencyContactTelephone.FieldName := 'EmergencyContactTelephone';
    GuestDataset2EmergencyContactTelephone.Size := 255;
    GuestDataset2SameContactDetails.SetParentComponent(GuestDataset2);
    GuestDataset2SameContactDetails.Name := 'GuestDataset2SameContactDetails';
    GuestDataset2SameContactDetails.FieldName := 'SameContactDetails';
    GuestDataset2SameContactDetails.Required := True;
    GuestDataset2EmergencyContactRelation.SetParentComponent(GuestDataset2);
    GuestDataset2EmergencyContactRelation.Name := 'GuestDataset2EmergencyContactRelation';
    GuestDataset2EmergencyContactRelation.FieldName := 'EmergencyContactRelation';
    GuestDataset2EmergencyContactRelation.Size := 50;
    GuestDataset2OrigAirport.SetParentComponent(GuestDataset2);
    GuestDataset2OrigAirport.Name := 'GuestDataset2OrigAirport';
    GuestDataset2OrigAirport.FieldName := 'OrigAirport';
    GuestDataset2OrigAirport.Size := 50;
    GuestDataset2PrefAirline.SetParentComponent(GuestDataset2);
    GuestDataset2PrefAirline.Name := 'GuestDataset2PrefAirline';
    GuestDataset2PrefAirline.FieldName := 'PrefAirline';
    GuestDataset2PrefAirline.Size := 50;
    GuestDataset2BoardRequired.SetParentComponent(GuestDataset2);
    GuestDataset2BoardRequired.Name := 'GuestDataset2BoardRequired';
    GuestDataset2BoardRequired.FieldName := 'BoardRequired';
    GuestDataset2BookingId.SetParentComponent(GuestDataset2);
    GuestDataset2BookingId.Name := 'GuestDataset2BookingId';
    GuestDataset2BookingId.FieldName := 'BookingId';
    GuestDataset2CustomerId.SetParentComponent(GuestDataset2);
    GuestDataset2CustomerId.Name := 'GuestDataset2CustomerId';
    GuestDataset2CustomerId.FieldName := 'CustomerId';
    GuestDataset2TotalPersonPrice.SetParentComponent(GuestDataset2);
    GuestDataset2TotalPersonPrice.Name := 'GuestDataset2TotalPersonPrice';
    GuestDataset2TotalPersonPrice.FieldName := 'TotalPersonPrice';
    GuestDataset2TotalPersonPrice.Required := True;
    GuestDataset2DepositDue.SetParentComponent(GuestDataset2);
    GuestDataset2DepositDue.Name := 'GuestDataset2DepositDue';
    GuestDataset2DepositDue.FieldName := 'DepositDue';
    GuestDataset2DepositDue.Required := True;
    GuestDataset2ExtraNightsCharge.SetParentComponent(GuestDataset2);
    GuestDataset2ExtraNightsCharge.Name := 'GuestDataset2ExtraNightsCharge';
    GuestDataset2ExtraNightsCharge.FieldName := 'ExtraNightsCharge';
    GuestDataset2ExtraNightsCharge.Required := True;
    GuestDataset2OtherTravelRequirements.SetParentComponent(GuestDataset2);
    GuestDataset2OtherTravelRequirements.Name := 'GuestDataset2OtherTravelRequirements';
    GuestDataset2OtherTravelRequirements.FieldName := 'OtherTravelRequirements';
    GuestDataset2OtherTravelRequirements.Size := 255;
    GuestDataset2TransportId.SetParentComponent(GuestDataset2);
    GuestDataset2TransportId.Name := 'GuestDataset2TransportId';
    GuestDataset2TransportId.FieldName := 'TransportId';
  finally
    DataConnection.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
    CustomerDataset.AfterLoadDFMValues;
    CustomerDatasetCustomerId.AfterLoadDFMValues;
    CustomerDatasetTitle.AfterLoadDFMValues;
    CustomerDatasetFirstName.AfterLoadDFMValues;
    CustomerDatasetMiddleName.AfterLoadDFMValues;
    CustomerDatasetLastName.AfterLoadDFMValues;
    CustomerDatasetHomeTelephoneNumber.AfterLoadDFMValues;
    CustomerDatasetMobileNumber.AfterLoadDFMValues;
    CustomerDatasetEmail.AfterLoadDFMValues;
    CustomerDatasetAddressLine1.AfterLoadDFMValues;
    CustomerDatasetAddressLine2.AfterLoadDFMValues;
    CustomerDatasetAddressLine3.AfterLoadDFMValues;
    CustomerDatasetTown.AfterLoadDFMValues;
    CustomerDatasetCounty.AfterLoadDFMValues;
    CustomerDatasetPostCode.AfterLoadDFMValues;
    CustomerDatasetDetailsComplete.AfterLoadDFMValues;
    CustomerDatasetFullyVaccinated.AfterLoadDFMValues;
    CustomerDatasetDateOfLastVaccination.AfterLoadDFMValues;
    CustomerDatasetCountryCode.AfterLoadDFMValues;
    CustomerDatasetDiscountId.AfterLoadDFMValues;
    CustomerDatasetUserId.AfterLoadDFMValues;
    CustomerDatasetCustomerCode.AfterLoadDFMValues;
    CustomerDatasetCustomerType.AfterLoadDFMValues;
    CustomerDatasetHearAboutUs.AfterLoadDFMValues;
    CustomerDatasetParentCustomerId.AfterLoadDFMValues;
    CustomerDatasetAddressSameAsParent.AfterLoadDFMValues;
    CustomerDatasetContactDetailId.AfterLoadDFMValues;
    CustomerDatasetDateCreated.AfterLoadDFMValues;
    GuestDataset.AfterLoadDFMValues;
    GuestDatasetGuestId.AfterLoadDFMValues;
    GuestDatasetCustomerId.AfterLoadDFMValues;
    GuestDatasetBookingId.AfterLoadDFMValues;
    GuestDatasetGuestType.AfterLoadDFMValues;
    GuestDatasetMedia1.AfterLoadDFMValues;
    GuestDatasetMedia2.AfterLoadDFMValues;
    GuestDatasetMedia3.AfterLoadDFMValues;
    GuestDatasetMedia4.AfterLoadDFMValues;
    GuestDatasetExtraNightsCharge.AfterLoadDFMValues;
    GuestDatasetRoomTypeId.AfterLoadDFMValues;
    GuestDatasetRoomUpgradeDescription.AfterLoadDFMValues;
    GuestDatasetIncomplete.AfterLoadDFMValues;
    GuestDatasetGuestNumber.AfterLoadDFMValues;
    GuestDatasetEaselRequired.AfterLoadDFMValues;
    GuestDatasetFoldingChairRequired.AfterLoadDFMValues;
    GuestDatasetDietaryRequirements.AfterLoadDFMValues;
    GuestDatasetMobility.AfterLoadDFMValues;
    GuestDatasetOtherNeeds.AfterLoadDFMValues;
    GuestDatasetEmergencyContactName.AfterLoadDFMValues;
    GuestDatasetEmergencyContactTelephone.AfterLoadDFMValues;
    GuestDatasetEmergencyContactRelation.AfterLoadDFMValues;
    GuestDatasetOrigAirport.AfterLoadDFMValues;
    GuestDatasetPrefAirline.AfterLoadDFMValues;
    GuestDatasetBoardRequired.AfterLoadDFMValues;
    GuestDatasetTotalPersonPrice.AfterLoadDFMValues;
    GuestDatasetDepositDue.AfterLoadDFMValues;
    GuestDatasetSameContactDetails.AfterLoadDFMValues;
    GuestDatasetOtherTravelRequirements.AfterLoadDFMValues;
    GuestDatasetTransportId.AfterLoadDFMValues;
    HolidaysDataset.AfterLoadDFMValues;
    HolidaysDatasetHolidayId.AfterLoadDFMValues;
    HolidaysDatasetHolidayRef.AfterLoadDFMValues;
    HolidaysDatasetTitle.AfterLoadDFMValues;
    HolidaysDatasetHolidayType.AfterLoadDFMValues;
    HolidaysDatasetStartDate.AfterLoadDFMValues;
    HolidaysDatasetEndDate.AfterLoadDFMValues;
    HolidaysDatasetTutor.AfterLoadDFMValues;
    HolidaysDatasetAvailability.AfterLoadDFMValues;
    HolidaysDatasetHotelId.AfterLoadDFMValues;
    HolidaysDatasetVenueId.AfterLoadDFMValues;
    HolidaysDatasetConfirmed.AfterLoadDFMValues;
    HolidaysDatasetMedia.AfterLoadDFMValues;
    HolidaysDatasetStartDateFormatted.AfterLoadDFMValues;
    HolidaysDatasetEndDateFormatted.AfterLoadDFMValues;
    BookingDataset.AfterLoadDFMValues;
    BookingDatasetBookingId.AfterLoadDFMValues;
    BookingDatasetBookingState.AfterLoadDFMValues;
    BookingDatasetNoOfGuests.AfterLoadDFMValues;
    BookingDatasetNonPaintingPartner.AfterLoadDFMValues;
    BookingDatasetArrivalDate.AfterLoadDFMValues;
    BookingDatasetDepartureDate.AfterLoadDFMValues;
    BookingDatasetFirstRoomExtraNightsBefore.AfterLoadDFMValues;
    BookingDatasetFirstRoomExtraNightsAfter.AfterLoadDFMValues;
    BookingDatasetFirstRoomUpgrade.AfterLoadDFMValues;
    BookingDatasetHasDog.AfterLoadDFMValues;
    BookingDatasetTransportId.AfterLoadDFMValues;
    BookingDatasetShareRoom.AfterLoadDFMValues;
    BookingDatasetNumberOfPainters.AfterLoadDFMValues;
    BookingDatasetBookingReference.AfterLoadDFMValues;
    BookingDatasetDepositDue.AfterLoadDFMValues;
    BookingDatasetTotalHolidayPrice.AfterLoadDFMValues;
    BookingDatasetFinalBalanceDueOn.AfterLoadDFMValues;
    BookingDatasetRoomAvailability.AfterLoadDFMValues;
    BookingDatasetHolidayAvailability.AfterLoadDFMValues;
    BookingDatasetLogid.AfterLoadDFMValues;
    BookingDatasetCreatedOn.AfterLoadDFMValues;
    BookingDatasetWheredidyouhearaboutus.AfterLoadDFMValues;
    BookingDatasetOtherTravelRequirements.AfterLoadDFMValues;
    BookingDatasetTotPriceAdjustment.AfterLoadDFMValues;
    BookingDatasetTotalPaid.AfterLoadDFMValues;
    BookingDatasetExported.AfterLoadDFMValues;
    BookingDatasetExportedOn.AfterLoadDFMValues;
    BookingDatasetCustomerId.AfterLoadDFMValues;
    BookingDatasetHolidayId.AfterLoadDFMValues;
    BookingDatasetFirstRoomTypeId.AfterLoadDFMValues;
    BookingDatasetSecondRoomTypeId.AfterLoadDFMValues;
    BookingDatasetGuestId.AfterLoadDFMValues;
    BookingDatasetNonPainterCount.AfterLoadDFMValues;
    BookingDatasetTotalNumberOfNights.AfterLoadDFMValues;
    BookingDatasetBalanceDue.AfterLoadDFMValues;
    BookingDatasetExtraNights.AfterLoadDFMValues;
    BookingCosts.AfterLoadDFMValues;
    BookingCostsBookingcostid.AfterLoadDFMValues;
    BookingCostsBookingId.AfterLoadDFMValues;
    BookingCostsItemcode.AfterLoadDFMValues;
    BookingCostsDescription.AfterLoadDFMValues;
    BookingCostsUnitPrice.AfterLoadDFMValues;
    BookingCostsCostPeriod.AfterLoadDFMValues;
    BookingCostsQuantity.AfterLoadDFMValues;
    BookingCostsPayerId.AfterLoadDFMValues;
    BookingCostsItemType.AfterLoadDFMValues;
    BookingCostsSubItemType.AfterLoadDFMValues;
    BookingCostsTotalCost.AfterLoadDFMValues;
    BookingCostsFormattedDescription.AfterLoadDFMValues;
    SphinxLogin.AfterLoadDFMValues;
    RoomAllocations.AfterLoadDFMValues;
    RoomAllocationsHolidayroomallocationid.AfterLoadDFMValues;
    RoomAllocationsHolidayid.AfterLoadDFMValues;
    RoomAllocationsRoomTypeId.AfterLoadDFMValues;
    RoomAllocationsMaxAllocation.AfterLoadDFMValues;
    RoomAllocationsAllocated.AfterLoadDFMValues;
    RoomTypes.AfterLoadDFMValues;
    RoomTypesRoomtypeid.AfterLoadDFMValues;
    RoomTypesRoomtypecode.AfterLoadDFMValues;
    RoomTypesRoomdescription.AfterLoadDFMValues;
    RoomTypesIssingleroom.AfterLoadDFMValues;
    RoomTypesNonresidential.AfterLoadDFMValues;
    HotelDataset.AfterLoadDFMValues;
    HotelDatasetHotelId.AfterLoadDFMValues;
    HotelDatasetCode.AfterLoadDFMValues;
    HotelDatasetName.AfterLoadDFMValues;
    HotelDatasetVenueId.AfterLoadDFMValues;
    HotelDatasetSingleroomsavailable.AfterLoadDFMValues;
    HotelDatasetDogCostPeriod.AfterLoadDFMValues;
    HotelDatasetDogCost.AfterLoadDFMValues;
    HotelDatasetDogsAllowed.AfterLoadDFMValues;
    HolidayData.AfterLoadDFMValues;
    HolidayDataHolidayid.AfterLoadDFMValues;
    HolidayDataReference.AfterLoadDFMValues;
    HolidayDataTitle.AfterLoadDFMValues;
    HolidayDataHolidayType.AfterLoadDFMValues;
    HolidayDataStartDate.AfterLoadDFMValues;
    HolidayDataEndDate.AfterLoadDFMValues;
    HolidayDataMaxNumberOfStudents.AfterLoadDFMValues;
    HolidayDataVenueId.AfterLoadDFMValues;
    HolidayDataDepositresidential.AfterLoadDFMValues;
    HolidayDataActive.AfterLoadDFMValues;
    HolidayDataMediaWaterColours.AfterLoadDFMValues;
    HolidayDataMediaOil.AfterLoadDFMValues;
    HolidayDataMediaAcrylic.AfterLoadDFMValues;
    HolidayDataMediaPastel.AfterLoadDFMValues;
    HolidayDataTotalAdjPainters.AfterLoadDFMValues;
    HolidayDataTotalAdjNonPainters.AfterLoadDFMValues;
    HolidayDataConfirmed.AfterLoadDFMValues;
    HolidayDataMainHotelId.AfterLoadDFMValues;
    HolidayDataSecondaryHotelId.AfterLoadDFMValues;
    HolidayDataTutorId.AfterLoadDFMValues;
    HolidayDataPainterPrice.AfterLoadDFMValues;
    HolidayDataNPPPrice.AfterLoadDFMValues;
    HolidayDataSingleOccupancy.AfterLoadDFMValues;
    HolidayDataSingleRoom.AfterLoadDFMValues;
    HolidayDataNPPSingleRoom.AfterLoadDFMValues;
    HolidayDataExtraNight.AfterLoadDFMValues;
    HolidayDataExtraNightSingleRoom.AfterLoadDFMValues;
    HolidayDataExtraNightSingleOccupancy.AfterLoadDFMValues;
    HolidayDataNPPSingleOccupancy.AfterLoadDFMValues;
    HolidayDataExtraNightOption.AfterLoadDFMValues;
    Payments.AfterLoadDFMValues;
    PaymentsId.AfterLoadDFMValues;
    PaymentsProvider.AfterLoadDFMValues;
    PaymentsState.AfterLoadDFMValues;
    PaymentsPaymentDate.AfterLoadDFMValues;
    PaymentsBookingId.AfterLoadDFMValues;
    PaymentsAmount.AfterLoadDFMValues;
    PaymentsReference.AfterLoadDFMValues;
    PaymentsOutcome.AfterLoadDFMValues;
    PaymentsCurrencyCode.AfterLoadDFMValues;
    PaymentsPaymentType.AfterLoadDFMValues;
    PaymentsPaymentLevel.AfterLoadDFMValues;
    DummyLookUp.AfterLoadDFMValues;
    DummyLookUpSource.AfterLoadDFMValues;
    HearAboutOptions.AfterLoadDFMValues;
    HearAboutOptionsHearAboutId.AfterLoadDFMValues;
    HearAboutOptionsDescription.AfterLoadDFMValues;
    HearAboutOptionsHearAboutStatus.AfterLoadDFMValues;
    Customer2.AfterLoadDFMValues;
    Customer2CustomerId.AfterLoadDFMValues;
    Customer2Title.AfterLoadDFMValues;
    Customer2FirstName.AfterLoadDFMValues;
    Customer2MiddleName.AfterLoadDFMValues;
    Customer2LastName.AfterLoadDFMValues;
    Customer2HomeTelephoneNumber.AfterLoadDFMValues;
    Customer2MobileNumber.AfterLoadDFMValues;
    Customer2Email.AfterLoadDFMValues;
    Customer2AddressLine1.AfterLoadDFMValues;
    Customer2AddressLine2.AfterLoadDFMValues;
    Customer2AddressLine3.AfterLoadDFMValues;
    Customer2Town.AfterLoadDFMValues;
    Customer2County.AfterLoadDFMValues;
    Customer2PostCode.AfterLoadDFMValues;
    Customer2DetailsComplete.AfterLoadDFMValues;
    Customer2FullyVaccinated.AfterLoadDFMValues;
    Customer2DateOfLastVaccination.AfterLoadDFMValues;
    Customer2CountryCode.AfterLoadDFMValues;
    Customer2DiscountId.AfterLoadDFMValues;
    Customer2UserId.AfterLoadDFMValues;
    Customer2CustomerCode.AfterLoadDFMValues;
    Customer2HearAboutUs.AfterLoadDFMValues;
    Customer2CustomerType.AfterLoadDFMValues;
    Customer2ParentCustomerId.AfterLoadDFMValues;
    Customer2AddressSameAsParent.AfterLoadDFMValues;
    Customer2ContactDetailId.AfterLoadDFMValues;
    Customer2DateCreated.AfterLoadDFMValues;
    GuestDataset2.AfterLoadDFMValues;
    GuestDataset2GuestId.AfterLoadDFMValues;
    GuestDataset2GuestType.AfterLoadDFMValues;
    GuestDataset2RoomTypeId.AfterLoadDFMValues;
    GuestDataset2RoomUpgradeDescription.AfterLoadDFMValues;
    GuestDataset2Incomplete.AfterLoadDFMValues;
    GuestDataset2GuestNumber.AfterLoadDFMValues;
    GuestDataset2EaselRequired.AfterLoadDFMValues;
    GuestDataset2FoldingChairRequired.AfterLoadDFMValues;
    GuestDataset2Media1.AfterLoadDFMValues;
    GuestDataset2Media2.AfterLoadDFMValues;
    GuestDataset2Media3.AfterLoadDFMValues;
    GuestDataset2Media4.AfterLoadDFMValues;
    GuestDataset2DietaryRequirements.AfterLoadDFMValues;
    GuestDataset2Mobility.AfterLoadDFMValues;
    GuestDataset2OtherNeeds.AfterLoadDFMValues;
    GuestDataset2EmergencyContactName.AfterLoadDFMValues;
    GuestDataset2EmergencyContactTelephone.AfterLoadDFMValues;
    GuestDataset2SameContactDetails.AfterLoadDFMValues;
    GuestDataset2EmergencyContactRelation.AfterLoadDFMValues;
    GuestDataset2OrigAirport.AfterLoadDFMValues;
    GuestDataset2PrefAirline.AfterLoadDFMValues;
    GuestDataset2BoardRequired.AfterLoadDFMValues;
    GuestDataset2BookingId.AfterLoadDFMValues;
    GuestDataset2CustomerId.AfterLoadDFMValues;
    GuestDataset2TotalPersonPrice.AfterLoadDFMValues;
    GuestDataset2DepositDue.AfterLoadDFMValues;
    GuestDataset2ExtraNightsCharge.AfterLoadDFMValues;
    GuestDataset2OtherTravelRequirements.AfterLoadDFMValues;
    GuestDataset2TransportId.AfterLoadDFMValues;
  end;
end;

End.
