unit Booking.Web.Shared;

interface

uses
  Cyrus.Enumerations;

type

  TButtonState = (wbsEnabled, wbsDisabled, wbsHidden, wbsNoChange);
  TNavDirection = (ndNone, ndForward, ndBackwards);
  TLogKind = (NoSave, Info, Error, AnException, Warning, Trace);

  TOnBookingPageChange = procedure(const ThePreviousButton, TheNextButton: TButtonState;
    const CrumblineIndex: Integer; const HideMyAccount: Boolean) of object;

  TOnReadyToProgress = procedure(const CanProgress: Boolean) of object;
  TAfterLogin = procedure(const AShowPage: string; const AMessage: string) of object;

  TAfterPaymentProcessed = procedure(const PaymentState: TPaymentState) of object;

  TBookingPage = (bpNoChange, bpChooseHoliday, bpLogin, bpRegisterCustomer, bpCustomerDetails, bpGuestDetails,
    bpCustomerRequirements, bpGuestRequirements, bpOptionalExtras, bpReview, bpPay, bpConfirmation, bpError);

  TsmxRecordState = (rsNotApplicable, rsLoading, rsExisting, rsNew);

  TCanProgress = (ccNo, ccImmediate, ccWait);

  TClearLevel = (clHoliday, clHotel, clAll);

  TLoginState = (lisNotLoggedIn, lisLoggingIn, lisProcessing, lisLoggedIn, lisFailedLogin);

  TProcessingState = (psStandard, psLoadingPreviousBooking, psContinuingBooking, psBookingExists, psWaitList,
    { psFinalisingBooking, } psPaymentProcessing, psConfirmation, psLoggingIn, psLogInProcessing, psSavingData, psReloadingCustomer, psLoadingHolidayLink,
    psAccountUpdate, psLoadFromLink);

  TLoginSource = (lsNotSpecified, lsMyAccount, lsBooking);

  TLogoutMethod = (lomManual, lomBrowserClose, lomRegistration, lomAuto);
  TMainAppPage = (mpNone, mpBooking, mpMyAccount);

  TRoomsAvailableOutCome = (raoYes, raoNone, raoType);

  TBookingData = record
    Booking: Boolean;
    Costs: Boolean;
    Customers: Boolean;
    Customer2: Boolean;
    GuestDataset: Boolean;
    GuestDataset2: Boolean;
    Payments: Boolean;
    procedure Clear;
    function AllDone: Boolean;
  end;

  THolidayData = record
    HolidayData: Boolean;
    Holidays: Boolean;
    Hotel: Boolean;
    RoomAllocations: Boolean;
    procedure Clear;
    function AllDone: Boolean;
  end;

const
  Crumb_No_Change = -1;
  Crumb_Choose_Holiday = 0;
  Crumb_Customer_Details = 1;
  Crumb_Guest_Details = 2;
  Crumb_Optional_Extras = 3;
  Crumb_Review_Pay = 4;
  Crumb_Confirmation = 5;

  Application_Title = 'Alpha Painting Holidays - "Your First & Best Choice for a Painting Holiday"';

  Register_Customer_Selected = 'RegisterCustomer';

  Booking_Page_Name: array[TBookingPage] of string = ('NoChange', 'ChooseHoliday', 'Login', 'RegisterCustomer', 'CustomerDetails', 'GuestDetails',
    'CustomerRequirements', 'GuestRequirements', 'OptionalExtras', 'Review', 'Pay', 'Confirmation', 'Error');

  Main_App_Page: array[TMainAppPage] of string = ('None', 'Booking', 'MyAccount');

  Nav_Direction: array[TNavDirection] of string = ('None', 'Forward', 'Backwards');

  CustomerTypeForPage: array [TBookingPage] of TCustomerType = (TCustomerType.None // bpNoChange,
    , TCustomerType.None // bpChooseHoliday,
    , TCustomerType.Primary // bpLogin,
    , TCustomerType.Primary // bpRegisterCustomer,
    , TCustomerType.Primary // bpCustomerDetails,
    , TCustomerType.Secondary // bpGuestDetails,
    , TCustomerType.Primary // bpCustomerRequirements,
    , TCustomerType.Secondary // bpGuestRequirements,
    , TCustomerType.None // bpOptionalExtras,
    , TCustomerType.None // bpReview,
    , TCustomerType.None // bpPay,
    , TCustomerType.None // bpConfirm,
    , TCustomerType.None);

implementation

{ TBookingData }

function TBookingData.AllDone: Boolean;
begin
  Result := Booking;
  Result := Result and Costs;
  Result := Result and Customers;
  Result := Result and GuestDataset;
  Result := Result and Customer2;
  Result := Result and GuestDataset2;
  Result := Result and Payments;
end;

procedure TBookingData.Clear;
begin
  Booking := False;
  GuestDataset := False;
  Costs := False;
  Customers := False;
  Customer2 := False;
  GuestDataset2 := False;
  Payments := False;
end;

{ THolidayData }

function THolidayData.AllDone: Boolean;
{$IFDEF DEBUG}
var
 lHolidayData: Boolean;
 lHolidays: Boolean;
 lHotel: Boolean;
 lRoomAllocations: Boolean;
begin
  lHolidayData := HolidayData;
  lHolidays:= Holidays;
  lHotel := Hotel;
  lRoomAllocations := RoomAllocations;
  Result := HolidayData and Holidays and Hotel and RoomAllocations;
{$ELSE}
begin
  Result := HolidayData and Holidays and Hotel and RoomAllocations;
{$ENDIF}
end;

procedure THolidayData.Clear;
begin
  HolidayData := False;
  Holidays := False;
  Hotel := False;
  RoomAllocations := False;
end;

end.
